import React, { useCallback } from 'react'

import { Checkbox } from '../../../../components/ui/Checkbox'
import { useTranslation } from '../../../../i18n'
import { useFlags } from '../../../../lib/featureToggle'

import * as styles from './styles'

type UserSetting = {
  notifyOkrEmailEnabled: boolean
  notifyOneOnOneEmailEnabled: boolean
  notifyBulkUpdateEnabledOnSlack: boolean
}

export type Props = {
  loading: boolean
  userSetting: UserSetting
  organizationSlackNotification: boolean
  updateNotifyOkrEmailEnabled: (enabled: boolean) => void
  updateNotifyOneOnOneEmailEnabled: (enabled: boolean) => void
  updateNotifyBulkUpdateBySlack: (enabled: boolean) => void
}

export const NotificationSetting: React.FC<Props> = ({
  loading,
  userSetting,
  organizationSlackNotification,
  updateNotifyOkrEmailEnabled,
  updateNotifyOneOnOneEmailEnabled,
  updateNotifyBulkUpdateBySlack,
}) => {
  const { t } = useTranslation()

  const { bulkUpdateSlackDm } = useFlags()

  const toggleUpdateNotifyOkrEmailEnabled = useCallback(() => {
    updateNotifyOkrEmailEnabled(!userSetting.notifyOkrEmailEnabled)
  }, [updateNotifyOkrEmailEnabled, userSetting.notifyOkrEmailEnabled])

  const toggleUpdateNotifyOneOnOneEmailEnabled = useCallback(() => {
    updateNotifyOneOnOneEmailEnabled(!userSetting.notifyOneOnOneEmailEnabled)
  }, [updateNotifyOneOnOneEmailEnabled, userSetting.notifyOneOnOneEmailEnabled])

  const toggleUpdateNotifyBulkUpdateBySlack = useCallback(() => {
    updateNotifyBulkUpdateBySlack(!userSetting.notifyBulkUpdateEnabledOnSlack)
  }, [updateNotifyBulkUpdateBySlack, userSetting.notifyBulkUpdateEnabledOnSlack])

  return (
    <div css={styles.rootCss}>
      <p css={styles.titleCss}>{t('NOTIFICATION')}</p>
      <div css={styles.contentsWrapperCss}>
        <p css={styles.descriptionCss}>{t('ENABLE_EMAIL_NOTIFICATION')}</p>
        <Checkbox
          disabled={loading}
          checked={userSetting.notifyOkrEmailEnabled}
          onChange={toggleUpdateNotifyOkrEmailEnabled}
        >
          {t('ENABLE_OKR_EMAIL_NOTIFICATION')}
        </Checkbox>
        <Checkbox
          disabled={loading}
          checked={userSetting.notifyOneOnOneEmailEnabled}
          onChange={toggleUpdateNotifyOneOnOneEmailEnabled}
        >
          {t('ENABLE_ONE_ON_ONE_EMAIL_NOTIFICATION')}
        </Checkbox>
        {bulkUpdateSlackDm && organizationSlackNotification && (
          <Checkbox
            disabled={loading}
            checked={userSetting.notifyBulkUpdateEnabledOnSlack}
            onChange={toggleUpdateNotifyBulkUpdateBySlack}
          >
            {t('NOTIFY_BULK_UPDATE_BY_SLACK')}
          </Checkbox>
        )}
      </div>
    </div>
  )
}

NotificationSetting.displayName = 'NotificationSetting'

import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useTranslation } from '../../../i18n'
import { findScreen } from '../../../lib/screen'
import { tracker } from '../../../lib/tracking'
import { fontSize } from '../../../styles/font'
import * as urls from '../../../urls'
import { Modal } from '../../ui/Modal'

export type Props = {
  isOpened: boolean
  onClickCheckPlan: () => void
}

export const NoLeftLicensesForAdminModal: React.FC<Props> = ({ isOpened, onClickCheckPlan }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [pushed, setPushed] = useState<'toUsers' | 'toBilling'>()

  return (
    <Modal
      isOpened={isOpened && pushed == null}
      title={t('LICENSE_LIMIT_REACHED_TITLE')}
      cancelText={t('LICENSE_LIMIT_REACHED_TO_USER_LINK')}
      executeText={t('PURCHASE_LICENSE')}
      onClickCancel={() => {
        setPushed('toUsers')
        navigate(urls.usersAdmin)
      }}
      onClickExecute={() => {
        tracker.CheckSubscriptionPlan(findScreen(window.location.pathname), true)
        setPushed('toBilling')
        onClickCheckPlan()
      }}
    >
      <div css={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <span css={{ ...fontSize('medium', 'regular'), whiteSpace: 'pre-wrap' }}>
          {t('LICENSE_LIMIT_REACHED_DESCRIPTION_FOR_ADMIN')}
        </span>
      </div>
    </Modal>
  )
}

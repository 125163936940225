import dayjs from 'dayjs'
import * as yup from 'yup'

import { TFunction } from '../../../i18n'
import { formatDateInput as formatDate } from '../../../lib/date'
import { Language } from '../graphql'

import { FormValue as OneOnOneModalFormValue, MeetingSchedule } from './type'

type ValidateMeetingSchedule = Partial<Pick<MeetingSchedule, 'date' | 'startTime' | 'endTime'>>

type FormSchema = Omit<OneOnOneModalFormValue, 'currentMeeting' | 'meetingSchedule'> & {
  currentMeeting: ValidateMeetingSchedule
  meetingSchedule: Array<ValidateMeetingSchedule>
}
// 開始時刻と終了時刻が逆転している
export const TIME_CONSISTENCY_ERROR_NAME = 'Check consistency of start and end times'

// 終了時刻に過去の日時を指定している
export const TIME_PAST_ERROR_NAME = 'Check if the date is in the past'

// 他の日時の重複をしている
export const TIME_DUPLICATE_ERROR_NAME = 'Check for duplicate schedule'

export const formSchema = (
  t: TFunction,
  currentMeeting?: MeetingSchedule,
): yup.SchemaOf<FormSchema> =>
  yup.object().shape({
    partner: yup.object().shape({
      id: yup.string().required(t('REQUIRED_ERROR')),
      email: yup.string().required(t('REQUIRED_ERROR')),
      firstName: yup.string().required(t('REQUIRED_ERROR')),
      lastName: yup.string().required(t('REQUIRED_ERROR')),
      language: yup.mixed<Language>().required(t('REQUIRED_ERROR')),
      notifyOneOnOneEmailEnabled: yup.boolean().required(t('REQUIRED_ERROR')),
    }),
    // eslint-disable-next-line react/forbid-prop-types
    currentMeeting:
      currentMeeting &&
      dayjs(`${formatDate(currentMeeting.date)} ${currentMeeting.endTime}`).isAfter(dayjs())
        ? yup
            .object()
            .shape({
              date: yup.date().required(t('REQUIRED_ERROR')),
              startTime: yup
                .string()
                .required(t('REQUIRED_ERROR'))
                .test(
                  TIME_CONSISTENCY_ERROR_NAME,
                  t('SELECT_TIME_ERROR'),
                  validateConsisteicyOfStartAndEndTimes,
                ),
              endTime: yup
                .string()
                .required(t('REQUIRED_ERROR'))
                .test(TIME_PAST_ERROR_NAME, t('PAST_TIME_ERROR'), validateTheDateIsInPast)
                .test(
                  TIME_CONSISTENCY_ERROR_NAME,
                  t('SELECT_TIME_ERROR'),
                  validateConsisteicyOfStartAndEndTimes,
                ),
            })
            .test(
              (
                currentMeetingSchedule: ValidateMeetingSchedule | undefined | null,
                context: yup.TestContext,
              ) => {
                const {
                  path,
                  parent: { meetingSchedule: meetingSchedules },
                  createError,
                } = context

                if (!(currentMeetingSchedule && Array.isArray(meetingSchedules))) {
                  return true
                }

                if (validateDuplicateMeetingSchedule(currentMeetingSchedule, meetingSchedules)) {
                  return true
                }

                return createError({
                  path: `${path}.date`,
                  message: t('DUPLICATED_ONEONONE_SCHEDULE'),
                  type: TIME_DUPLICATE_ERROR_NAME,
                })
              },
            )
        : yup
            .object()
            .shape({
              date: yup.date().required(t('REQUIRED_ERROR')),
              startTime: yup.string().required(t('REQUIRED_ERROR')),
              endTime: yup.string().required(t('REQUIRED_ERROR')),
            })
            .default(undefined),
    // eslint-disable-next-line react/forbid-prop-types
    meetingSchedule: yup.array(
      yup
        .object()
        .shape({
          date: yup.date().required(t('REQUIRED_ERROR')),
          startTime: yup
            .string()
            .required(t('REQUIRED_ERROR'))
            .test(
              TIME_CONSISTENCY_ERROR_NAME,
              t('SELECT_TIME_ERROR'),
              validateConsisteicyOfStartAndEndTimes,
            ),
          endTime: yup
            .string()
            .required(t('REQUIRED_ERROR'))
            .test(TIME_PAST_ERROR_NAME, t('PAST_TIME_ERROR'), validateTheDateIsInPast)
            .test(
              TIME_CONSISTENCY_ERROR_NAME,
              t('SELECT_TIME_ERROR'),
              validateConsisteicyOfStartAndEndTimes,
            ),
        })
        .test(
          (
            currentMeetingSchedule: ValidateMeetingSchedule | undefined,
            context: yup.TestContext,
          ) => {
            const {
              path,
              from,
              parent: meetingSchedules,
              createError,
            } = context as yup.TestContext<object> & { from: Array<any> }
            // NOTE: https://github.com/jquense/yup/pull/556#issuecomment-641988687
            const { currentMeeting: currentMeetingSchemaValue } = from[1].value

            if (!(currentMeetingSchedule && Array.isArray(meetingSchedules))) {
              return true
            }
            // NOTE: https://github.com/jquense/yup/issues/204#issuecomment-452608484
            const currentIndex = parseInt(path.split('[')[1].split(']')[0], 10)
            if (currentIndex === undefined) {
              return true
            }
            // チェック対象以外のMeetingSchedule
            const otherMeetingSchedules = meetingSchedules
              .filter((_, index) => index !== currentIndex)
              .concat(
                currentMeetingSchemaValue && Object.keys(currentMeetingSchemaValue).length > 0
                  ? [currentMeetingSchemaValue]
                  : [],
              )

            if (validateDuplicateMeetingSchedule(currentMeetingSchedule, otherMeetingSchedules)) {
              return true
            }

            return createError({
              path: `${path}.date`,
              message: t('DUPLICATED_ONEONONE_SCHEDULE'),
              type: TIME_DUPLICATE_ERROR_NAME,
            })
          },
        ),
    ),
  })

// ユニットテストを実行するため、関数に切り出した
export const isCorrectDate = (comparisonDate: dayjs.Dayjs, endDate: dayjs.Dayjs): boolean =>
  endDate > comparisonDate

export const isDuplicateSchedule = (
  selectedDate: dayjs.Dayjs,
  comparisonStartDate: dayjs.Dayjs,
  comparisonEndDate: dayjs.Dayjs,
): boolean => selectedDate.isBetween(comparisonStartDate, comparisonEndDate, null, '[]')

const validateConsisteicyOfStartAndEndTimes = (_: string | undefined, context: yup.TestContext) => {
  const meetingSchedule = context.parent

  return isCorrectDate(
    dayjs(`${formatDate(meetingSchedule.date)} ${meetingSchedule.startTime}`),
    dayjs(`${formatDate(meetingSchedule.date)} ${meetingSchedule.endTime}`),
  )
}

const validateTheDateIsInPast = (_: string | undefined, context: yup.TestContext) => {
  const meetingSchedule = context.parent
  // endTimeはstringなので、日付比較をするためにdayjsでキャストする
  return isCorrectDate(
    dayjs(),
    dayjs(`${formatDate(meetingSchedule.date)} ${meetingSchedule.endTime}`),
  )
}

const validateDuplicateMeetingSchedule = (
  currentMeetingSchedule: ValidateMeetingSchedule,
  meetingSchedules: ReadonlyArray<ValidateMeetingSchedule>,
) => {
  const selectedStartTime = dayjs(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    `${formatDate(currentMeetingSchedule.date!)} ${currentMeetingSchedule.startTime}`,
  )
  const selectedEndTime = dayjs(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    `${formatDate(currentMeetingSchedule.date!)} ${currentMeetingSchedule.endTime}`,
  )

  // eslint-disable-next-line no-restricted-syntax
  for (const meetingSchedule of meetingSchedules) {
    const comparisonStartTime = dayjs(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      `${formatDate(meetingSchedule.date!)} ${meetingSchedule.startTime}`,
    )
    const comparisonEndTime = dayjs(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      `${formatDate(meetingSchedule.date!)} ${meetingSchedule.endTime}`,
    )

    if (
      isDuplicateSchedule(selectedStartTime, comparisonStartTime, comparisonEndTime) ||
      isDuplicateSchedule(selectedEndTime, comparisonStartTime, comparisonEndTime)
    ) {
      return false
    }
  }

  return true
}

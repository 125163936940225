import { Link } from '../../../../components/ui/Link'
import { StyledText } from '../../../../components/ui/StyledText'
import { useTranslation } from '../../../../i18n'
import { color } from '../../../../styles/newColors'
import { billingAdmin } from '../../../../urls'

export type Props = {
  remainingLicenses: number
  backgroundColor: string
}

export const RemainingLicensesArea: React.VFC<Props> = ({ remainingLicenses, backgroundColor }) => {
  const { t } = useTranslation()

  return (
    <div
      style={{
        backgroundColor: remainingLicenses < 1 ? color('resily-orange-5') : backgroundColor,
        borderRadius: 4,
        padding: '6px 12px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 24,
      }}
    >
      {remainingLicenses < 1 ? (
        <StyledText color="tag-red" fontStyle="regular">
          {t('LICENSE_LIMIT_REACHED')}
        </StyledText>
      ) : (
        <span style={{ display: 'inline-flex', gap: 4 }}>
          <StyledText color="text-bk-50" fontStyle="regular">
            {t('REMAINING_LICENSES')}
          </StyledText>
          <StyledText color="text-bk-100" fontStyle="regular" size="large">
            {remainingLicenses}
          </StyledText>
        </span>
      )}
      <Link href={billingAdmin} css={{ flexShrink: 0 }}>
        {t('PURCHASE_LICENSE')}
      </Link>
    </div>
  )
}

import { reactRouterV6Instrumentation, init, withSentryReactRouterV6Routing } from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { Main } from 'grommet'
import history from 'history/browser'
import React, { FC, Suspense, useCallback, useContext, useEffect, useMemo } from 'react'
import {
  unstable_HistoryRouter as HistoryRouter,
  Routes,
  Route,
  Outlet,
  Navigate,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
  useNavigate,
  To,
  matchPath,
} from 'react-router-dom'
import { QueryParamProvider, transformSearchStringJsonSafe } from 'use-query-params'

import { CheckinAlert } from './components/domain/CheckinAlert'
import { CreateNewOkrTermAlert } from './components/domain/CreateNewOkrTermAlert'
import { Container } from './components/layout/Container'
import { useCheckinAlert, useCreateNewOkrTermAlert } from './components/layout/Container/hooks'
import { GloNavAndMain } from './components/layout/GloNavAndMain'
import { TrialBar, HEIGHT as TRIAL_BAR_HEIGHT } from './components/layout/TrialBar'
import { NoLeftLicensesModal } from './components/standalone/NoLeftLicensesModal'
import { TrialExpiredModal } from './components/standalone/TrialExpiredModal'
import { ProgressBar } from './components/ui/ProgressBar'
import { AuthContext } from './contexts/AuthContext'
import { OkrTermIdContext } from './contexts/OkrTermIdContext'
import { OrganizationContext } from './contexts/OrganizationContext'
import { useCurrentUser } from './contexts/UserContext'
import { trackChannelTalkPageView } from './lib/channelTalk'
import { useSelfServeConfig } from './lib/domain/organization/useSelfServeConfig'
import { sentryConf } from './lib/tracking'
import { AccountSettingContainer } from './pages/AccountSetting'
import { AccountSetupContainer } from './pages/AccountSetup'
import { AccountSetupCompleteContainer } from './pages/AccountSetupComplete'
import { ActionPlanDetailContainer } from './pages/ActionPlanDetail'
import { ActionPlanDetailEditContainer } from './pages/ActionPlanDetailEdit'
import { AdminBillingContainer } from './pages/AdminBilling'
import { AdminFixedAgendaCreateContainer } from './pages/AdminFixedAgendaCreate'
import { AdminFixedAgendaEditContainer } from './pages/AdminFixedAgendaEdit'
import { AdminGroupsSettingContainer } from './pages/AdminGroupsSetting'
import { AdminIntegrationsContainer } from './pages/AdminIntegration'
import { AdminOkrGlossaryContainer } from './pages/AdminOkrGlossary'
import { AdminOkrTermsContainer } from './pages/AdminOkrTerms'
import { AdminOneOnOneContainer } from './pages/AdminOneOnOne'
import { AdminOrganizationContainer } from './pages/AdminOrganization'
import { AdminUserContainer } from './pages/AdminUser'
import { AdminUsersContainer } from './pages/AdminUsers'
import { CheckinSummaryContainer } from './pages/CheckinSummary'
import { CheckinSummaryEditContainer } from './pages/CheckinSummaryEdit'
import { ResetEmailContainer } from './pages/EmailReset'
import { GroupDetailContainer } from './pages/GroupDetail'
import { GroupsImportContainer } from './pages/GroupsImport'
import { KeyResultDetailContainer } from './pages/KeyResultDetail'
import { KeyResultDetailEditContainer } from './pages/KeyResultDetailEdit'
import { LabeledNoteListContainer } from './pages/LabeledNoteList'
import { LabeledPersonalNoteListContainer } from './pages/LabeledPersonalNoteList'
import { NotFound } from './pages/NotFound'
import { NoteDetailContainer } from './pages/NoteDetail'
import { NoteDetailEditContainer } from './pages/NoteDetailEdit'
import { LabelListContainer } from './pages/NoteLabelList'
import { NotificationListContainer } from './pages/NotificationList'
import { ObjectiveDetailContainer } from './pages/ObjectiveDetail'
import { ObjectiveDetailEditContainer } from './pages/ObjectiveDetailEdit'
import { OkrContainer } from './pages/Okr'
import { OkrMapContainer } from './pages/OkrMap'
import { NodeContainer, TreeContainer, TreesContainer } from './pages/OkrMap/deprecated'
import { OneOnOneHistoryContainer } from './pages/OneOnOneHistory'
import { OneOnOnesContainer } from './pages/OneOnOnes'
import { OneOnOnesDetailContainer } from './pages/OneOnOnesDetail'
import { PasswordResetContainer } from './pages/PasswordReset'
import { PasswordResetConfirmContainer } from './pages/PasswordResetConfirm'
import { PersonalNoteDetailContainer } from './pages/PersonalNoteDetail'
import { PersonalNoteDetailEditContainer } from './pages/PersonalNoteDetailEdit'
import { PersonalNoteLabelListContainer } from './pages/PersonalNoteLabelList'
import { SecuritySettingContainer } from './pages/SecuritySettings'
import { SettingContainer } from './pages/Setting'
import { SignInContainer } from './pages/SignIn'
import { SignUpContainer } from './pages/SignUp'
import { SmartHREmplyeeContainer } from './pages/SmartHREmployee'
import { TermContainer } from './pages/Term'
import { BetaHomeRedirectContainer, HomeContainer, MyHomeContainer } from './pages/User/beta'
import { ActionPlanContainer as HomeActionPlanContainer } from './pages/User/beta/tabs/ActionPlan'
import { ActivityContainer as HomeActivityContainer } from './pages/User/beta/tabs/Activity'
import { NotesContainer as HomeNoteContainer } from './pages/User/beta/tabs/Note'
import { OkrContainer as HomeOkrContainer } from './pages/User/beta/tabs/Okr'
import { UsersContainer } from './pages/Users'
import { UsersImportContainer } from './pages/UsersImport'
import * as urls from './urls'

// initialize Sentry
// react-routerと統合するためにここで初期化
// 本来はlib/tracking.tsでinitialize関数を定義したいが
// historyパッケージが型情報をexportしていないためここで実行
if (sentryConf.dsn !== '') {
  init({
    dsn: sentryConf.dsn,
    environment: process.env.REACT_APP_ENV,
    release: process.env.REACT_APP_COMMIT_SHA,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
    ],
    tracesSampleRate: sentryConf.tracesSampleRate,
    beforeBreadcrumb(breadcrumb, _hint) {
      if (breadcrumb.category === 'xhr') {
        const ignoreDomains = [
          'www.google-analytics.com', // GA
          'api-js.mixpanel.com', // Mixpanel
          'r.lr-in.com', // LogRocket
          'cdn.resily.com', // CDN
          'app.launchdarkly.com', // Launch Darkly
          'events.launchdarkly.com', // Launch Darkly
        ]
        const url: string = breadcrumb.data?.url ?? ''
        if (ignoreDomains.some((e) => url.includes(e))) {
          return null
        }
      }
      return breadcrumb
    },
    // See: https://docs.sentry.io/platforms/javascript/configuration/filtering/
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
      // reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
      // See https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications.',
      'ChunkLoadError',
      // See https://github.com/apollographql/apollo-client/issues/6769
      'AbortError',
      // Slate Errors
      /Cannot get the start point in the node at path.*/,
      /Cannot resolve a Slate point from DOM point.*/,
      /Cannot resolve a Slate node from DOM node.*/,
    ],
    denyUrls: [
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
      // Channel Talk
      /cdn\.channel\.io/i,
      // LogRocket
      /r\.lr-in\.com/i,
      // Mixpanel
      /api-js\.mixpanel\.com/i,
      // GA
      /www\.google-analytics\.com/i,
      // Resily CDN version json
      /cdn\.resily\.com\/version\/app\.json/i,
      // Launch Darkly
      /(app|events)\.launchdarkly\.com/i,
    ],
  })
}

const SentryRoutes = withSentryReactRouterV6Routing(Routes)

// https://github.com/pbeshai/use-query-params/issues/108#issuecomment-785209454
// https://github.com/pbeshai/use-query-params/blob/b14c97ec2e7b1dfaca51d4d17439f9c306b34dba/packages/use-query-params-adapter-react-router-6/src/index.ts
const RouteAdapter: FC = ({ children }) => {
  const navigate = useNavigate()
  const location = useLocation()

  const adaptedHistory = React.useMemo(
    () => ({
      replace(to: To) {
        navigate(to, { replace: true, state: location.state })
      },
      push(to: To) {
        navigate(to, { replace: false, state: location.state })
      },
    }),
    [navigate, location],
  )
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return children({ history: adaptedHistory, location })
}
RouteAdapter.displayName = 'RouteAdapter'

type AuthRootComponentProps = { withGloNav?: boolean }

const AuthRootComponent = ({ withGloNav = true }: AuthRootComponentProps) => {
  const navigate = useNavigate()
  const authenticated = useContext(AuthContext)
  const organization = useContext(OrganizationContext)
  const selfServeConfig = useSelfServeConfig()
  const user = useCurrentUser()

  const handleClickCheckPlan = useCallback(() => navigate(urls.billingAdmin), [navigate])
  const handleClickBookTutorial = useCallback(
    () => window.open(urls.ExternalUrls.BOOK_TUTORIAL_PAGE),
    [],
  )

  // サブスク中はトライアルバー表示しない
  const isTrialBarShow = selfServeConfig.isSelfServe && selfServeConfig.state !== 'subscription'

  // サブスク期間中の場合、ライセンス数が上限突破してたらモーダル表示
  const isShowNoLeftLicensesModal =
    selfServeConfig.isSelfServe &&
    selfServeConfig.state === 'subscription' &&
    selfServeConfig.remainingLicenses != null &&
    selfServeConfig.remainingLicenses < 0

  const { shouldShowCreateNewOkrTermAlert, closeAlert: closeCreateNewOkrTermAlert } =
    useCreateNewOkrTermAlert({
      organization,
      user,
    })
  const {
    checkinPageUrl,
    shouldShowAlert: shouldShowCheckinAlert,
    title: checkinTitle,
    message: checkinMessage,
    closeAlert: closeCheckinAlert,
    submitEvent,
  } = useCheckinAlert({ user, organization, isHiddenAlert: shouldShowCreateNewOkrTermAlert })

  const mainCss = useMemo(
    () =>
      isTrialBarShow
        ? {
            height: `calc(100vh - ${TRIAL_BAR_HEIGHT}px)`,
            marginTop: `${TRIAL_BAR_HEIGHT}px`,
          }
        : { height: '100vh' },
    [isTrialBarShow],
  )

  // 画面遷移時にChannelTalkのポップアップを表示するための設定
  trackChannelTalkPageView()

  return authenticated && user ? (
    <>
      {selfServeConfig.isSelfServe && (
        <>
          {isTrialBarShow && (
            <TrialBar
              onClickCheckPlan={handleClickCheckPlan}
              onClickBookTutorial={handleClickBookTutorial}
            />
          )}
          <TrialExpiredModal
            isOpened={
              !matchPath({ path: urls.billingAdmin }, window.location.pathname) &&
              (selfServeConfig.state === 'trial-expired' ||
                selfServeConfig.state === 'subscription-expired')
            }
            isAdmin={user.admin}
            onClickCheckPlan={handleClickCheckPlan}
          />
          <NoLeftLicensesModal
            isOpened={isShowNoLeftLicensesModal}
            isAdmin={user.admin}
            onClickCheckPlan={handleClickCheckPlan}
          />
        </>
      )}
      {withGloNav ? (
        <GloNavAndMain css={mainCss}>
          <Outlet />
        </GloNavAndMain>
      ) : (
        <WithoutGloNavComponent css={mainCss} />
      )}
      {shouldShowCreateNewOkrTermAlert && (
        <CreateNewOkrTermAlert onClose={closeCreateNewOkrTermAlert} />
      )}
      {shouldShowCheckinAlert && (
        <CheckinAlert
          href={checkinPageUrl}
          title={checkinTitle}
          message={checkinMessage}
          onClose={closeCheckinAlert}
          onClick={submitEvent}
        />
      )}
    </>
  ) : (
    <Navigate
      to={{
        pathname: urls.signIn,
        search: `from=${encodeURIComponent(window.location.href)}`,
      }}
    />
  )
}

const WithoutGloNavComponent: React.FC = (props) => (
  <Main {...props}>
    <Outlet />
  </Main>
)

export const AppRoutes: React.FC = () => {
  const { updateOkrTermId } = useContext(OkrTermIdContext)
  const isAdmin = !!useCurrentUser()?.admin
  const { isSelfServe } = useSelfServeConfig()
  const handleOkrTermLoaded = useCallback(
    (id: string | null) => {
      updateOkrTermId(id)
    },
    [updateOkrTermId],
  )
  const props = {
    onOkrTermLoaded: handleOkrTermLoaded,
  }

  return (
    <HistoryRouter history={history}>
      <Suspense
        fallback={
          <ProgressBar
            css={{
              position: 'fixed',
              top: 0,
              zIndex: 9999,
            }}
          />
        }
      >
        <QueryParamProvider
          ReactRouterRoute={RouteAdapter}
          stringifyOptions={{
            transformSearchString: transformSearchStringJsonSafe,
          }}
        >
          <Container>
            <SentryRoutes>
              {/* 認証する、かつグロナビを表示する */}
              <Route path={urls.root} element={<AuthRootComponent />}>
                <Route index element={<Navigate to={urls.myHome} />} />

                <Route
                  path={urls.betaHomeRootForRedirect}
                  element={<BetaHomeRedirectContainer {...props} />}
                />
                <Route path={urls.myHome} element={<MyHomeContainer {...props} />} />
                <Route path={urls.home} element={<HomeContainer {...props} />}>
                  <Route index element={<HomeOkrContainer {...props} />} />
                  <Route path={urls.homeOkr} element={<HomeOkrContainer {...props} />} />
                  <Route
                    path={urls.homeActionPlan}
                    element={<HomeActionPlanContainer {...props} />}
                  />
                  <Route path={urls.homeNote} element={<HomeNoteContainer {...props} />} />
                  <Route path={urls.homeActivity} element={<HomeActivityContainer {...props} />} />
                </Route>

                <Route path={urls.trees} element={<OkrMapContainer {...props} />} />
                <Route path={urls.actionPlan} element={<ActionPlanDetailContainer {...props} />} />
                <Route
                  path={urls.actionPlanEdit}
                  element={<ActionPlanDetailEditContainer {...props} />}
                />
                <Route path={urls.term} element={<TermContainer {...props} />} />
                <Route path={urls.deprecatedTrees} element={<TreesContainer {...props} />} />
                <Route
                  path={urls.deprecatedExperimentalTrees}
                  element={<OkrMapContainer {...props} />}
                />

                <Route path={urls.deprecatedTree} element={<TreeContainer {...props} />} />
                <Route path={urls.deprecatedNode} element={<NodeContainer {...props} />} />
                <Route path={urls.deprecatedSubtree} element={<TreeContainer {...props} />} />

                <Route path={urls.okr} element={<OkrContainer {...props} />} />

                <Route
                  path={urls.objectiveEdit}
                  element={<ObjectiveDetailEditContainer {...props} />}
                />
                <Route path={urls.objective} element={<ObjectiveDetailContainer {...props} />} />

                <Route
                  path={urls.keyResultEdit}
                  element={<KeyResultDetailEditContainer {...props} />}
                />
                <Route path={urls.keyResult} element={<KeyResultDetailContainer {...props} />} />

                <Route path={urls.users} element={<UsersContainer {...props} />} />

                <Route path={urls.note} element={<NoteDetailContainer {...props} />} />
                <Route path={urls.noteEdit} element={<NoteDetailEditContainer {...props} />} />

                <Route
                  path={urls.personalNote}
                  element={<PersonalNoteDetailContainer {...props} />}
                />
                <Route
                  path={urls.personalNoteEdit}
                  element={<PersonalNoteDetailEditContainer {...props} />}
                />
                <Route
                  path={urls.personalNoteLabels}
                  element={<PersonalNoteLabelListContainer {...props} />}
                />

                <Route path={urls.setting} element={<SettingContainer {...props} />} />
                <Route
                  path={urls.accountSetting}
                  element={<AccountSettingContainer {...props} />}
                />

                {isAdmin && (
                  <Route path={urls.userAdmin} element={<AdminUserContainer {...props} />} />
                )}

                <Route
                  path={urls.groupsSettingAdmin}
                  element={<AdminGroupsSettingContainer {...props} />}
                />
                <Route path={urls.group} element={<GroupDetailContainer {...props} />} />

                {isAdmin && (
                  <>
                    <Route
                      path={urls.okrTermsAdmin}
                      element={<AdminOkrTermsContainer {...props} />}
                    />
                    <Route path={urls.usersAdmin} element={<AdminUsersContainer {...props} />} />
                    <Route
                      path={urls.usersImportAdmin}
                      element={<UsersImportContainer {...props} />}
                    />
                    <Route
                      path={urls.oneOnOneAdmin}
                      element={<AdminOneOnOneContainer {...props} />}
                    />
                    <Route
                      path={urls.createFixedAgendaAdmin}
                      element={<AdminFixedAgendaCreateContainer {...props} />}
                    />
                    <Route
                      path={urls.editFixedAgendaAdmin}
                      element={<AdminFixedAgendaEditContainer {...props} />}
                    />

                    <Route
                      path={urls.groupsImportAdmin}
                      element={<GroupsImportContainer {...props} />}
                    />
                    <Route
                      path={urls.organizationAdmin}
                      element={<AdminOrganizationContainer {...props} />}
                    />
                    <Route
                      path={urls.okrGlossaries}
                      element={<AdminOkrGlossaryContainer {...props} />}
                    />
                    <Route
                      path={urls.integrationsAdmin}
                      element={<AdminIntegrationsContainer {...props} />}
                    />
                    <Route path={urls.security} element={<SecuritySettingContainer {...props} />} />
                    <Route
                      path={urls.integrationsSmartHREmployees}
                      element={<SmartHREmplyeeContainer {...props} />}
                    />
                    {isSelfServe && (
                      <Route
                        path={urls.billingAdmin}
                        element={<AdminBillingContainer {...props} />}
                      />
                    )}
                  </>
                )}

                <Route path={urls.oneOnOne} element={<OneOnOnesContainer {...props} />} />
                <Route
                  path={urls.oneOnOneDetail}
                  element={<OneOnOnesDetailContainer {...props} />}
                />

                <Route
                  path={urls.notifications}
                  element={<NotificationListContainer {...props} />}
                />
                <Route path={urls.noteLabels} element={<LabelListContainer {...props} />} />

                <Route path={urls.labeledNotes} element={<LabeledNoteListContainer {...props} />} />
                <Route
                  path={urls.labeledPersonalNotes}
                  element={<LabeledPersonalNoteListContainer {...props} />}
                />
                <Route
                  path={urls.checkinSummary}
                  element={<CheckinSummaryContainer {...props} />}
                />
                <Route
                  path={urls.checkinSummaryEdit}
                  element={<CheckinSummaryEditContainer {...props} />}
                />
              </Route>

              {/* 認証はするけどグロナビを表示しない */}
              <Route path={urls.root} element={<AuthRootComponent withGloNav={false} />}>
                <Route path={urls.oneOnOneHistory} element={<OneOnOneHistoryContainer />} />
              </Route>

              {/* 認証しない、かつグロナビを表示しない */}
              <Route path={urls.root} element={<WithoutGloNavComponent />}>
                <Route path={urls.accountSetup} element={<AccountSetupContainer />} />
                <Route
                  path={urls.accountSetupComplete}
                  element={<AccountSetupCompleteContainer />}
                />
                <Route path={urls.signIn} element={<SignInContainer />} />
                <Route path={urls.signUp} element={<SignUpContainer />} />
                <Route path={urls.passwordReset} element={<PasswordResetContainer />} />
                <Route
                  path={urls.passwordResetConfirm}
                  element={<PasswordResetConfirmContainer />}
                />
                <Route path={urls.emailResetConfirm} element={<ResetEmailContainer />} />
                <Route path={urls.oneOnOneHistory} element={<OneOnOneHistoryContainer />} />
              </Route>

              <Route path="*" element={<NotFound />} />
            </SentryRoutes>
          </Container>
        </QueryParamProvider>
      </Suspense>
    </HistoryRouter>
  )
}

AppRoutes.displayName = 'AppRoutes'

import { yupResolver } from '@hookform/resolvers/yup'
import { useModal } from '@resily/geisha'
import dayjs from 'dayjs'
import { Box, Grid, GridProps, ThemeContext } from 'grommet'
import React, { useRef, useMemo, useCallback, MouseEventHandler, useState } from 'react'
import {
  Controller,
  SubmitHandler,
  useForm,
  Control,
  FieldPath,
  useFieldArray,
  UseFormTrigger,
  UseFormGetValues,
  UseFormSetValue,
  FieldErrors,
  ControllerRenderProps,
} from 'react-hook-form'
import { v1 as uuid } from 'uuid'

import { Button } from '../../../components/ui/Button'
import { ErrorMessage } from '../../../components/ui/ErrorMessage'
import { Icon } from '../../../components/ui/Icon'
import { Popover } from '../../../components/ui/Popover'
import { RichTextEditor } from '../../../components/ui/RichTextEditor'
import { StyledText } from '../../../components/ui/StyledText'
import { TextButton } from '../../../components/ui/TextButton'
import { UserOption } from '../../../components/ui/UserOption'
import { useCurrentUser } from '../../../contexts/UserContext'
import { User } from '../../../graphql'
import { useTranslation } from '../../../i18n'
import { formatDateInput as formatDate } from '../../../lib/date'
import { getUserSearchText } from '../../../lib/domain/user/searchText'
import { fontSize } from '../../../styles/font'
import { color } from '../../../styles/newColors'
import { ExternalUrls } from '../../../urls'
import { SelectUser } from '../SelectUser'
import { Language, OneOnOneUserFragment, useUserOneOnOnesQuery, useUsersQuery } from '../graphql'

import { OneOnOneCancelModal } from './OneOnOneCancelModal'
import { OneOnOneModalDateParts } from './OneOnOneModalDateParts'
import { OneOnOneModalTimeParts, TIME_FORMAT } from './OneOnOneModalTimeParts'
import {
  isCorrectDate,
  formSchema,
  TIME_CONSISTENCY_ERROR_NAME,
  TIME_DUPLICATE_ERROR_NAME,
  TIME_PAST_ERROR_NAME,
} from './formSchema'
import { FormValue, MeetingSchedule } from './type'

export type Disabled = {
  partner?: boolean
}

export type SelectedPartner = {
  user: OneOnOneUserFragment
}

export type ModalSubmitHandler = (...params: Parameters<SubmitHandler<FormValue>>) => Promise<void>

export type Props = {
  title: string
  disabled?: Disabled
  selectedPartner?: SelectedPartner
  currentMeeting?: MeetingSchedule
  meetings?: FormValue['meetingSchedule']
  onSubmit: ModalSubmitHandler
  onClose: () => void
}

// 1on1の日程が存在しない時に表示する1on1の日程の初期データ
const generateInitialMeetingSchedule = (now: dayjs.Dayjs): MeetingSchedule => ({
  id: '',
  date: new Date(now.format('YYYY/MM/DD')),
  ...generateNextMeetingTime(now),
  description: {
    treeJson: '',
    plainText: '',
  },
})

// 現在時刻を15分単位で切り上げた時刻を元に開始時刻と終了時刻（開始時刻+1h）を生成する
// 例： nowが10:48だった場合、 開始時刻：11:00、終了時刻：12:00
const generateNextMeetingTime = (
  now: dayjs.Dayjs,
): {
  startTime: string
  endTime: string
} => {
  const startTime = now.add(15 - (now.minute() % 15), 'minute')
  const endTime = startTime.add(1, 'hour')
  return {
    startTime: startTime.format(TIME_FORMAT),
    endTime: endTime.format(TIME_FORMAT),
  }
}

export const getTargetUsers = <U extends Pick<User, 'id' | 'isDisabled'>>(
  activeUsers: ReadonlyArray<U>,
  loginUser: string,
  excludedUsers?: ReadonlyArray<U>,
): Array<U> => {
  const targetUsers: Array<U> = []
  // ログインユーザー除去
  const selectUsers = activeUsers.filter((o) => o.id !== loginUser)
  // 選択されているユーザーを除外して返却
  if (excludedUsers) {
    const cIdList = excludedUsers.map((c) => c.id)
    return targetUsers.concat(
      selectUsers.filter((u) => cIdList.findIndex((cid) => cid === u.id) === -1),
    )
  }

  return targetUsers.concat(selectUsers)
}

const GRID_COLUMNS: GridProps['columns'] = ['61px', 'auto']
const GRID_GAP: GridProps['gap'] = { row: '24px', column: '32px' }

export const OneOnOneModal: React.FC<Props> = ({
  title,
  disabled = { partner: false },
  selectedPartner,
  currentMeeting,
  meetings = [],
  onSubmit,
  onClose,
}) => {
  const [Modal] = useModal(true)

  const loginUser = useCurrentUser()
  const { data: userData } = useUsersQuery()
  const { t } = useTranslation()
  const currentTime = useMemo<Date>(() => dayjs().startOf('minute').toDate(), [])

  const {
    trigger,
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors, dirtyFields, isSubmitting },
  } = useForm<FormValue>({
    defaultValues: {
      partner: {
        id: selectedPartner?.user.id ?? '',
        email: selectedPartner?.user.email ?? '',
        firstName: selectedPartner?.user.firstName ?? '',
        lastName: selectedPartner?.user.lastName ?? '',
        language: selectedPartner?.user.userSetting.language ?? Language.Japanese,
        notifyOneOnOneEmailEnabled:
          selectedPartner?.user.userSetting.notifyOneOnOneEmailEnabled ?? false,
      },
      currentMeeting,
      meetingSchedule:
        currentMeeting || meetings.length > 0
          ? meetings
          : [generateInitialMeetingSchedule(dayjs())],
    },
    resolver: yupResolver(formSchema(t, currentMeeting)),
  })

  const isDirty = !!Object.keys(dirtyFields).length

  const { fields, append, remove } = useFieldArray({ control, name: 'meetingSchedule' })
  const addMeetingButtonRef = useRef<HTMLButtonElement>(null)

  const handleRemoveMeeting = useCallback<
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) => void
  >(
    (e, index) => {
      e.preventDefault()
      const { id } = fields[index]
      const animationTarget = e.currentTarget.closest<HTMLDivElement>(`[data-animation-id="${id}"]`)
      if (!animationTarget) {
        remove(index)
        trigger(['meetingSchedule', 'currentMeeting'])
        return
      }
      animationTarget.dataset.animationStart = 'true'
      animationTarget.ontransitionend = () => {
        remove(index)
        trigger(['meetingSchedule', 'currentMeeting'])
      }
    },
    [fields, remove, trigger],
  )

  const handleAddMeeting = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      e.preventDefault()
      const meetingSchedule = getValues('meetingSchedule')
      const currentMeetingSchedule = getValues('currentMeeting')

      const meetingSchedules = (
        currentMeetingSchedule && Object.keys(currentMeetingSchedule).length > 0
          ? meetingSchedule.concat(
              dayjs().isBefore(
                dayjs(
                  `${formatDate(currentMeetingSchedule.date)} ${currentMeetingSchedule.endTime}`,
                ),
              )
                ? [currentMeetingSchedule]
                : [],
            )
          : meetingSchedule
      ).sort((a: MeetingSchedule, b: MeetingSchedule) =>
        dayjs(`${formatDate(a.date)} ${a.endTime}`).isAfter(`${formatDate(b.date)} ${b.endTime}`)
          ? 1
          : -1,
      )
      if (meetingSchedules.length > 0) {
        append({
          date: dayjs(meetingSchedules[meetingSchedules.length - 1].date)
            .add(1, 'week')
            .toDate(),
          startTime: meetingSchedules[meetingSchedules.length - 1].startTime,
          endTime: meetingSchedules[meetingSchedules.length - 1].endTime,
          description: meetingSchedules[meetingSchedules.length - 1].description,
        })
      }

      if (meetingSchedules.length === 0) {
        append(generateInitialMeetingSchedule(dayjs()))
      }

      // 追加したフォームが表示されるまで待ってからスクロールしたいのでsetTimeoutを使う
      setTimeout(
        () =>
          addMeetingButtonRef?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
          }),
        500,
      )
    },
    [append, getValues],
  )

  const { data: userOneOnOnes } = useUserOneOnOnesQuery({ variables: { now: currentTime } })
  const userOneOnOneQuery = userOneOnOnes?.userOneOnOnes

  const first = useRef<HTMLButtonElement>(null)
  const handleExecute = useCallback(() => {
    first?.current?.form?.submitButton.click()
  }, [])
  const [isShowCancelModal, setIsShowCancelModal] = useState<boolean>(false)

  const handleDeleteCancel = useCallback(() => {
    setIsShowCancelModal(false)
  }, [])

  const handleClose = useCallback(() => {
    // フォームの編集をしていない or 警告モーダルからモーダルの破棄を実行した場合
    if (!isDirty || isShowCancelModal) {
      onClose()
    }

    setIsShowCancelModal(true)
  }, [isDirty, isShowCancelModal, onClose])

  const handleSelectedUser = useCallback(
    (userId: string, onChange: ControllerRenderProps['onChange']) => {
      const selectedUser = userData?.users.find((u) => u.id === userId)
      if (!selectedUser) return
      onChange(userId)
      setValue('partner.email', selectedUser.email)
      setValue('partner.firstName', selectedUser.firstName)
      setValue('partner.lastName', selectedUser.lastName)
      setValue('partner.language', selectedUser.userSetting.language)
      setValue(
        'partner.notifyOneOnOneEmailEnabled',
        selectedUser.userSetting.notifyOneOnOneEmailEnabled,
      )
      trigger()
    },
    [setValue, trigger, userData?.users],
  )

  if (!loginUser) return null

  const partnerUserIds = userOneOnOneQuery?.map((o) => o.partnerUser.id) ?? []
  const activeUsers = userData?.users.filter((u) => !u.isDisabled) ?? []
  const excludedUsers =
    userData?.users.filter((val) => val.id === loginUser.id || partnerUserIds.includes(val.id)) ??
    []
  const candidateUsers: Array<OneOnOneUserFragment> = getTargetUsers<OneOnOneUserFragment>(
    activeUsers,
    loginUser.id,
    excludedUsers,
  )

  const partnerUserArr = selectedPartner
    ? ([selectedPartner.user] as Array<OneOnOneUserFragment>)
    : []

  const users = [...candidateUsers, ...partnerUserArr]

  // 現在1on1の日程が存在せず、新規で日程を作成するかどうか
  const isCreateNewMeetingSchedule = meetings.length === 0

  // ボタンを非活性にするかどうかを判定するためのboolean
  const checkErrors =
    // 日程が存在せずに新規で追加する場合は、編集していなくてもボタンを活性化する
    !(isCreateNewMeetingSchedule || isDirty) ||
    Boolean(Object.keys(errors).length) ||
    !getValues('partner.id')

  return (
    <>
      {isShowCancelModal && (
        <OneOnOneCancelModal onClickDelete={handleClose} onClickCancel={handleDeleteCancel} />
      )}
      <Modal isOpen size="medium" restrictFocus={false} onClose={handleClose}>
        <Modal.Header title={title} />
        <Modal.Content>
          <form
            onSubmit={handleSubmit(onSubmit)}
            // HACK: geishaのModalの幅を強制的に変更
            css={{ '*[role="dialog"]:has(* &)': { maxWidth: 572 } }}
          >
            <ThemeContext.Extend value={{ global: { drop: { zIndex: '201' } } }}>
              <Grid rows={['42px', 'auto']} columns={GRID_COLUMNS} gap={GRID_GAP} align="center">
                {/* 『相手』ラベル */}
                <Box direction="row">
                  <StyledText css={{ fontSize: '12px' }} weight="bold">
                    {t('PARTNER')}
                  </StyledText>
                  <StyledText color="resily-orange-100" css={{ fontSize: '12px' }} weight="bold">
                    *
                  </StyledText>
                </Box>
                {/* 『相手』フォーム */}
                <Box direction="row" align="center">
                  <ThemeContext.Extend
                    value={{
                      global: {
                        control: {
                          border: {
                            color: errors.partner?.id ? '#D42922' : '#DADADA',
                            radius: '4px',
                          },
                        },
                        drop: {
                          border: {
                            radius: '4px',
                          },
                        },
                        input: {
                          font: {
                            weight: 'normal',
                          },
                          padding: {
                            horizontal: '16px',
                          },
                        },
                      },
                      select: {
                        control: {
                          extend: {
                            fontSize: 12,
                            lineHeight: '12px',
                          },
                        },
                        container: {
                          extend: {
                            padding: '',
                            maxWidth: '510px',
                          },
                        },
                        icons: {
                          down: (
                            <Icon
                              type="selectDown"
                              color={color('border-bk-20')}
                              css={{ width: '8px' }}
                            />
                          ),
                          up: (
                            <Icon
                              type="selectDown"
                              color={color('border-bk-20')}
                              css={{ width: '8px', transform: 'rotate(-180deg)' }}
                            />
                          ),
                        },
                        options: {
                          text: {
                            weight: 'normal',
                          },
                        },
                      },
                    }}
                  >
                    <Controller
                      name="partner.id"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <div css={{ minWidth: '210px' }}>
                          <SelectUser
                            value={value}
                            placeholder={t('SELECT_X', { x: t('USER') })}
                            options={users.map((o) => ({
                              value: o.id,
                              label: (
                                <UserOption
                                  firstName={o.firstName}
                                  lastName={o.lastName}
                                  avatarUrl={o.avatar?.url}
                                  isUserDisabled={o.isDisabled}
                                />
                              ),
                              searchText: getUserSearchText(o),
                            }))}
                            onChange={(userId: string) => {
                              handleSelectedUser(userId, onChange)
                            }}
                            isDisabled={disabled.partner}
                          />
                        </div>
                      )}
                    />
                  </ThemeContext.Extend>
                  <ErrorMessage>{errors.partner?.id && t('REQUIRED_ERROR')}</ErrorMessage>
                  <Popover
                    targetContent={
                      <Popover.TargetContent.Help
                        css={{ marginLeft: '8px', verticalAlign: 'initial' }}
                      />
                    }
                    dropdownContent={
                      <Popover.DropdownContent
                        text={t('CREATE_ONEONONE_SELECT_USER_HELP')}
                        link={{
                          text: t('MORE_DETAILS'),
                          href: ExternalUrls.ADD_USER,
                        }}
                      />
                    }
                  />
                </Box>
                {currentMeeting && (
                  <div
                    css={{
                      gridColumn: '1 / 3',
                    }}
                  >
                    <Grid
                      columns={GRID_COLUMNS}
                      gap={{ ...GRID_GAP, row: '0' }}
                      align="center"
                      as="div"
                    >
                      <MeetingScheduleForm
                        setValue={setValue}
                        getValues={getValues}
                        trigger={trigger}
                        control={control}
                        scheduleError={errors.currentMeeting}
                        disabled={
                          dayjs().isAfter(
                            `${formatDate(currentMeeting.date)} ${currentMeeting.endTime}`,
                          )
                            ? {
                                date: true,
                                startTime: true,
                                endTime: true,
                                description: true,
                              }
                            : undefined
                        }
                        meetingScheduleNames={{
                          date: `currentMeeting.date`,
                          startTime: `currentMeeting.startTime`,
                          endTime: `currentMeeting.endTime`,
                          description: `currentMeeting.description`,
                        }}
                        showRemoveButton={false}
                      />
                    </Grid>
                  </div>
                )}
                {currentMeeting && (
                  <div
                    css={{
                      gridColumn: '1 / 3',
                      color: color('text-bk-50'),
                    }}
                  >
                    <p css={{ ...fontSize('medium', 'bold') }}>{t('UPCOMING_DATES')}</p>
                    {fields.length === 0 && (
                      <p
                        css={{
                          marginTop: '8px',
                          textAlign: 'center',
                          ...fontSize('small'),
                        }}
                      >
                        {t('NEXT_ONEONONE_NOTHING')}
                      </p>
                    )}
                  </div>
                )}
                {fields.map(({ id }, index) => (
                  <div
                    key={id}
                    data-animation-id={id}
                    data-animation-start={false}
                    css={{
                      gridColumn: '1 / 3',
                      maxHeight: '1000px',
                      transition: 'all 0.4s ease-out',
                      '&[data-animation-start="true"]': {
                        maxHeight: 0,
                        overflow: 'hidden',
                      },
                    }}
                  >
                    <Grid
                      columns={GRID_COLUMNS}
                      gap={{ ...GRID_GAP, row: '0' }}
                      align="center"
                      as="div"
                    >
                      <MeetingScheduleForm
                        setValue={setValue}
                        getValues={getValues}
                        trigger={trigger}
                        control={control}
                        scheduleError={
                          errors.meetingSchedule ? errors.meetingSchedule[index] : undefined
                        }
                        meetingScheduleNames={{
                          date: `meetingSchedule.${index}.date`,
                          startTime: `meetingSchedule.${index}.startTime`,
                          endTime: `meetingSchedule.${index}.endTime`,
                          description: `meetingSchedule.${index}.description`,
                        }}
                        showRemoveButton={currentMeeting ? true : index > 0}
                        handleRemove={(e) => handleRemoveMeeting(e, index)}
                      />
                    </Grid>
                  </div>
                ))}
              </Grid>
              <TextButton
                ref={addMeetingButtonRef}
                icon="plus"
                color="text-bk-30"
                padding="none"
                onClick={handleAddMeeting}
                css={{ marginLeft: 'auto', marginTop: '16px', height: '22px' }}
              >
                {t('ADD_MEETING_DATE')}
              </TextButton>

              <Button type="submit" id="submitButton" ref={first} css={{ display: 'none' }} />
            </ThemeContext.Extend>
          </form>
        </Modal.Content>
        <Modal.Footer
          cancelType="tertiary"
          cancelLabel={t('CANCEL')}
          confirmLabel={t('DONE')}
          confirmDisabled={checkErrors || isSubmitting}
          onCancel={handleClose}
          onConfirm={handleExecute}
        />
      </Modal>
    </>
  )
}

type HandleRemove = MouseEventHandler<HTMLButtonElement>
type DisabledMeetingScheduleForm = {
  date: boolean
  startTime: boolean
  endTime: boolean
  description: boolean
}

type MeetingScheduleFormProps = {
  trigger: UseFormTrigger<FormValue>
  control: Control<FormValue>
  disabled?: DisabledMeetingScheduleForm
  meetingScheduleNames: {
    [key in keyof FormValue['meetingSchedule'][number]]: Extract<
      FieldPath<FormValue>,
      `${string}.${key}`
    >
  }
  showRemoveButton?: boolean
  handleRemove?: HandleRemove
  getValues: UseFormGetValues<FormValue>
  setValue: UseFormSetValue<FormValue>
  scheduleError?: NonNullable<FieldErrors<FormValue>['meetingSchedule']>[number]
}

const DATE_BOUNDS = { startDate: dayjs().toDate(), endDate: dayjs().add(1, 'year').toDate() }

type MeetingScheduleFieldError = NonNullable<FieldErrors<FormValue>['meetingSchedule']>[number]

const createMeetingScheduleErrorMessage = (
  meetingScheduleError: MeetingScheduleFieldError,
): Array<string> => {
  const errorMessages: Array<string> = []

  // 開催日付のバリデーションエラー
  if (meetingScheduleError.date?.message) {
    errorMessages.push(meetingScheduleError.date.message)
  }

  // 開始時刻のバリデーションエラー
  if (meetingScheduleError.startTime?.message) {
    errorMessages.push(meetingScheduleError.startTime.message)
  }

  // 終了時刻のバリデーションエラー
  if (meetingScheduleError.endTime?.message) {
    // 開始時刻も終了時刻も両方とも「開始時刻と終了時刻が逆転しているバリデーションエラー」ではない場合は、
    // 同じエラー文の表示を回避できるため終了時刻のエラーメッセージを返り値に挿入する
    if (
      meetingScheduleError.startTime?.type !== TIME_CONSISTENCY_ERROR_NAME ||
      meetingScheduleError.endTime?.type !== TIME_CONSISTENCY_ERROR_NAME
    ) {
      errorMessages.push(meetingScheduleError.endTime.message)
    }
  }

  return errorMessages
}

export const MeetingScheduleForm: React.FC<MeetingScheduleFormProps> = ({
  trigger,
  control,
  meetingScheduleNames,
  disabled,
  showRemoveButton = true,
  handleRemove,
  getValues,
  setValue,
  scheduleError,
}) => {
  const { t } = useTranslation()
  const editorId = useMemo<string>(() => uuid(), [])

  const scheduleErrorMessages = scheduleError
    ? createMeetingScheduleErrorMessage(scheduleError)
    : []

  return (
    <>
      {/** 「日程を削除」ボタン */}
      {showRemoveButton && (
        <>
          {/** Gridを使っているのでラベルのカラムに空要素を入れる */}
          <div />
          <TextButton
            icon="deleteOutline"
            iconSize={16}
            color="text-bk-30"
            padding="0"
            onClick={handleRemove}
            css={{ marginLeft: 'auto', height: '22px' }}
          >
            {t('DELETION_X', { x: t('SCHEDULE') })}
          </TextButton>
        </>
      )}
      {/* 『開催日時』ラベル */}
      <Box direction="row" css={{ marginBottom: '16px' }}>
        <StyledText css={{ fontSize: '12px' }} weight="bold">
          {t('OPEN_DATE')}
        </StyledText>
        <StyledText color="resily-orange-100" css={{ fontSize: '12px' }} weight="bold">
          *
        </StyledText>
      </Box>
      {/* 『開催日時』フォーム */}
      <div css={{ marginBottom: '16px' }}>
        <Box direction="row" gap="40px">
          <Controller
            name={meetingScheduleNames.date}
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <OneOnOneModalDateParts
                id="1on1-date"
                name={name}
                value={value.toDateString()}
                disabled={disabled?.date}
                bounds={DATE_BOUNDS}
                hasError={
                  !!scheduleError?.date || scheduleError?.endTime?.type === TIME_PAST_ERROR_NAME
                }
                onChange={(date: Date) => {
                  trigger(['meetingSchedule', 'currentMeeting'])
                  onChange(date)
                }}
              />
            )}
          />
          <div css={{ display: 'flex' }}>
            {/* スタート時間 */}
            <Controller
              name={meetingScheduleNames.startTime}
              control={control}
              render={({ field: { name, value, onChange } }) => (
                <OneOnOneModalTimeParts
                  name={name}
                  value={value}
                  isDisabled={disabled?.startTime}
                  hasError={
                    !!scheduleError?.startTime ||
                    scheduleError?.date?.type === TIME_DUPLICATE_ERROR_NAME
                  }
                  onChange={(startTime) => {
                    const date = formatDate(getValues(meetingScheduleNames.date))
                    const comparisonStartTime = dayjs(`${date} ${startTime}`)
                    const comparisonEndTime = dayjs(
                      `${date} ${getValues(meetingScheduleNames.endTime)}`,
                    )
                    const diff = dayjs(`${date} ${getValues(meetingScheduleNames.startTime)}`).diff(
                      comparisonEndTime,
                    )
                    if (!isCorrectDate(comparisonStartTime, comparisonEndTime)) {
                      const endTime = comparisonStartTime.add(Math.abs(diff)).format('H:mm') // OneOnOneModalTimeParts.tsxの時間フォーマットに合わせている。Hourは0埋めしない。
                      setValue(meetingScheduleNames.endTime, endTime)
                    }
                    trigger(['meetingSchedule', 'currentMeeting'])
                    onChange(startTime)
                  }}
                />
              )}
            />
            <StyledText css={{ fontSize: '14px', margin: 'auto 12px' }} weight="normal">
              <Icon type="minus" color={color('text-bk-100')} width={8} height={20} />
            </StyledText>
            {/* エンド時間 */}
            <Controller
              name={meetingScheduleNames.endTime}
              control={control}
              render={({ field: { name, value, onChange } }) => (
                <OneOnOneModalTimeParts
                  name={name}
                  value={value}
                  isDisabled={disabled?.endTime}
                  hasError={
                    !!scheduleError?.endTime ||
                    scheduleError?.date?.type === TIME_DUPLICATE_ERROR_NAME
                  }
                  onChange={(endTime) => {
                    const date = formatDate(getValues(meetingScheduleNames.date))
                    const comparisonStartTime = dayjs(
                      `${date} ${getValues(meetingScheduleNames.startTime)}`,
                    )
                    const comparisonEndTime = dayjs(`${date} ${endTime}`)
                    const diff = dayjs(`${date} ${getValues(meetingScheduleNames.endTime)}`).diff(
                      comparisonStartTime,
                    )
                    if (!isCorrectDate(comparisonStartTime, comparisonEndTime)) {
                      const startTime = comparisonEndTime.subtract(Math.abs(diff)).format('H:mm') // OneOnOneModalTimeParts.tsxの時間フォーマットに合わせている。Hourは0埋めしない。
                      setValue(meetingScheduleNames.startTime, startTime)
                    }
                    trigger(['meetingSchedule', 'currentMeeting'])
                    onChange(endTime)
                  }}
                />
              )}
            />
          </div>
        </Box>
        {scheduleErrorMessages.map((message) => (
          <ErrorMessage>{message}</ErrorMessage>
        ))}
      </div>

      {/* 『場所・URL』ラベル */}
      <Box css={{ marginBottom: '24px' }}>
        <StyledText css={{ fontSize: '12px' }} weight="bold">
          {t('LOCATION_AND_URL')}
        </StyledText>
      </Box>
      {/* 『場所・URL』フォーム */}
      <Box css={{ marginBottom: '24px' }}>
        <Controller
          name={meetingScheduleNames.description}
          control={control}
          render={({ field: { onChange, name, value } }) => (
            <RichTextEditor
              id={editorId}
              initialValueJSON={
                disabled?.description && !value.plainText ? t('NO_DESCRIPTION') : value.treeJson
              }
              css={{
                maxHeight: '128px',
                overflowY: 'scroll',
                ...(disabled?.description
                  ? {
                      backgroundColor: color('white-100'),
                      border: `1px solid ${color('border-bk-20')}`,
                      borderRadius: '4px',
                      opacity: '0.3',
                    }
                  : undefined),
                'p[data-slate-node="element"]': {
                  padding: '0',
                },
                'div[data-slate-editor="true"]': {
                  padding: '9px 8px !important',
                },
              }}
              editorProps={{
                placeholder: disabled?.description
                  ? t('NO_DESCRIPTION')
                  : t('LOCATION_AND_URL_PLACEHOLDER'),
                name,
                readOnly: disabled?.description,
              }}
              onChange={(treeJson, plainText) => {
                onChange({ treeJson, plainText })
              }}
              autoFocus={false}
            />
          )}
        />
      </Box>
      {/** ボーダー */}
      <div
        css={{ height: '1px', width: 'calc(100% + 32px)', backgroundColor: color('border-bk-10') }}
      />
      <div css={{ height: '1px', backgroundColor: color('border-bk-10') }} />
    </>
  )
}

/* eslint-disable */
import { gql } from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
gql.disableFragmentWarnings()

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * 日時データを格納するスカラー型
   * フォーマットはRFC3339形式でUTC時間が格納されます
   * e.g.)
   * 2000-10-10T23:00:00Z -> UTC+0
   * 2000-10-10T23:00:00+09:00 -> UTC+9
   */
  DateTime: Date;
};

/** アクションプランを示すオブジェクト */
export type ActionPlan = {
  readonly __typename?: 'ActionPlan';
  /** 実績値 */
  readonly actualValue?: Maybe<Scalars['Float']>;
  /** 本文 */
  readonly body?: Maybe<Document>;
  /** 作成日時 */
  readonly createdAt: Scalars['DateTime'];
  /** 表示順 */
  readonly displayOrder: Scalars['Int'];
  /** 期日 */
  readonly dueDate?: Maybe<Scalars['DateTime']>;
  /** ID */
  readonly id: Scalars['ID'];
  /** KeyResult */
  readonly keyResult: KeyResult;
  /** 関係者 */
  readonly members: ReadonlyArray<User>;
  /** 作成者 */
  readonly owner: User;
  /** 進捗率 */
  readonly progressRate: Scalars['Int'];
  /** ステータス */
  readonly status: ActionPlanStatus;
  /** 目標値 */
  readonly targetValue?: Maybe<Scalars['Float']>;
  /** タイトル */
  readonly title: Scalars['String'];
  /** 目標値単位 */
  readonly unit?: Maybe<Scalars['String']>;
  /** 進捗率の更新方法 */
  readonly updateType: ActionPlanUpdateType;
  /** 更新日時 */
  readonly updatedAt: Scalars['DateTime'];
};

/** ActionPlanStatus アクションプランのステータス */
export const ActionPlanStatus = {
  /** 完了 */
  Completed: 'COMPLETED',
  /** 破棄 */
  Discarded: 'DISCARDED',
  /** 保留 */
  Held: 'HELD',
  /** 未着手 */
  NotStarted: 'NOT_STARTED',
  /** 着手 */
  Started: 'STARTED'
} as const;

export type ActionPlanStatus = typeof ActionPlanStatus[keyof typeof ActionPlanStatus];
/** ActionPlanUpdateType の進捗率の更新タイプ */
export const ActionPlanUpdateType = {
  /** 自動 */
  Automate: 'AUTOMATE',
  /** 手動 */
  Manual: 'MANUAL'
} as const;

export type ActionPlanUpdateType = typeof ActionPlanUpdateType[keyof typeof ActionPlanUpdateType];
/**
 * アクティビティを示すオブジェクト
 * MEMO: 今後、ノートやアクションプランなどチェックイン以外のアクティビティも追加する予定のです
 */
export type Activity = Node & {
  readonly __typename?: 'Activity';
  /** アクティビティ種別 */
  readonly activityType: ActivityType;
  /** 作成日時 */
  readonly createdAt: Scalars['DateTime'];
  /** ID */
  readonly id: Scalars['ID'];
  /** Key Result */
  readonly keyResult: KeyResult;
  /** KeyResultに対するコメント */
  readonly keyResultComment?: Maybe<ActivityKeyResultComment>;
  /** KeyResultに対する進捗・実績更新イベント情報 */
  readonly keyResultEvent?: Maybe<ActivityKeyResultEvent>;
  /** 更新日時 */
  readonly updatedAt: Scalars['DateTime'];
  /** ユーザ */
  readonly user: User;
};

/** アクティビティのコネクション */
export type ActivityConnection = {
  readonly __typename?: 'ActivityConnection';
  /** edges */
  readonly edges: ReadonlyArray<ActivityEdge>;
  /** ページング情報 */
  readonly pageInfo: PageInfo;
  /** トータル件数 */
  readonly totalCount: Scalars['Int'];
};

/** アクティビティのEdgePath */
export type ActivityEdge = {
  readonly __typename?: 'ActivityEdge';
  /** カーソル */
  readonly cursor: Scalars['ID'];
  /** ノード */
  readonly node: Activity;
};

/** アクティビティのKeyResultCommentを示すオブジェクト */
export type ActivityKeyResultComment = {
  readonly __typename?: 'ActivityKeyResultComment';
  /** その他 */
  readonly other?: Maybe<KeyResultOther>;
  /** 優先事項 */
  readonly priority?: Maybe<KeyResultPriority>;
  /** 困りごと */
  readonly problem?: Maybe<KeyResultProblem>;
  /** ウィン */
  readonly winSession?: Maybe<KeyResultWinSession>;
};

/** アクティビティのKeyResultEventを示すオブジェクト(進捗・自信度などの更新) */
export type ActivityKeyResultEvent = {
  readonly __typename?: 'ActivityKeyResultEvent';
  /** 実績値(更新後) */
  readonly afterActualValue?: Maybe<Scalars['Float']>;
  /** 自信度(更新後) */
  readonly afterConfidenceLevel?: Maybe<Scalars['Int']>;
  /** 初期値(更新後) */
  readonly afterInitialValue?: Maybe<Scalars['Float']>;
  /** 進捗率(更新後) */
  readonly afterProgressRate?: Maybe<Scalars['Int']>;
  /** 目標値(更新後) */
  readonly afterTargetValue?: Maybe<Scalars['Float']>;
  /** 単位(更新後) */
  readonly afterUnit?: Maybe<Scalars['String']>;
  /** 実績値(更新前) */
  readonly beforeActualValue?: Maybe<Scalars['Float']>;
  readonly beforeConfidenceLevel?: Maybe<Scalars['Int']>;
  /** 初期値(更新前) */
  readonly beforeInitialValue?: Maybe<Scalars['Float']>;
  /** 進捗率(更新前) */
  readonly beforeProgressRate?: Maybe<Scalars['Int']>;
  /** 目標値(更新前) */
  readonly beforeTargetValue?: Maybe<Scalars['Float']>;
  /** 単位(更新前) */
  readonly beforeUnit?: Maybe<Scalars['String']>;
  /** 更新理由 */
  readonly updateReason?: Maybe<Scalars['String']>;
  /** 更新時点の単位 */
  readonly updatedUnit?: Maybe<Scalars['String']>;
};

/** 取得するアクティビティの種別 */
export const ActivityResourceType = {
  /** 自分のOKR */
  MyOkr: 'MY_OKR',
  /** 関連するOKR */
  RelatedOkr: 'RELATED_OKR'
} as const;

export type ActivityResourceType = typeof ActivityResourceType[keyof typeof ActivityResourceType];
/** アクティビティの種別 */
export const ActivityType = {
  /** KeyResult 自動更新 */
  KrAutoAggregation: 'KR_AUTO_AGGREGATION',
  /** KeyResult 一括更新/チェックイン */
  KrBulkUpdate: 'KR_BULK_UPDATE',
  /** KeyResult ドロワーからの更新 */
  KrDrawerUpdate: 'KR_DRAWER_UPDATE',
  /** KeyResult モーダルからの更新 */
  KrModalUpdate: 'KR_MODAL_UPDATE',
  /** KeyResult クイック更新 */
  KrQuickUpdate: 'KR_QUICK_UPDATE'
} as const;

export type ActivityType = typeof ActivityType[keyof typeof ActivityType];
/** OKR Nodeを表すオブジェクト */
export type AddOkrNodeInput = {
  /** 添付ファイル */
  readonly attachments: ReadonlyArray<FileInput>;
  /** nodeと連携させるChatworkのapiToken */
  readonly chatworkApiToken?: InputMaybe<Scalars['String']>;
  /** nodeと連携させるChatworkのroomId */
  readonly chatworkRoomId?: InputMaybe<Scalars['String']>;
  readonly description?: InputMaybe<DocumentInput>;
  readonly groupIds: ReadonlyArray<Scalars['ID']>;
  readonly isDescriptionChanged?: InputMaybe<Scalars['Boolean']>;
  readonly name: Scalars['String'];
  readonly okrTermId: Scalars['ID'];
  readonly ownerId: Scalars['ID'];
  readonly parentKeyResultIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly parentOkrNodeId?: InputMaybe<Scalars['ID']>;
  /** nodeと連携させるSlackチャンネルリスト */
  readonly slackChannelIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** nodeと連携させるTeamsのWebhoolUrl */
  readonly teamsWebhookUrl?: InputMaybe<Scalars['String']>;
};

/** Agendaを表すInterface */
export type Agenda = {
  /** 作成日 */
  readonly createdAt: Scalars['DateTime'];
  /** ID */
  readonly id: Scalars['ID'];
  /** 表示順 */
  readonly index: Scalars['Int'];
  /** タイトル */
  readonly title: Scalars['String'];
  /** 更新日 */
  readonly updatedAt: Scalars['DateTime'];
};

export type BasicRecommendAgenda = RecommendAgenda & {
  readonly __typename?: 'BasicRecommendAgenda';
  /** id */
  readonly id: Scalars['ID'];
  /** おすすめアジェンダの種別 */
  readonly type: RecommendAgendaType;
};

/** 更新前のOKR Nodeに関する情報のオブジェクト */
export type BeforeUpdateOkrNodeInput = {
  /** 更新前のOKR背景 */
  readonly description?: InputMaybe<DocumentInput>;
  /** 更新前のObjectiveタイトル */
  readonly name: Scalars['String'];
  /** 更新前のOwnerID */
  readonly ownerId: Scalars['ID'];
  /** 更新前の親KeyResultIDs */
  readonly parentKeyResultIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  /** 更新前の親OkrNodeID */
  readonly parentOkrNodeId?: InputMaybe<Scalars['ID']>;
};

/** KRチェックイン */
export type CheckinKeyResult = {
  readonly __typename?: 'CheckinKeyResult';
  /** 更新後のKRの進捗率 */
  readonly actualValue?: Maybe<Scalars['Int']>;
  /** 前KRの実績値 */
  readonly beforeActualValue?: Maybe<Scalars['Int']>;
  /** 前KRの進捗率 */
  readonly beforeProgressRate?: Maybe<Scalars['Int']>;
  /** KRチェックインサマリ */
  readonly checkinSummaryKeyResult?: Maybe<CheckinSummaryKeyResult>;
  /** 最新のコンフィデンス */
  readonly confidence?: Maybe<Scalars['Int']>;
  /** 作成日時 */
  readonly createdAt: Scalars['DateTime'];
  /** ID */
  readonly id: Scalars['ID'];
  /** 最新のKey Result */
  readonly keyResult: KeyResult;
  /** そのほか */
  readonly other?: Maybe<KeyResultOther>;
  /** 優先事項 */
  readonly priority?: Maybe<KeyResultPriority>;
  /** 課題・障害 */
  readonly problem?: Maybe<KeyResultProblem>;
  /** 更新後のKRの進捗率 */
  readonly progressRate?: Maybe<Scalars['Int']>;
  /** 更新日時 */
  readonly updatedAt: Scalars['DateTime'];
  /** チェックイン時のユーザー情報 */
  readonly user: CheckinUser;
  /** ウィンセッション */
  readonly winSession?: Maybe<KeyResultWinSession>;
};

/** チェックインKRのイベントを表すオブジェクト */
export type CheckinKeyResultEventHistory = Node & {
  readonly __typename?: 'CheckinKeyResultEventHistory';
  /** 変更前進捗率の値 */
  readonly beforeProgressRate?: Maybe<Scalars['Int']>;
  /** 作成日時 */
  readonly createdAt: Scalars['DateTime'];
  /** ID */
  readonly id: Scalars['ID'];
  /** KR */
  readonly keyResult: KeyResult;
  /** コンフィデンス値 */
  readonly level?: Maybe<Scalars['Int']>;
  /** 変更理由 */
  readonly message?: Maybe<Scalars['String']>;
  /** 変更した人 */
  readonly operator: User;
  /** そのほか */
  readonly other?: Maybe<KeyResultOther>;
  /** 優先事項 */
  readonly priority?: Maybe<KeyResultPriority>;
  /** 課題・障害 */
  readonly problem?: Maybe<KeyResultProblem>;
  /** 変更後進捗率の値 */
  readonly progressRate?: Maybe<Scalars['Int']>;
  /** イベントの種別 */
  readonly type: CheckinKeyResultEventHistoryType;
  /** ウィンセッション */
  readonly winSession?: Maybe<KeyResultWinSession>;
};

/** チェクインKRイベントのオブジェクトのエッジのPath */
export type CheckinKeyResultEventHistoryConnection = {
  readonly __typename?: 'CheckinKeyResultEventHistoryConnection';
  /** edges */
  readonly edges: ReadonlyArray<CheckinKeyResultEventHistoryEdge>;
  /** pageInfo */
  readonly pageInfo: PageInfo;
};

/** チェクインKRイベントのエッジオブジェクト */
export type CheckinKeyResultEventHistoryEdge = {
  readonly __typename?: 'CheckinKeyResultEventHistoryEdge';
  /** カーソル */
  readonly cursor: Scalars['ID'];
  /** ノード */
  readonly node?: Maybe<CheckinKeyResultEventHistory>;
};

/** CheckinKeyResultEventHistoryの種別 */
export const CheckinKeyResultEventHistoryType = {
  /** チェックインKR */
  CheckinKeyResult: 'CHECKIN_KEY_RESULT',
  /** Confidence */
  Confidence: 'CONFIDENCE',
  /** 進捗率 */
  ProgressRate: 'PROGRESS_RATE',
  /** 自動吸い上げ */
  ProgressRateAggregation: 'PROGRESS_RATE_AGGREGATION'
} as const;

export type CheckinKeyResultEventHistoryType = typeof CheckinKeyResultEventHistoryType[keyof typeof CheckinKeyResultEventHistoryType];
/** KRチェックイン作成時の引数 */
export type CheckinKeyResultInput = {
  /** 実績値 */
  readonly actualValue?: InputMaybe<Scalars['Float']>;
  /** 実績値 */
  readonly initialValue?: InputMaybe<Scalars['Float']>;
  /** Key ResultのID */
  readonly keyResultId: Scalars['ID'];
  /** コンフィデンス */
  readonly level?: InputMaybe<Scalars['Int']>;
  /** そのほか */
  readonly other?: InputMaybe<DocumentInput>;
  /** 優先事項 */
  readonly priority?: InputMaybe<DocumentInput>;
  /** 課題・障害 */
  readonly problem?: InputMaybe<DocumentInput>;
  /** 目標値 */
  readonly targetValue?: InputMaybe<Scalars['Float']>;
  /** 目標値単位 */
  readonly unit?: InputMaybe<Scalars['String']>;
  /** 更新理由 */
  readonly updateReason?: InputMaybe<Scalars['String']>;
  /** ウィン */
  readonly winSession?: InputMaybe<DocumentInput>;
};

/** チェックインの状態 */
export type CheckinKeyResultsState = {
  readonly __typename?: 'CheckinKeyResultsState';
  /** チェックインの開始日 */
  readonly checkinStartDate?: Maybe<Scalars['DateTime']>;
  /**
   * ID
   * 実態としてはこのリソースにIDはないためユーザーIDで代替しています
   */
  readonly id: Scalars['ID'];
  /** 一括更新したか否か */
  readonly isBulkUpdated: Scalars['Boolean'];
};

/** OKRのチェックインサマリー */
export type CheckinSummary = {
  readonly __typename?: 'CheckinSummary';
  /** 前回のチェックインサマリー */
  readonly beforeCheckinSummary?: Maybe<CheckinSummary>;
  /** 変更者 */
  readonly changer?: Maybe<User>;
  /** チェックインKR */
  readonly checkinSummaryKeyResults: ReadonlyArray<CheckinSummaryKeyResult>;
  /** 作成日 */
  readonly createdAt: Scalars['DateTime'];
  /** 議事メモ */
  readonly description?: Maybe<Document>;
  /** チェックイン終了日 */
  readonly endDate: Scalars['DateTime'];
  /** ID */
  readonly id: Scalars['ID'];
  /** 親のノード */
  readonly node: OkrNode;
  /** サマリーに対するリアクション */
  readonly reactions: ReadonlyArray<CheckinSummaryReaction>;
  /** チェックイン開始日 */
  readonly startDate: Scalars['DateTime'];
  /** チェックインステータス */
  readonly status: CheckinSummaryStatus;
  /** 更新日 */
  readonly updatedAt: Scalars['DateTime'];
};

/** チェクインサマリーのオブジェクトのエッジのPath */
export type CheckinSummaryConnection = {
  readonly __typename?: 'CheckinSummaryConnection';
  /** edges */
  readonly edges: ReadonlyArray<CheckinSummaryEdge>;
  /** pageInfo */
  readonly pageInfo: PageInfo;
  /** totalCount */
  readonly totalCount: Scalars['Int'];
};

/** チェクインサマリーのエッジオブジェクト */
export type CheckinSummaryEdge = {
  readonly __typename?: 'CheckinSummaryEdge';
  /** カーソル */
  readonly cursor: Scalars['ID'];
  /** ノード */
  readonly node: CheckinSummary;
};

/** チェックインサマリーのKR */
export type CheckinSummaryKeyResult = {
  readonly __typename?: 'CheckinSummaryKeyResult';
  /** 前回のチェックインサマリーKR */
  readonly beforeCheckinSummaryKeyResult?: Maybe<CheckinSummaryKeyResult>;
  /** 各ユーザーのチェックイン */
  readonly checkinKeyResults: ReadonlyArray<CheckinKeyResult>;
  /**
   * チェックイン前、チェックイン中はKRコンフィデンス
   * チェックイン後、未チェックインはスナップショット作成時のKRコンフィデンス
   */
  readonly confidence: Scalars['Int'];
  /** 作成日 */
  readonly createdAt: Scalars['DateTime'];
  /**
   * チェックイン前、チェックイン中は最新のKRの表示順
   * チェックイン後、未チェックインはスナップショット作成時のKRの表示順
   */
  readonly displayOrder: Scalars['Int'];
  /** ID */
  readonly id: Scalars['ID'];
  /** 最新のKey Result */
  readonly keyResult: KeyResult;
  /**
   * チェックイン前、チェックイン中はKR名
   * チェックイン後、未チェックインはスナップショット作成時のKR名
   */
  readonly name: Scalars['String'];
  /**
   * チェックイン前、チェックイン中はKR進捗率
   * チェックイン後、未チェックインはスナップショット作成時のKR進捗率
   */
  readonly progressRate: Scalars['Int'];
  /**
   * チェックイン前、チェックイン中はKRのtarget_setting
   * チェックイン後、未チェックインはスナップショット作成時のtarget_setting
   */
  readonly targetSetting: TargetSettingType;
  /** 更新日 */
  readonly updatedAt: Scalars['DateTime'];
};

/** チェックインサマリーのリアクション */
export type CheckinSummaryReaction = {
  readonly __typename?: 'CheckinSummaryReaction';
  /** 作成日 */
  readonly createdAt: Scalars['DateTime'];
  /** 絵文字 */
  readonly emoji: Scalars['String'];
  /** ID */
  readonly id: Scalars['ID'];
  /** 更新日 */
  readonly updatedAt: Scalars['DateTime'];
  /** ユーザー */
  readonly user: User;
};

/** チェックインサマリーのステータス */
export const CheckinSummaryStatus = {
  /** チェックイン後 */
  Done: 'DONE',
  /** チェックイン中 */
  Now: 'NOW',
  /** チェックイン前 */
  Previous: 'PREVIOUS',
  /** チェックインせずに期間終了 */
  Undone: 'UNDONE'
} as const;

export type CheckinSummaryStatus = typeof CheckinSummaryStatus[keyof typeof CheckinSummaryStatus];
/** チェックイン時のユーザー情報 */
export type CheckinUser = {
  readonly __typename?: 'CheckinUser';
  /** アバター */
  readonly avatar?: Maybe<File>;
  /** 作成日 */
  readonly createdAt: Scalars['DateTime'];
  /** 名 */
  readonly firstName: Scalars['String'];
  /** チェックインユーザーID */
  readonly id: Scalars['ID'];
  /** 姓 */
  readonly lastName: Scalars['String'];
  /** 更新日 */
  readonly updatedAt: Scalars['DateTime'];
  /** 元のユーザー情報（削除時はnull） */
  readonly user?: Maybe<User>;
  /** ユーザーID */
  readonly userId?: Maybe<Scalars['String']>;
};

/** 現在日のチェックイン可能なKeyResult */
export type CheckinableKeyResult = {
  readonly __typename?: 'CheckinableKeyResult';
  /** チェックインサマリーのKR */
  readonly checkinSummaryKeyResult: CheckinSummaryKeyResult;
  /**
   * ID
   * 実態としてはこのリソースにIDはないためCheckinSummaryKeyResultIDで代替しています
   */
  readonly id: Scalars['ID'];
  /** そのユーザーが最後に投稿したコメント */
  readonly lastComments?: Maybe<LastComments>;
};

/** コンフィデンスを表すためのinterface */
export type Confidence = {
  /** 作成日時 */
  readonly createdAt: Scalars['DateTime'];
  /** コンフィデンスID */
  readonly id: Scalars['ID'];
  /** レベル */
  readonly level: Scalars['Int'];
  /** 更新日時 */
  readonly updatedAt: Scalars['DateTime'];
  /** ユーザー */
  readonly user: User;
};

/** コンフィデンススコアを表すためのinterface */
export type ConfidenceScore = {
  /** 平均 */
  readonly average: Scalars['Float'];
  /** 作成日時 */
  readonly createdAt: Scalars['DateTime'];
  /** コンフィデンススコアID */
  readonly id: Scalars['ID'];
  /** 標準偏差 */
  readonly standardDeviation: Scalars['Float'];
};

export type Connection = {
  readonly after?: InputMaybe<Scalars['String']>;
  readonly before?: InputMaybe<Scalars['String']>;
  readonly first?: InputMaybe<Scalars['Int']>;
  readonly last?: InputMaybe<Scalars['Int']>;
};

/**
 * KRに紐づく下位Objective作成用オブジェクト
 * 入力引数用
 */
export type CreateChildObjective = {
  /** 説明 */
  readonly description?: InputMaybe<DocumentInput>;
  /** 説明文のトラッキングフラグ (デフォルトテキストから変更がある場合true) */
  readonly isDescriptionChanged?: InputMaybe<Scalars['Boolean']>;
  /** 名前 */
  readonly name: Scalars['String'];
  /** 責任者ID */
  readonly ownerId: Scalars['String'];
};

/** 固定アジェンダ作成用入力値 */
export type CreateFixedAgendaInput = {
  /** 本文 */
  readonly body: Scalars['String'];
  /** プレーンテキスト */
  readonly plainText: Scalars['String'];
  /** ステータス */
  readonly status: FixedAgendaStatus;
  /** タイトル */
  readonly title: Scalars['String'];
};

/**
 * KR作成用オブジェクト
 * 入力引数用
 */
export type CreateKeyResultInput = {
  /** 添付ファイル */
  readonly attachments: ReadonlyArray<FileInput>;
  /** 自信度 */
  readonly confidence?: InputMaybe<Scalars['Int']>;
  /** 説明 */
  readonly description?: InputMaybe<DocumentInput>;
  /** 初期値 */
  readonly initialValue?: InputMaybe<Scalars['Float']>;
  /** 説明文のトラッキングフラグ (デフォルトテキストから変更がある場合true) */
  readonly isDescriptionChanged?: InputMaybe<Scalars['Boolean']>;
  /** コントリビューターID */
  readonly memberIds: ReadonlyArray<Scalars['String']>;
  /** 名前 */
  readonly name: Scalars['String'];
  /** term id */
  readonly okrTermId: Scalars['ID'];
  /** 責任者ID */
  readonly ownerId: Scalars['String'];
  /** 親のobjectiveId */
  readonly parentObjectiveId: Scalars['String'];
  /** moon,roofの設定 */
  readonly targetSetting: TargetSettingType;
  /** 目標値 */
  readonly targetValue?: InputMaybe<Scalars['Float']>;
  /** 目標値の単位 */
  readonly unit?: InputMaybe<Scalars['String']>;
};

/**
 * KeyResultの共有事項の作成を表すオブジェクト
 * 入力引数用
 */
export type CreateKeyResultSharedItemInput = {
  /** 詳細 */
  readonly body: DocumentInput;
  /** 期限日 */
  readonly expiredDate?: InputMaybe<Scalars['DateTime']>;
  /** 共有事項の種別 */
  readonly itemType: KeyResultSharedItemType;
  /** KeyResultのID */
  readonly keyResultId: Scalars['String'];
  /** コントリビューターID */
  readonly memberIds: ReadonlyArray<Scalars['String']>;
  /** 責任者ID */
  readonly ownerId: Scalars['String'];
  /** ピン留め */
  readonly pinning: Scalars['Boolean'];
  /** ステータス */
  readonly status: KeyResultSharedItemStatus;
  /** タイトル */
  readonly title: Scalars['String'];
};

/**
 * Note作成用オブジェクト
 * 入力引数用
 */
export type CreateNoteInput = {
  readonly attachments: ReadonlyArray<FileInput>;
  readonly body?: InputMaybe<DocumentInput>;
  readonly keyResultId?: InputMaybe<Scalars['ID']>;
  readonly labelIds: ReadonlyArray<Scalars['ID']>;
  readonly objectiveId?: InputMaybe<Scalars['ID']>;
  readonly permission: NotePermission;
  readonly title: Scalars['String'];
  readonly userIds: ReadonlyArray<Scalars['ID']>;
};

/** ユーザーアジェンダ作成用入力値 */
export type CreateUserAgendaInput = {
  /** タイトル */
  readonly title: Scalars['String'];
};

/** 1on1新規作成時の開始時間、終了時間、場所・URL */
export type CreateUserOneOnOneMeetingInput = {
  readonly description: Description;
  readonly schedule: Schedule;
};

export type CreateUserOneOnOneMeetingsInput = {
  /** 1on1の予定 */
  readonly UserOneOnOneMeetingsInput: ReadonlyArray<CreateUserOneOnOneMeetingInput>;
  /** 1on1の予定をnotifierサービスに連携するための情報 */
  readonly notifierInput: NotifierOneOnOneInput;
  /** 1on1対象者 */
  readonly partnerID: Scalars['ID'];
};

/** OKR用語をカスタムできるオブジェクト */
export type CustomOkrGlossary = {
  readonly __typename?: 'CustomOkrGlossary';
  /** 作成日時 */
  readonly CreatedAt: Scalars['DateTime'];
  /** 更新日時 */
  readonly UpdatedAt: Scalars['DateTime'];
  /** 任意に設定した英語（未登録の場合はnull） */
  readonly customTextEn?: Maybe<Scalars['String']>;
  /** 任意に設定した日本語（未登録の場合はnull） */
  readonly customTextJa?: Maybe<Scalars['String']>;
  /** id */
  readonly id: Scalars['ID'];
  /** OKR用語 */
  readonly okrGlossary: Scalars['String'];
};

/**
 * OKR用語に任意の名前をつけるための更新用オブジェクト
 * 入力引数用
 */
export type CustomOkrGlossaryInput = {
  /** 任意に設定したい英語 */
  readonly customTextEn: Scalars['String'];
  /** 任意に設定したい日本語 */
  readonly customTextJa: Scalars['String'];
  /** 更新対象のOKR用語 */
  readonly okrGlossary: OkrGlossary;
};

/** editorに付随するドキュメントを表すオブジェクト */
export type Document = {
  readonly __typename?: 'Document';
  /** プレーンテキスト */
  readonly plainText?: Maybe<Scalars['String']>;
  /**
   * Draft.jsのオブジェクトモデル
   * 詳細はEditorState参照
   */
  readonly treeJson: Scalars['String'];
};

/**
 * editorに付随するドキュメントを表すオブジェクト
 * 入力引数用
 */
export type DocumentInput = {
  /** プレーンテキスト */
  readonly plainText: Scalars['String'];
  /**
   * Draft.jsのオブジェクトモデル
   * 詳細はEditorState参照
   */
  readonly treeJson: Scalars['String'];
};

export type Duration = {
  /** from */
  readonly from?: InputMaybe<Scalars['DateTime']>;
  /** to */
  readonly to?: InputMaybe<Scalars['DateTime']>;
};

/** 添付ファイルを表すオブジェクト */
export type File = {
  readonly __typename?: 'File';
  /**
   * オリジナルファイル名
   * urlだけだと署名付きURLでオリジナルのファイル名が分からなくなるため
   */
  readonly name: Scalars['String'];
  /** ファイルのPath */
  readonly path: Scalars['String'];
  /** ファイルのURL */
  readonly url: Scalars['String'];
};

/**
 * 添付ファイルを表すオブジェクト
 * 入力引数用
 */
export type FileInput = {
  /**
   * オリジナルファイル名
   * urlだけだと署名付きURLでオリジナルのファイル名が分からなくなるため
   */
  readonly name: Scalars['String'];
  /** ファイルのPath */
  readonly path: Scalars['String'];
};

/** 固定アジェンダ */
export type FixedAgenda = Agenda & Node & {
  readonly __typename?: 'FixedAgenda';
  /** 本文 */
  readonly body: Scalars['String'];
  /** 作成日 */
  readonly createdAt: Scalars['DateTime'];
  /** ID */
  readonly id: Scalars['ID'];
  /** 表示順 */
  readonly index: Scalars['Int'];
  /** 1on1MTGに紐づく固定アジェンダの議事録 */
  readonly meetingNote: Scalars['String'];
  /** プレーンテキスト */
  readonly plainText: Scalars['String'];
  /** ステータス */
  readonly status: FixedAgendaStatus;
  /** タイトル */
  readonly title: Scalars['String'];
  /** 更新日 */
  readonly updatedAt: Scalars['DateTime'];
};


/** 固定アジェンダ */
export type FixedAgendaMeetingNoteArgs = {
  meetingId: Scalars['ID'];
};

/** 固定アジェンダコネクション */
export type FixedAgendaConnection = {
  readonly __typename?: 'FixedAgendaConnection';
  /** エッジ */
  readonly edges: ReadonlyArray<FixedAgendaEdge>;
  /** ページング情報 */
  readonly pageInfo: PageInfo;
  /** トータル件数 */
  readonly totalCount: Scalars['Int'];
};

/** 固定アジェンダエッジ */
export type FixedAgendaEdge = {
  readonly __typename?: 'FixedAgendaEdge';
  /** カーソル */
  readonly cursor: Scalars['String'];
  /** ノード */
  readonly node: FixedAgenda;
};

/** 固定アジェンダ一覧を取得するソート順を指定するためのinput */
export type FixedAgendaOrder = {
  /** 順序 */
  readonly direction?: OrderDirection;
  /** ソート対象カラム */
  readonly field?: InputMaybe<FixedAgendaOrderField>;
};

/** 固定アジェンダ一覧を取得する際のソートフィールド */
export const FixedAgendaOrderField = {
  /** 作成日時 */
  CreatedAt: 'CREATED_AT',
  /** 表示順 */
  Index: 'INDEX',
  /** 更新日時 */
  UpdatedAt: 'UPDATED_AT'
} as const;

export type FixedAgendaOrderField = typeof FixedAgendaOrderField[keyof typeof FixedAgendaOrderField];
/** 1on1用固定アジェンダステータス */
export const FixedAgendaStatus = {
  /** 非公開 */
  Private: 'PRIVATE',
  /** 公開 */
  Public: 'PUBLIC'
} as const;

export type FixedAgendaStatus = typeof FixedAgendaStatus[keyof typeof FixedAgendaStatus];
/**
 * FixedAgendaUserOneOnOneMeetingWhereInput is used for filtering FixedAgendaUserOneOnOneMeeting objects.
 * Input was generated by ent.
 */
export type FixedAgendaUserOneOnOneMeetingWhereInput = {
  readonly and?: InputMaybe<ReadonlyArray<FixedAgendaUserOneOnOneMeetingWhereInput>>;
  /** created_at field predicates */
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly createdAtGT?: InputMaybe<Scalars['DateTime']>;
  readonly createdAtGTE?: InputMaybe<Scalars['DateTime']>;
  readonly createdAtIn?: InputMaybe<ReadonlyArray<Scalars['DateTime']>>;
  readonly createdAtLT?: InputMaybe<Scalars['DateTime']>;
  readonly createdAtLTE?: InputMaybe<Scalars['DateTime']>;
  readonly createdAtNEQ?: InputMaybe<Scalars['DateTime']>;
  readonly createdAtNotIn?: InputMaybe<ReadonlyArray<Scalars['DateTime']>>;
  /** fixed_agenda edge predicates */
  readonly hasFixedAgenda?: InputMaybe<Scalars['Boolean']>;
  readonly hasFixedAgendaWith?: InputMaybe<ReadonlyArray<FixedAgendaWhereInput>>;
  /** user_one_on_one_meeting edge predicates */
  readonly hasUserOneOnOneMeeting?: InputMaybe<Scalars['Boolean']>;
  readonly hasUserOneOnOneMeetingWith?: InputMaybe<ReadonlyArray<UserOneOnOneMeetingWhereInput>>;
  readonly not?: InputMaybe<FixedAgendaUserOneOnOneMeetingWhereInput>;
  readonly or?: InputMaybe<ReadonlyArray<FixedAgendaUserOneOnOneMeetingWhereInput>>;
  /** updated_at field predicates */
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAtGT?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAtGTE?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAtIn?: InputMaybe<ReadonlyArray<Scalars['DateTime']>>;
  readonly updatedAtLT?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAtLTE?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAtNEQ?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAtNotIn?: InputMaybe<ReadonlyArray<Scalars['DateTime']>>;
};

/**
 * FixedAgendaWhereInput is used for filtering FixedAgenda objects.
 * Input was generated by ent.
 */
export type FixedAgendaWhereInput = {
  readonly and?: InputMaybe<ReadonlyArray<FixedAgendaWhereInput>>;
  /** created_at field predicates */
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly createdAtGT?: InputMaybe<Scalars['DateTime']>;
  readonly createdAtGTE?: InputMaybe<Scalars['DateTime']>;
  readonly createdAtIn?: InputMaybe<ReadonlyArray<Scalars['DateTime']>>;
  readonly createdAtLT?: InputMaybe<Scalars['DateTime']>;
  readonly createdAtLTE?: InputMaybe<Scalars['DateTime']>;
  readonly createdAtNEQ?: InputMaybe<Scalars['DateTime']>;
  readonly createdAtNotIn?: InputMaybe<ReadonlyArray<Scalars['DateTime']>>;
  /** fixed_agenda_user_one_on_one_meeting edge predicates */
  readonly hasFixedAgendaUserOneOnOneMeeting?: InputMaybe<Scalars['Boolean']>;
  readonly hasFixedAgendaUserOneOnOneMeetingWith?: InputMaybe<ReadonlyArray<FixedAgendaUserOneOnOneMeetingWhereInput>>;
  readonly not?: InputMaybe<FixedAgendaWhereInput>;
  readonly or?: InputMaybe<ReadonlyArray<FixedAgendaWhereInput>>;
  /** updated_at field predicates */
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAtGT?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAtGTE?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAtIn?: InputMaybe<ReadonlyArray<Scalars['DateTime']>>;
  readonly updatedAtLT?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAtLTE?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAtNEQ?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAtNotIn?: InputMaybe<ReadonlyArray<Scalars['DateTime']>>;
};

/** KRの初期値・目標値・単位を設定するためのオブジェクト */
export type GoalSettingInput = {
  /** 初期値 */
  readonly initialValue?: InputMaybe<Scalars['Float']>;
  /** メッセージ */
  readonly message?: InputMaybe<Scalars['String']>;
  /** 更新箇所 */
  readonly screen: KeyResultEventScreen;
  /** 目標値 */
  readonly targetValue?: InputMaybe<Scalars['Float']>;
  /** 目標値の単位 */
  readonly unit?: InputMaybe<Scalars['String']>;
};

/** 企業内の部署などのグループを表すオブジェクト */
export type Group = {
  readonly __typename?: 'Group';
  /** グループの全管理者 */
  readonly admins: ReadonlyArray<User>;
  /** 子のグループID */
  readonly childGroupIds: ReadonlyArray<Scalars['ID']>;
  /** 作成日時 */
  readonly createdAt: Scalars['DateTime'];
  /** グループの深さ */
  readonly depth: Scalars['Int'];
  /** 表示順 */
  readonly displayOrder: Scalars['Int'];
  /** グループID */
  readonly id: Scalars['ID'];
  /** グループの全メンバー */
  readonly members: ReadonlyArray<User>;
  /** グループ名 */
  readonly name: Scalars['String'];
  /** 所属するOrganization */
  readonly organization: Organization;
  /** 更新日時 */
  readonly updatedAt: Scalars['DateTime'];
};

/** グループのレポート情報 */
export type GroupReport = {
  readonly __typename?: 'GroupReport';
  /** グループ名 */
  readonly name: Scalars['String'];
  /** 対象者人数 */
  readonly number: Scalars['Int'];
};

/** IdProvider情報 */
export type IdentityProvider = {
  readonly __typename?: 'IdentityProvider';
  /** IdPのissuer URL */
  readonly issuerUrl: Scalars['String'];
  /** 認証リクエスト時にリダイレクトするURL */
  readonly ssoRedirectUrl: Scalars['String'];
};

/**
 * SmartHR連携用オブジェクト
 * 入力引数用
 */
export type IntegateSmartHrInput = {
  /** SmartHRアクセストークン */
  readonly accessToken: Scalars['String'];
  /** SmartHRシークレットトークン */
  readonly secretToken: Scalars['String'];
  /** SmartHRテナントID */
  readonly tenantId: Scalars['String'];
};

/** OKRのKey Resultを表すオブジェクト */
export type KeyResult = Node & OkrNodeItem & {
  readonly __typename?: 'KeyResult';
  /** アクションプラン */
  readonly actionPlans: ReadonlyArray<ActionPlan>;
  /** 実績値 */
  readonly actualValue?: Maybe<Scalars['Float']>;
  /** 添付ファイル */
  readonly attachments: ReadonlyArray<File>;
  /** 下位のObjective */
  readonly childObjectives: ReadonlyArray<Objective>;
  /** 指定期間のConfidence Score */
  readonly confidenceScores: ReadonlyArray<KeyResultConfidenceScore>;
  /** Confidenceのトレンド */
  readonly confidenceTrend: Scalars['Int'];
  /** コンフィデンス */
  readonly confidences: ReadonlyArray<KeyResultConfidence>;
  /** 作成日時 */
  readonly createdAt: Scalars['DateTime'];
  /** 説明 */
  readonly description?: Maybe<Document>;
  /** 表示順序 */
  readonly displayOrder: Scalars['Int'];
  /** イベント履歴 */
  readonly events: ReadonlyArray<KeyResultEvent>;
  /** 期限 */
  readonly expiredAt?: Maybe<Scalars['DateTime']>;
  /** KeyResultID */
  readonly id: Scalars['ID'];
  /** 初期値 */
  readonly initialValue: Scalars['Float'];
  /** 自動集計か否か */
  readonly isAutoAggregate: Scalars['Boolean'];
  /** 無効化状態 */
  readonly isDisabled: Scalars['Boolean'];
  /** 最新のConfidence Score */
  readonly latestConfidenceScore?: Maybe<KeyResultConfidenceScore>;
  /** メンバー */
  readonly members: ReadonlyArray<User>;
  /** 名前 */
  readonly name: Scalars['String'];
  /** KeyResultが所属しているNode */
  readonly node: OkrNode;
  /** 投稿されたノート */
  readonly notes: ReadonlyArray<Note>;
  /** 所属するObjective */
  readonly objective: Objective;
  /** オーナー */
  readonly owner: User;
  /** 進捗率の着地予想 */
  readonly progressForecast: Scalars['Int'];
  /** 進捗率 */
  readonly progressRate?: Maybe<Scalars['Int']>;
  /** 進捗率の推移 */
  readonly progressRateHistory: KeyResultProgressRateHistoryConnection;
  /** 進捗率のトレンド */
  readonly progressRateTrend: Scalars['Int'];
  /** 進捗率の最終更新日時 */
  readonly progressRateUpdatedAt?: Maybe<Scalars['DateTime']>;
  /**
   * リソースアロケーション
   * @deprecated リソアロ廃止につき削除予定
   */
  readonly resourceAllocations: ReadonlyArray<KeyResultResourceAllocation>;
  /** 結果 */
  readonly result?: Maybe<Document>;
  /** moon, roof */
  readonly targetSetting: TargetSettingType;
  /** 目標値 */
  readonly targetValue?: Maybe<Scalars['Float']>;
  /** 目標値単位 */
  readonly unit?: Maybe<Scalars['String']>;
  /** 指定期間内の更新件数 */
  readonly updateCount: Scalars['Int'];
  /** 進捗率の更新方法 */
  readonly updateType: KeyResultUpdateType;
  /** 更新日時 */
  readonly updatedAt: Scalars['DateTime'];
  /** ウェイト */
  readonly weight?: Maybe<Scalars['Int']>;
};


/** OKRのKey Resultを表すオブジェクト */
export type KeyResultConfidenceScoresArgs = {
  from?: InputMaybe<Scalars['DateTime']>;
  to?: InputMaybe<Scalars['DateTime']>;
};


/** OKRのKey Resultを表すオブジェクト */
export type KeyResultConfidenceTrendArgs = {
  days: Scalars['Int'];
};


/** OKRのKey Resultを表すオブジェクト */
export type KeyResultProgressRateHistoryArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** OKRのKey Resultを表すオブジェクト */
export type KeyResultProgressRateTrendArgs = {
  days: Scalars['Int'];
};


/** OKRのKey Resultを表すオブジェクト */
export type KeyResultUpdateCountArgs = {
  duration?: InputMaybe<Duration>;
  itemTypes?: InputMaybe<ReadonlyArray<KeyResultEventItem>>;
};

/** Key Resultのコンフィデンスを表すオブジェクト */
export type KeyResultConfidence = Confidence & {
  readonly __typename?: 'KeyResultConfidence';
  /** 作成日時 */
  readonly createdAt: Scalars['DateTime'];
  /** KeyResultコンフィデンスID */
  readonly id: Scalars['ID'];
  /** KeyResult */
  readonly keyResult: KeyResult;
  /** レベル */
  readonly level: Scalars['Int'];
  /** メッセージ */
  readonly message?: Maybe<Scalars['String']>;
  /** 更新日時 */
  readonly updatedAt: Scalars['DateTime'];
  /** ユーザー */
  readonly user: User;
};

/** Key Resultのコンフィデンススコアを表すオブジェクト */
export type KeyResultConfidenceScore = ConfidenceScore & {
  readonly __typename?: 'KeyResultConfidenceScore';
  /** 平均 */
  readonly average: Scalars['Float'];
  /** 作成日時 */
  readonly createdAt: Scalars['DateTime'];
  /** コンフィデンススコアID */
  readonly id: Scalars['ID'];
  /** Key Result */
  readonly keyResult: KeyResult;
  /** 標準偏差 */
  readonly standardDeviation: Scalars['Float'];
};

/** KeyResultのエッジのPath */
export type KeyResultConnection = {
  readonly __typename?: 'KeyResultConnection';
  /** edges */
  readonly edges?: Maybe<ReadonlyArray<Maybe<KeyResultEdge>>>;
  /** pageInfo */
  readonly pageInfo: PageInfo;
};

/** KeyResultのエッジオブジェクト */
export type KeyResultEdge = {
  readonly __typename?: 'KeyResultEdge';
  /** カーソル */
  readonly cursor: Scalars['ID'];
  /** ノード */
  readonly node?: Maybe<KeyResult>;
};

/** Key Resultのイベント履歴を表すオブジェクト */
export type KeyResultEvent = Node & {
  readonly __typename?: 'KeyResultEvent';
  /** 変更後の値 */
  readonly after?: Maybe<Scalars['String']>;
  /** 変更前の値 */
  readonly before?: Maybe<Scalars['String']>;
  /** 作成日時 */
  readonly createdAt: Scalars['DateTime'];
  /** KeyResultイベント履歴ID */
  readonly id: Scalars['ID'];
  /** Item */
  readonly item: KeyResultEventItem;
  /** KeyResult */
  readonly keyResult?: Maybe<KeyResult>;
  /** linkedKeyResultEvents */
  readonly linkedKeyResultEvents?: Maybe<ReadonlyArray<Maybe<KeyResultEvent>>>;
  /** 変更理由 */
  readonly message?: Maybe<Scalars['String']>;
  /** 変更した人 */
  readonly operator: User;
  /** 更新日時 */
  readonly updatedAt: Scalars['DateTime'];
};

/** ページングオブジェクトのエッジのPath */
export type KeyResultEventConnection = {
  readonly __typename?: 'KeyResultEventConnection';
  /** edges */
  readonly edges?: Maybe<ReadonlyArray<Maybe<KeyResultEventEdge>>>;
  /** pageInfo */
  readonly pageInfo: PageInfo;
};

export type KeyResultEventConnections = {
  readonly __typename?: 'KeyResultEventConnections';
  readonly connection?: Maybe<KeyResultEventConnection>;
  readonly keyResultId: Scalars['ID'];
};

/** ページングオブジェクトのエッジオブジェクト */
export type KeyResultEventEdge = {
  readonly __typename?: 'KeyResultEventEdge';
  /** カーソル */
  readonly cursor: Scalars['ID'];
  /** ノード */
  readonly node?: Maybe<KeyResultEvent>;
};

/** Key Resultのイベント履歴カラム名 */
export const KeyResultEventItem = {
  /** 実績値 */
  ActualValue: 'ACTUAL_VALUE',
  /** 自信度 */
  Confidence: 'CONFIDENCE',
  /** コントリビューター一覧 */
  Contributors: 'CONTRIBUTORS',
  /** 初期値 */
  InitialValue: 'INITIAL_VALUE',
  /** 責任者 */
  Owner: 'OWNER',
  /** 進捗率 */
  ProgressRate: 'PROGRESS_RATE',
  /** 自動吸い上げ */
  ProgressRateAggregation: 'PROGRESS_RATE_AGGREGATION',
  /** moon,roof */
  TargetSetting: 'TARGET_SETTING',
  /** 目標値 */
  TargetValue: 'TARGET_VALUE',
  /** 目標値(単位) */
  TargetValueUnit: 'TARGET_VALUE_UNIT',
  /** タイトル */
  Title: 'TITLE'
} as const;

export type KeyResultEventItem = typeof KeyResultEventItem[keyof typeof KeyResultEventItem];
/** Key Resultのイベント更新箇所 */
export const KeyResultEventScreen = {
  /** ドロワー */
  Drawer: 'DRAWER',
  /** モーダル */
  Modal: 'MODAL',
  /** クイック更新 */
  Quick: 'QUICK'
} as const;

export type KeyResultEventScreen = typeof KeyResultEventScreen[keyof typeof KeyResultEventScreen];
/** そのほかを表すオブジェクト */
export type KeyResultOther = KeyResultSharedItem & {
  readonly __typename?: 'KeyResultOther';
  /** 共有事項 */
  readonly body?: Maybe<Document>;
  /** 作成日時 */
  readonly createdAt: Scalars['DateTime'];
  /** ID */
  readonly id: Scalars['ID'];
  /** 更新日時 */
  readonly updatedAt: Scalars['DateTime'];
};

/** 優先事項を表すオブジェクト */
export type KeyResultPriority = KeyResultSharedItem & {
  readonly __typename?: 'KeyResultPriority';
  /** 共有事項 */
  readonly body?: Maybe<Document>;
  /** 作成日時 */
  readonly createdAt: Scalars['DateTime'];
  /** ID */
  readonly id: Scalars['ID'];
  /** 更新日時 */
  readonly updatedAt: Scalars['DateTime'];
};

/** 課題・障害を表すオブジェクト */
export type KeyResultProblem = KeyResultSharedItem & {
  readonly __typename?: 'KeyResultProblem';
  /** 共有事項 */
  readonly body?: Maybe<Document>;
  /** 作成日時 */
  readonly createdAt: Scalars['DateTime'];
  /** ID */
  readonly id: Scalars['ID'];
  /** 更新日時 */
  readonly updatedAt: Scalars['DateTime'];
};

/** KeyResultの進捗率の推移を示すためのtype */
export type KeyResultProgressRateHistory = ProgressRateHistory & {
  readonly __typename?: 'KeyResultProgressRateHistory';
  /** 進捗率(変更前) */
  readonly beforeProgressRate: Scalars['Int'];
  /** 作成日時 */
  readonly createdAt: Scalars['DateTime'];
  /** ID */
  readonly id: Scalars['ID'];
  /** 種別 */
  readonly item: KeyResultProgressRateItem;
  /** KeyResult */
  readonly keyResult: KeyResult;
  /** メッセージ */
  readonly message?: Maybe<Scalars['String']>;
  /** 進捗率 */
  readonly progressRate: Scalars['Int'];
  /** 変更者 */
  readonly user: User;
};

/** ページングオブジェクトのエッジのPath */
export type KeyResultProgressRateHistoryConnection = {
  readonly __typename?: 'KeyResultProgressRateHistoryConnection';
  /** edges */
  readonly edges: ReadonlyArray<KeyResultProgressRateHistoryEdge>;
  /** pageInfo */
  readonly pageInfo: PageInfo;
};

/** ページングオブジェクトのエッジオブジェクト */
export type KeyResultProgressRateHistoryEdge = {
  readonly __typename?: 'KeyResultProgressRateHistoryEdge';
  /** カーソル */
  readonly cursor: Scalars['ID'];
  /** ノード */
  readonly node: KeyResultProgressRateHistory;
};

/** 進捗率の推移の種別 */
export const KeyResultProgressRateItem = {
  /** 進捗率 */
  ProgressRate: 'PROGRESS_RATE',
  /** 自動吸い上げ */
  ProgressRateAggregation: 'PROGRESS_RATE_AGGREGATION'
} as const;

export type KeyResultProgressRateItem = typeof KeyResultProgressRateItem[keyof typeof KeyResultProgressRateItem];
/**
 * リソースアロケーション(実態はKeyResultMember)
 * @deprecated リソアロ廃止につき削除予定
 */
export type KeyResultResourceAllocation = {
  readonly __typename?: 'KeyResultResourceAllocation';
  /** リソースアロケーション */
  readonly allocation: Scalars['Int'];
  /** KeyResultMemberのID */
  readonly id: Scalars['ID'];
  /** KeyResult */
  readonly keyResult: KeyResult;
  /** User */
  readonly user: User;
};

/**
 * KeyResultのリソースアロケーションを扱うためのinput型
 * @deprecated リソアロ廃止につき削除予定
 */
export type KeyResultResourceAllocationInput = {
  /** リソースアロケーション */
  readonly allocation: Scalars['Int'];
  /** Key ResultのID */
  readonly keyResultId: Scalars['ID'];
};

/** KeyResultの共有事項を表すためのinterface */
export type KeyResultSharedItem = {
  /** 共有事項 */
  readonly body?: Maybe<Document>;
  /** 作成日時 */
  readonly createdAt: Scalars['DateTime'];
  /** ID */
  readonly id: Scalars['ID'];
  /** 更新日時 */
  readonly updatedAt: Scalars['DateTime'];
};

/** 共有事項のステータス */
export const KeyResultSharedItemStatus = {
  /** 完了 */
  Completed: 'COMPLETED',
  /** 未着手 */
  NotStarted: 'NOT_STARTED'
} as const;

export type KeyResultSharedItemStatus = typeof KeyResultSharedItemStatus[keyof typeof KeyResultSharedItemStatus];
/**
 * KeyResultの共有事項のタイトルを表すオブジェクト
 * 入力引数用
 */
export type KeyResultSharedItemTitlesInput = {
  /** その他 */
  readonly other?: InputMaybe<Scalars['String']>;
  /** 優先事項 */
  readonly priority?: InputMaybe<Scalars['String']>;
  /** 課題・障害 */
  readonly problem?: InputMaybe<Scalars['String']>;
  /** ウィンセッション */
  readonly winSession?: InputMaybe<Scalars['String']>;
};

/** KeyResultSharedItemの種別 */
export const KeyResultSharedItemType = {
  /** そのほか */
  Other: 'OTHER',
  /** 優先事項 */
  Priority: 'PRIORITY',
  /** 課題・障害 */
  Problem: 'PROBLEM',
  /** ウィンセッション */
  WinSession: 'WIN_SESSION'
} as const;

export type KeyResultSharedItemType = typeof KeyResultSharedItemType[keyof typeof KeyResultSharedItemType];
/** Key Result の進捗率の更新タイプ */
export const KeyResultUpdateType = {
  /** 自動 */
  Automate: 'AUTOMATE',
  /** 手動 */
  Manual: 'MANUAL'
} as const;

export type KeyResultUpdateType = typeof KeyResultUpdateType[keyof typeof KeyResultUpdateType];
/** ウィンセッション（成果）を表すオブジェクト */
export type KeyResultWinSession = KeyResultSharedItem & {
  readonly __typename?: 'KeyResultWinSession';
  /** 共有事項 */
  readonly body?: Maybe<Document>;
  /** 作成日時 */
  readonly createdAt: Scalars['DateTime'];
  /** ID */
  readonly id: Scalars['ID'];
  /** 更新日時 */
  readonly updatedAt: Scalars['DateTime'];
};

/**
 * KRのweight設定更新オブジェクト
 * 入力引数用
 */
export type KeyResultsWeightInput = {
  /** Key Result ID */
  readonly id: Scalars['String'];
  /** ウェイト */
  readonly weight: Scalars['Int'];
};

/** 言語の種類 */
export const Language = {
  /** 英語 */
  English: 'ENGLISH',
  /** 日本語 */
  Japanese: 'JAPANESE'
} as const;

export type Language = typeof Language[keyof typeof Language];
export type LastComments = {
  readonly __typename?: 'LastComments';
  /** ID（CheckinKeyResult）が所属しているチェックインサマリーKeyResult */
  readonly checkinSummaryKeyResult: CheckinSummaryKeyResult;
  /**
   * ID
   * 実態としてはこのリソースにIDはないためCheckinKeyResultIDで代替しています
   */
  readonly id: Scalars['ID'];
  /** その他 */
  readonly other?: Maybe<KeyResultOther>;
  /** 優先事項 */
  readonly priority?: Maybe<KeyResultPriority>;
  /** 困りごと */
  readonly problem?: Maybe<KeyResultProblem>;
  /** ウィン */
  readonly winSession?: Maybe<KeyResultWinSession>;
};

export type LowKeyResultConfidence = RecommendAgenda & {
  readonly __typename?: 'LowKeyResultConfidence';
  /** id */
  readonly id: Scalars['ID'];
  /** KeyResult */
  readonly keyResult: KeyResult;
  /** おすすめアジェンダの種別 */
  readonly type: RecommendAgendaType;
};

/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type Mutation = {
  readonly __typename?: 'Mutation';
  /**
   * OKRノードを追加する
   * Arrayのfirst indexは追加されたNode
   * second indexはparentOkrNodeIdが指定されていれば親のnodeがセット
   */
  readonly addOkrNode: ReadonlyArray<OkrNode>;
  /** グループに管理者をアタッチ */
  readonly attachAdminToGroup: Group;
  /** Objectiveにグループをアタッチ */
  readonly attachGroupToObjective: Objective;
  /** グループにメンバーをアタッチ */
  readonly attachMemberToGroup: Group;
  /** KeyResultにユーザーをアタッチ */
  readonly attachMemberToKeyResult: KeyResult;
  /** ノートにラベルをアタッチ */
  readonly attachNoteLabel: Note;
  /** 個人ノートにラベルをアタッチ */
  readonly attachNoteLabelToPersonalNote: PersonalNote;
  /** OkrNodeにOkrLabelをアタッチ */
  readonly attachOkrLabel: OkrNode;
  /**
   * SmartHR従業員情報の更新
   * ids: smarthr従業員のid
   * enable: 連携の有効化
   * startDate: 利用開始日
   */
  readonly bulkUpdateSmartHREmployee: ReadonlyArray<Maybe<SmartHrEmployee>>;
  /** 固定アジェンダを複製する */
  readonly copyFixedAgenda: FixedAgenda;
  /** ノートをコピーする */
  readonly copyNote: Note;
  /** OKR期間をコピーする */
  readonly copyOkrTerm: OkrTerm;
  /** 個人ノートをコピーする */
  readonly copyPersonalNote: PersonalNote;
  /** ActionPlanを作成する */
  readonly createActionPlan?: Maybe<ActionPlan>;
  /** KRのチェックインの作成 */
  readonly createCheckinKeyResults: ReadonlyArray<CheckinKeyResult>;
  /** チェックインサマリーにリアクションを追加 */
  readonly createCheckinSummaryReaction: CheckinSummaryReaction;
  /** コメント作成 */
  readonly createComment: NoteComment;
  /** 固定アジェンダを作成 */
  readonly createFixedAgenda: FixedAgenda;
  /** グループを作成する */
  readonly createGroup: Group;
  /** 複数のKeyResultを作成する */
  readonly createKeyResults: ReadonlyArray<KeyResult>;
  /** ノートを作成する */
  readonly createNote: Note;
  /** ノートのコメントにリアクションする */
  readonly createNoteCommentReaction: NoteCommentReaction;
  /** ノートのラベルを追加 */
  readonly createNoteLabel: NoteLabel;
  /** ノートリアクション作成 */
  readonly createNoteReaction: NoteReaction;
  /** OkrLabel を追加 */
  readonly createOkrLabel: OkrLabel;
  /** OKR期間を作成する */
  readonly createOkrTerm: OkrTerm;
  /** 個人ノートを作成する */
  readonly createPersonalNote: PersonalNote;
  /** 個人ノートのコメント作成 */
  readonly createPersonalNoteComment: PersonalNoteComment;
  /** 個人ノートのコメントにリアクションする */
  readonly createPersonalNoteCommentReaction: PersonalNoteCommentReaction;
  /** 個人ノートのリアクション作成 */
  readonly createPersonalNoteReaction: PersonalNoteReaction;
  /** ユーザーアジェンダを作成 */
  readonly createUserAgenda: UserAgenda;
  /** ブックマークを作成する */
  readonly createUserBookmark: UserBookmark;
  /** 1on1のペア情報と開催予定の1on1を新規作成をする */
  readonly createUserOneOnOneMeetings: UserOneOnOne;
  /** ActionPlanを削除する */
  readonly deleteActionPlan: Scalars['ID'];
  /** チェックインサマリーのリアクションを削除 */
  readonly deleteCheckinSummaryReaction: Scalars['ID'];
  /** コメントを削除する */
  readonly deleteComment: Scalars['ID'];
  /** 固定アジェンダを削除する */
  readonly deleteFixedAgenda: Scalars['ID'];
  /** Key Resultを削除 */
  readonly deleteKeyResult: Scalars['ID'];
  /** Key Result自信度を削除 */
  readonly deleteKeyResultConfidence: Scalars['ID'];
  /** ノートを削除する */
  readonly deleteNote: Scalars['ID'];
  /** ノートのコメントのリアクションを取り消す */
  readonly deleteNoteCommentReaction: Scalars['ID'];
  /** ノートのラベルを削除 */
  readonly deleteNoteLabel: Scalars['ID'];
  /** ノートリアクション削除 */
  readonly deleteNoteReaction: Scalars['ID'];
  /** OkrLabelを削除 */
  readonly deleteOkrLabel: Scalars['ID'];
  /**
   * OKRノードを削除する
   * 子のノードは別ツリーとしてRootとなる
   */
  readonly deleteOkrNode: Scalars['ID'];
  /** OKR期間を削除する */
  readonly deleteOkrTerm: Scalars['ID'];
  /** 1on1を削除する */
  readonly deleteOneOnOne: Scalars['ID'];
  /** 1on1を削除する */
  readonly deleteOneOnOneMeeting: Scalars['ID'];
  /** 個人ノートを削除する */
  readonly deletePersonalNote: Scalars['ID'];
  /** 個人ノートのコメントを削除する */
  readonly deletePersonalNoteComment: Scalars['ID'];
  /** 個人ノートのコメントのリアクションを取り消す */
  readonly deletePersonalNoteCommentReaction: Scalars['ID'];
  /** 個人ノートのリアクション削除 */
  readonly deletePersonalNoteReaction: Scalars['ID'];
  /** ユーザーアジェンダを削除する */
  readonly deleteUserAgenda: Scalars['ID'];
  /** ブックマークを削除する */
  readonly deleteUserBookmark: OkrTerm;
  /** 指定IDのユーザーを削除する */
  readonly deleteUserById: Scalars['ID'];
  /** グループから管理者をデタッチ */
  readonly detachAdminFromGroup: Group;
  /** Objectiveにグループをデタッチ */
  readonly detachGroupFromObjective: Objective;
  /** グループからメンバーをデタッチ */
  readonly detachMemberFromGroup: Group;
  /** KeyResultにユーザーをデタッチ */
  readonly detachMemberFromKeyResult: KeyResult;
  /** ノートにラベルをデタッチ */
  readonly detachNoteLabel: Note;
  /** 個人ノートにラベルをデタッチ */
  readonly detachNoteLabelToPersonalNote: PersonalNote;
  /** OkrNodeにOkrLabelをデタッチ */
  readonly detachOkrLabel: OkrNode;
  /** グループの無効化 */
  readonly disableGroup: ReadonlyArray<Group>;
  /** Chatworkと連携する */
  readonly integrateChatwork: OkrNode;
  /** Slackと連携する */
  readonly integrateSlackToOkrNode: OkrNode;
  /**
   * SmartHR連携
   * すでに連携済みの場合はtokenは必要ない
   */
  readonly integrateSmartHR: Organization;
  /** Teamsで連携する */
  readonly integrateTeams: OkrNode;
  /** ユーザを招待する */
  readonly inviteUser: User;
  /**
   * グループの移動
   * 並び替えやbranch毎移動させる場合に利用する
   * parentGroupIdは変更後の親となるグループIDを指定する
   * nullであればTOP階層のものとする
   */
  readonly moveGroup: ReadonlyArray<Group>;
  /** 指定OKRノードを引数のOKRノードの親に移動させます */
  readonly moveOkrNode: ReadonlyArray<OkrNode>;
  /** Key Resultを並べ替える */
  readonly reOrderingKeyResult: ReadonlyArray<KeyResult>;
  /** OKRノードを並び替える */
  readonly reOrderingOkrNode: ReadonlyArray<OkrNode>;
  /** 未読の通知をすべて既読にする */
  readonly readNotifications: ReadonlyArray<Notification>;
  /** 指定したProviderのOauth連携の解除 */
  readonly removeOAuthInformation: Scalars['ID'];
  /** アカウント有効化の招待メールを再送する */
  readonly resendInvitation: User;
  /** 指定ユーザIDのユーザのパスワードをリセットする */
  readonly resetPasswordUser: User;
  /** Chatworkとの連携を解除する */
  readonly segregateChatwork: OkrNode;
  /** Slackとの連携を解除する */
  readonly segregateSlackFromOkrNode: OkrNode;
  /** Slackとの連携を解除し登録しているSlackチャンネルを全て解除する */
  readonly segregateSlackFromOkrNodeAndIntegratedChannels: OkrNode;
  /**
   * Slackとの連携を解除する
   * テナント単位での連携解除がこちらでOKRノード毎の連携はまた別mutation
   */
  readonly segregateSlackIntegration: Organization;
  /** SmartHR連携解除 */
  readonly segregateSmartHR: Organization;
  /** Teamsとの連携を解除する */
  readonly segregateTeamsIntegration: OkrNode;
  /** startCheckinSummary */
  readonly startCheckinSummary: CheckinSummary;
  /** ActionPlanを更新する */
  readonly updateActionPlan?: Maybe<ActionPlan>;
  /** Slackで一括更新通知を受け取るフラグの変更 */
  readonly updateBulkUpdateNotificationEnabled: Organization;
  /** チェックインサマリーの議事メモを更新する */
  readonly updateCheckinSummaryDescription: CheckinSummary;
  /** コメントを更新する */
  readonly updateComment: NoteComment;
  /** OKR用語に任意の用語を設定 */
  readonly updateCustomOkrGlossaries: ReadonlyArray<CustomOkrGlossary>;
  /** メールアドレスの更新 */
  readonly updateEmail: User;
  /** 固定アジェンダを更新 */
  readonly updateFixedAgenda: FixedAgenda;
  /** 固定アジェンダの表示順を更新 */
  readonly updateFixedAgendaIndex: ReadonlyArray<FixedAgenda>;
  /** 固定アジェンダのステータスを更新 */
  readonly updateFixedAgendaStatus: FixedAgenda;
  /** グループ情報更新 */
  readonly updateGroup: Group;
  /** IPアドレスのホワイトリストを更新 */
  readonly updateIpWhitelist: Organization;
  /** Key Resultの有効/無効を更新する */
  readonly updateKeyResultActivateStatus: KeyResult;
  /** KeyResultのDescriptionを更新する */
  readonly updateKeyResultDescription: KeyResult;
  /** Key Resultの初期値・目標値・単位の設定する */
  readonly updateKeyResultGoalSetting: KeyResult;
  /** KeyResultに関数情報を更新する */
  readonly updateKeyResultInfo: KeyResult;
  /** KeyResultの自動集計か否かを更新する */
  readonly updateKeyResultIsAutoAggregate: KeyResult;
  /** KeyResultの名前を更新する */
  readonly updateKeyResultName: KeyResult;
  /** KeyResultのOwnerを更新する */
  readonly updateKeyResultOwner: KeyResult;
  /**
   * KeyResultのParent Objectiveを更新する
   * 変更されたNodeと変更後Nodeを返します
   */
  readonly updateKeyResultParentObjective: ReadonlyArray<OkrNode>;
  /** Key Resultの進捗率を更新する */
  readonly updateKeyResultProgressRate: KeyResult;
  /** Key Resultの進捗率と自信度を更新する */
  readonly updateKeyResultProgressRateAndConfidence: KeyResult;
  /** KRの共有事項の更新 */
  readonly updateKeyResultSharedItem: KeyResultSharedItem;
  /** KeyResultのmoon,roofを更新する */
  readonly updateKeyResultTargetSetting: KeyResult;
  /** KeyResultのweight設定を更新する */
  readonly updateKeyResultsWeight: Objective;
  /** 自身の言語設定を更新する */
  readonly updateLanguage: User;
  /** ノートを更新する */
  readonly updateNote: Note;
  /** ノートのラベルを更新 */
  readonly updateNoteLabel: NoteLabel;
  /** ノート利用可能ユーザを更新する */
  readonly updateNoteUsers: Scalars['ID'];
  /** OAuthドメインのホワイトリストを更新 */
  readonly updateOauthWhitelist: Organization;
  /** Objectiveの有効/無効ステータスを更新する */
  readonly updateObjectiveActivateStatus: ReadonlyArray<OkrNode>;
  /** ObjectiveのDescriptionを更新する */
  readonly updateObjectiveDescription: Objective;
  /** Objectiveの名前を更新する */
  readonly updateObjectiveName: Objective;
  /** Objectiveの責任者を更新する */
  readonly updateObjectiveOwner: Objective;
  /** Objectiveの上位Key Resultを更新する */
  readonly updateObjectiveParentKeyResults: Objective;
  /** ObjectiveのKR重み付けの利用可否を更新する */
  readonly updateObjectiveUseWeighting: Objective;
  /** OkrLabel を更新 */
  readonly updateOkrLabel: OkrLabel;
  /** Objectiveに関する情報を更新する */
  readonly updateOkrNode: ReadonlyArray<OkrNode>;
  /** OKR期間を更新する */
  readonly updateOkrTerm: OkrTerm;
  /** 1on1機能の公開・非公開を管理するフラグの変更 */
  readonly updateOneOnOneEnabled: Organization;
  /** 1on1固定アジェンダ用議事録を更新する */
  readonly updateOneOnOneMeetingFixedAgendaNote: FixedAgenda;
  /** 1on1議事録を更新する */
  readonly updateOneOnOneMeetingNote: UserAgenda;
  /** 1on1個人メモを更新する */
  readonly updateOneOnOneMeetingPrivateNote: UserOneOnOneMeetingPrivateNote;
  /** 企業情報を変更する */
  readonly updateOrganization: Organization;
  /** パスワードの更新 */
  readonly updatePassword: User;
  /** 個人ノートを更新する */
  readonly updatePersonalNote: PersonalNote;
  /** 個人ノートのコメントを更新する */
  readonly updatePersonalNoteComment: PersonalNoteComment;
  /** 個人ノート利用可能ユーザを更新する */
  readonly updatePersonalNoteUsers: Scalars['ID'];
  /** OKRツリーで初期表示時にfolding状態としないOKRノードの階層の個人設定の更新 */
  readonly updatePersonalVisibleOkrNodeLevel: UserSetting;
  /** 自身のプロフィールを更新する */
  readonly updateProfile: User;
  /** 自身のセキュリティ設定を更新する */
  readonly updateSecuritySetting: User;
  /** OKRツリーで無効化したOKRを表示するかどうかの個人設定の更新 */
  readonly updateShowDisabledOkrs: UserSetting;
  /** 指定ユーザIDのユーザの有効可/無効化を行う */
  readonly updateUserActivateStatus: User;
  /** ユーザーアジェンダを更新 */
  readonly updateUserAgenda: UserAgenda;
  /** ユーザーアジェンダの表示順を更新 */
  readonly updateUserAgendaIndex: ReadonlyArray<UserAgenda>;
  /** 指定IDのユーザー情報を更新する */
  readonly updateUserById: User;
  /** 自身の設定を更新する */
  readonly updateUserSetting: User;
  /** 未開催1on1の新規作成・更新・論理削除をする */
  readonly upsertAndDeleteUserOneOnOneMeetings: UserOneOnOne;
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationAddOkrNodeArgs = {
  input: AddOkrNodeInput;
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationAttachAdminToGroupArgs = {
  adminUserId: Scalars['ID'];
  id: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationAttachGroupToObjectiveArgs = {
  groupId: Scalars['ID'];
  id: Scalars['ID'];
  ownerId: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationAttachMemberToGroupArgs = {
  id: Scalars['ID'];
  memberUserId: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationAttachMemberToKeyResultArgs = {
  id: Scalars['ID'];
  memberId: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationAttachNoteLabelArgs = {
  noteId: Scalars['ID'];
  noteLabelId: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationAttachNoteLabelToPersonalNoteArgs = {
  noteLabelId: Scalars['ID'];
  personalNoteId: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationAttachOkrLabelArgs = {
  okrLabelId: Scalars['ID'];
  okrNodeId: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationBulkUpdateSmartHrEmployeeArgs = {
  enable?: InputMaybe<Scalars['Boolean']>;
  ids: ReadonlyArray<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationCopyFixedAgendaArgs = {
  id: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationCopyNoteArgs = {
  id: Scalars['ID'];
  title: Scalars['String'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationCopyOkrTermArgs = {
  endDate: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  startDate: Scalars['DateTime'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationCopyPersonalNoteArgs = {
  id: Scalars['ID'];
  title: Scalars['String'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationCreateActionPlanArgs = {
  body?: InputMaybe<DocumentInput>;
  dueDate?: InputMaybe<Scalars['DateTime']>;
  keyResultId: Scalars['ID'];
  ownerId: Scalars['ID'];
  status: ActionPlanStatus;
  title: Scalars['String'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationCreateCheckinKeyResultsArgs = {
  input: ReadonlyArray<CheckinKeyResultInput>;
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationCreateCheckinSummaryReactionArgs = {
  checkinSummaryId: Scalars['ID'];
  emoji: Scalars['String'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationCreateCommentArgs = {
  attachments: ReadonlyArray<FileInput>;
  body: DocumentInput;
  mentionedUsers: ReadonlyArray<Scalars['ID']>;
  noteId: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationCreateFixedAgendaArgs = {
  input: CreateFixedAgendaInput;
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationCreateGroupArgs = {
  memberUserIds: ReadonlyArray<Scalars['ID']>;
  name: Scalars['String'];
  ownerUserIds: ReadonlyArray<Scalars['ID']>;
  parentGroupId?: InputMaybe<Scalars['ID']>;
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationCreateKeyResultsArgs = {
  inputs: ReadonlyArray<CreateKeyResultInput>;
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationCreateNoteArgs = {
  input: CreateNoteInput;
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationCreateNoteCommentReactionArgs = {
  emoji: Scalars['String'];
  noteCommentId: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationCreateNoteLabelArgs = {
  color: Scalars['String'];
  name: Scalars['String'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationCreateNoteReactionArgs = {
  emoji: Scalars['String'];
  noteId: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationCreateOkrLabelArgs = {
  color: Scalars['String'];
  name: Scalars['String'];
  okrTermId: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationCreateOkrTermArgs = {
  endDate: Scalars['DateTime'];
  name: Scalars['String'];
  startDate: Scalars['DateTime'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationCreatePersonalNoteArgs = {
  attachments: ReadonlyArray<FileInput>;
  body?: InputMaybe<DocumentInput>;
  okrTermId: Scalars['ID'];
  permission: NotePermission;
  title: Scalars['String'];
  userId: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationCreatePersonalNoteCommentArgs = {
  attachments: ReadonlyArray<FileInput>;
  body: DocumentInput;
  personalNoteId: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationCreatePersonalNoteCommentReactionArgs = {
  emoji: Scalars['String'];
  personalNoteCommentId: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationCreatePersonalNoteReactionArgs = {
  emoji: Scalars['String'];
  personalNoteId: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationCreateUserAgendaArgs = {
  input: CreateUserAgendaInput;
  userOneOnOneMeetingId: Scalars['String'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationCreateUserBookmarkArgs = {
  filterGroupIds: ReadonlyArray<Scalars['ID']>;
  filterUserIds: ReadonlyArray<Scalars['ID']>;
  focusNodeId?: InputMaybe<Scalars['ID']>;
  foldNodeIds: ReadonlyArray<Scalars['ID']>;
  name: Scalars['String'];
  okrTermId: Scalars['ID'];
  subtreeNodeId?: InputMaybe<Scalars['ID']>;
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationCreateUserOneOnOneMeetingsArgs = {
  input: CreateUserOneOnOneMeetingsInput;
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationDeleteActionPlanArgs = {
  id: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationDeleteCheckinSummaryReactionArgs = {
  id: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationDeleteCommentArgs = {
  id: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationDeleteFixedAgendaArgs = {
  id: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationDeleteKeyResultArgs = {
  id: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationDeleteKeyResultConfidenceArgs = {
  id: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationDeleteNoteArgs = {
  id: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationDeleteNoteCommentReactionArgs = {
  id: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationDeleteNoteLabelArgs = {
  id: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationDeleteNoteReactionArgs = {
  id: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationDeleteOkrLabelArgs = {
  id: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationDeleteOkrNodeArgs = {
  id: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationDeleteOkrTermArgs = {
  id: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationDeleteOneOnOneArgs = {
  id: Scalars['ID'];
  notifierInput: NotifierOneOnOneInput;
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationDeleteOneOnOneMeetingArgs = {
  id: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationDeletePersonalNoteArgs = {
  id: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationDeletePersonalNoteCommentArgs = {
  id: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationDeletePersonalNoteCommentReactionArgs = {
  id: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationDeletePersonalNoteReactionArgs = {
  id: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationDeleteUserAgendaArgs = {
  id: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationDeleteUserBookmarkArgs = {
  id: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationDeleteUserByIdArgs = {
  id: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationDetachAdminFromGroupArgs = {
  adminUserId: Scalars['ID'];
  id: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationDetachGroupFromObjectiveArgs = {
  groupId: Scalars['ID'];
  id: Scalars['ID'];
  ownerId: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationDetachMemberFromGroupArgs = {
  id: Scalars['ID'];
  memberUserId: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationDetachMemberFromKeyResultArgs = {
  id: Scalars['ID'];
  memberId: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationDetachNoteLabelArgs = {
  noteId: Scalars['ID'];
  noteLabelId: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationDetachNoteLabelToPersonalNoteArgs = {
  noteLabelId: Scalars['ID'];
  personalNoteId: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationDetachOkrLabelArgs = {
  okrLabelId: Scalars['ID'];
  okrNodeId: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationDisableGroupArgs = {
  id: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationIntegrateChatworkArgs = {
  apiToken: Scalars['String'];
  okrNodeId: Scalars['ID'];
  roomId: Scalars['String'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationIntegrateSlackToOkrNodeArgs = {
  channelId: Scalars['String'];
  okrNodeId: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationIntegrateSmartHrArgs = {
  input?: InputMaybe<IntegateSmartHrInput>;
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationIntegrateTeamsArgs = {
  okrNodeId: Scalars['ID'];
  webhookUrl: Scalars['String'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationInviteUserArgs = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  isAdmin: Scalars['Boolean'];
  lastName: Scalars['String'];
  samlEnabled: Scalars['Boolean'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationMoveGroupArgs = {
  id: Scalars['ID'];
  index: Scalars['Int'];
  parentGroupId?: InputMaybe<Scalars['ID']>;
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationMoveOkrNodeArgs = {
  id: Scalars['ID'];
  parentOkrNodeId?: InputMaybe<Scalars['ID']>;
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationReOrderingKeyResultArgs = {
  displayOrder: Scalars['Int'];
  keyResultID: Scalars['ID'];
  nodeID: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationReOrderingOkrNodeArgs = {
  ID: Scalars['ID'];
  displayOrder: Scalars['Int'];
  parentOkrNodeID: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationRemoveOAuthInformationArgs = {
  provider: OAuthProvider;
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationResendInvitationArgs = {
  userId: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationResetPasswordUserArgs = {
  id: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationSegregateChatworkArgs = {
  okrNodeId: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationSegregateSlackFromOkrNodeArgs = {
  channelId: Scalars['String'];
  okrNodeId: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationSegregateSlackFromOkrNodeAndIntegratedChannelsArgs = {
  okrNodeId: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationSegregateTeamsIntegrationArgs = {
  okrNodeId: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationStartCheckinSummaryArgs = {
  checkinSummaryId: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationUpdateActionPlanArgs = {
  actualValue?: InputMaybe<Scalars['Float']>;
  body?: InputMaybe<DocumentInput>;
  displayOrder?: InputMaybe<Scalars['Int']>;
  dueDate?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  keyResultId: Scalars['ID'];
  memberIds: ReadonlyArray<Scalars['ID']>;
  ownerId?: InputMaybe<Scalars['ID']>;
  progressRate?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<ActionPlanStatus>;
  targetValue?: InputMaybe<Scalars['Float']>;
  title?: InputMaybe<Scalars['String']>;
  unit?: InputMaybe<Scalars['String']>;
  updateType: ActionPlanUpdateType;
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationUpdateBulkUpdateNotificationEnabledArgs = {
  enabled: Scalars['Boolean'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationUpdateCheckinSummaryDescriptionArgs = {
  description: DocumentInput;
  id: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationUpdateCommentArgs = {
  attachments: ReadonlyArray<FileInput>;
  body: DocumentInput;
  id: Scalars['ID'];
  mentionedUsers: ReadonlyArray<Scalars['ID']>;
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationUpdateCustomOkrGlossariesArgs = {
  inputs: ReadonlyArray<CustomOkrGlossaryInput>;
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationUpdateEmailArgs = {
  email: Scalars['String'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationUpdateFixedAgendaArgs = {
  id: Scalars['ID'];
  input: UpdateFixedAgendaInput;
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationUpdateFixedAgendaIndexArgs = {
  id: Scalars['ID'];
  input: UpdateFixedAgendaIndexInput;
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationUpdateFixedAgendaStatusArgs = {
  id: Scalars['ID'];
  input: UpdateFixedAgendaStatusInput;
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationUpdateGroupArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationUpdateIpWhitelistArgs = {
  whitelist: ReadonlyArray<Scalars['String']>;
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationUpdateKeyResultActivateStatusArgs = {
  id: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationUpdateKeyResultDescriptionArgs = {
  attachments: ReadonlyArray<FileInput>;
  description: DocumentInput;
  id: Scalars['ID'];
  isDescriptionChanged?: InputMaybe<Scalars['Boolean']>;
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationUpdateKeyResultGoalSettingArgs = {
  id: Scalars['ID'];
  input: GoalSettingInput;
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationUpdateKeyResultInfoArgs = {
  input: UpdateKeyResultInfoInput;
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationUpdateKeyResultIsAutoAggregateArgs = {
  id: Scalars['ID'];
  isAutoAggregate: Scalars['Boolean'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationUpdateKeyResultNameArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationUpdateKeyResultOwnerArgs = {
  id: Scalars['ID'];
  ownerId: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationUpdateKeyResultParentObjectiveArgs = {
  id: Scalars['ID'];
  parentObjectiveId: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationUpdateKeyResultProgressRateArgs = {
  id: Scalars['ID'];
  input: ProgressRateInput;
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationUpdateKeyResultProgressRateAndConfidenceArgs = {
  id: Scalars['ID'];
  input: ProgressRateAndConfidenceInput;
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationUpdateKeyResultSharedItemArgs = {
  body: DocumentInput;
  id: Scalars['ID'];
  itemType: KeyResultSharedItemType;
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationUpdateKeyResultTargetSettingArgs = {
  id: Scalars['ID'];
  targetSetting: TargetSettingType;
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationUpdateKeyResultsWeightArgs = {
  inputs: ReadonlyArray<KeyResultsWeightInput>;
  objectiveId: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationUpdateLanguageArgs = {
  language: Language;
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationUpdateNoteArgs = {
  attachments: ReadonlyArray<FileInput>;
  body?: InputMaybe<DocumentInput>;
  id: Scalars['ID'];
  keyResultId?: InputMaybe<Scalars['ID']>;
  labelIds?: InputMaybe<UpdateNoteLabelIds>;
  objectiveId?: InputMaybe<Scalars['ID']>;
  permission: NotePermission;
  title: Scalars['String'];
  userIds?: InputMaybe<UpdateNoteUserIds>;
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationUpdateNoteLabelArgs = {
  color: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationUpdateNoteUsersArgs = {
  noteId: Scalars['ID'];
  userIds: ReadonlyArray<Scalars['ID']>;
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationUpdateOauthWhitelistArgs = {
  whitelist: ReadonlyArray<Scalars['String']>;
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationUpdateObjectiveActivateStatusArgs = {
  id: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationUpdateObjectiveDescriptionArgs = {
  input: UpdateObjectiveDescriptionInput;
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationUpdateObjectiveNameArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationUpdateObjectiveOwnerArgs = {
  id: Scalars['ID'];
  ownerId: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationUpdateObjectiveParentKeyResultsArgs = {
  id: Scalars['ID'];
  parentKeyResultIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationUpdateObjectiveUseWeightingArgs = {
  id: Scalars['ID'];
  useWeighting: Scalars['Boolean'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationUpdateOkrLabelArgs = {
  color: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationUpdateOkrNodeArgs = {
  input: UpdateOkrNodeInput;
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationUpdateOkrTermArgs = {
  endDate: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  startDate: Scalars['DateTime'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationUpdateOneOnOneEnabledArgs = {
  enabled: Scalars['Boolean'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationUpdateOneOnOneMeetingFixedAgendaNoteArgs = {
  fixedAgendaId: Scalars['ID'];
  input: UpdateOneOnOneMeetingFixedAgendaNoteInput;
  meetingId: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationUpdateOneOnOneMeetingNoteArgs = {
  input: UpdateOneOnOneMeetingNoteInput;
  userAgendaId: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationUpdateOneOnOneMeetingPrivateNoteArgs = {
  id: Scalars['ID'];
  input: UpdateOneOnOneMeetingPrivateNoteInput;
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationUpdateOrganizationArgs = {
  logoPath?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationUpdatePasswordArgs = {
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
  passwordConfirmation: Scalars['String'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationUpdatePersonalNoteArgs = {
  attachments: ReadonlyArray<FileInput>;
  body?: InputMaybe<DocumentInput>;
  id: Scalars['ID'];
  permission: NotePermission;
  title: Scalars['String'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationUpdatePersonalNoteCommentArgs = {
  attachments: ReadonlyArray<FileInput>;
  body: DocumentInput;
  id: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationUpdatePersonalNoteUsersArgs = {
  noteId: Scalars['ID'];
  userIds: ReadonlyArray<Scalars['ID']>;
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationUpdatePersonalVisibleOkrNodeLevelArgs = {
  level: Scalars['Int'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationUpdateProfileArgs = {
  avatarPath?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationUpdateSecuritySettingArgs = {
  otpRequiredForLogin: Scalars['Boolean'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationUpdateShowDisabledOkrsArgs = {
  isShow: Scalars['Boolean'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationUpdateUserActivateStatusArgs = {
  id: Scalars['ID'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationUpdateUserAgendaArgs = {
  id: Scalars['ID'];
  input: UpdateUserAgendaInput;
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationUpdateUserAgendaIndexArgs = {
  id: Scalars['ID'];
  input: UpdateUserAgendaIndexInput;
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationUpdateUserByIdArgs = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  isAdmin: Scalars['Boolean'];
  lastName: Scalars['String'];
  samlEnabled: Scalars['Boolean'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationUpdateUserSettingArgs = {
  notifyBulkUpdateEnabledOnSlack: Scalars['Boolean'];
  notifyOkrEmailEnabled: Scalars['Boolean'];
  notifyOneOnOneEmailEnabled: Scalars['Boolean'];
};


/**
 * ミューテーション一覧
 * オブジェクト更新の全てのエントリポイントを定義しています
 */
export type MutationUpsertAndDeleteUserOneOnOneMeetingsArgs = {
  input: UpsertAndDeleteUserOneOnOneMeetings;
};

export type NeverProgressKeyResult = RecommendAgenda & {
  readonly __typename?: 'NeverProgressKeyResult';
  /**
   * 現在の日付 - KRが作成された日付の日数
   * OKR期間外にKRが作成された場合は、「KRが作成された日付」はOKR期間の開始日として計算する
   */
  readonly createdDaysAgo: Scalars['Int'];
  /** id */
  readonly id: Scalars['ID'];
  /** KeyResult */
  readonly keyResult: KeyResult;
  /** おすすめアジェンダの種別 */
  readonly type: RecommendAgendaType;
};

export type NoProgressKeyResult = RecommendAgenda & {
  readonly __typename?: 'NoProgressKeyResult';
  /** 進捗率が更新されていない日数 */
  readonly days: Scalars['Int'];
  /** id */
  readonly id: Scalars['ID'];
  /** KeyResult */
  readonly keyResult: KeyResult;
  /** おすすめアジェンダの種別 */
  readonly type: RecommendAgendaType;
};

/**
 * Relay Node InterfaceのNode
 * See: https://relay.dev/graphql/objectidentification.htm#sel-DABDDBAADLA0Cl0c
 */
export type Node = {
  /** ID */
  readonly id: Scalars['ID'];
};

/** ノートを表すオブジェクト */
export type Note = Node & {
  readonly __typename?: 'Note';
  /** 添付ファイル */
  readonly attachments: ReadonlyArray<File>;
  /** 投稿者 */
  readonly author: User;
  /** 本文 */
  readonly body?: Maybe<Document>;
  /** コメント */
  readonly comments: ReadonlyArray<NoteComment>;
  /** 作成日時 */
  readonly createdAt: Scalars['DateTime'];
  /** ノートID */
  readonly id: Scalars['ID'];
  /** ラベル */
  readonly labels: ReadonlyArray<NoteLabel>;
  /** OKR期間 */
  readonly okrTerm: OkrTerm;
  /** 公開範囲 */
  readonly permission: NotePermission;
  /** リアクション */
  readonly reactions: ReadonlyArray<NoteReaction>;
  /** 属しているリソース */
  readonly resource: NoteResource;
  /** タイトル */
  readonly title: Scalars['String'];
  /** 更新日時 */
  readonly updatedAt: Scalars['DateTime'];
  /** 更新者 */
  readonly updatedUser: User;
};

/** ノート内のコメントを表すオブジェクト */
export type NoteComment = {
  readonly __typename?: 'NoteComment';
  /** 添付ファイル */
  readonly attachments: ReadonlyArray<File>;
  /** 投稿者 */
  readonly author: User;
  /** 本文 */
  readonly body: Document;
  /** 作成日時 */
  readonly createdAt: Scalars['DateTime'];
  /** ノートコメントID */
  readonly id: Scalars['ID'];
  /** ノート */
  readonly note: Note;
  /** リアクション */
  readonly reactions: ReadonlyArray<NoteCommentReaction>;
  /** 更新日時 */
  readonly updatedAt: Scalars['DateTime'];
};

/** リアクションを表すオブジェクト */
export type NoteCommentReaction = {
  readonly __typename?: 'NoteCommentReaction';
  /** 作成日時 */
  readonly createdAt: Scalars['DateTime'];
  /** 絵文字 */
  readonly emoji: Scalars['String'];
  /** コメントリアクションID */
  readonly id: Scalars['ID'];
  /** コメント */
  readonly noteComment: NoteComment;
  /** 更新日時 */
  readonly updatedAt: Scalars['DateTime'];
  /** ユーザー */
  readonly user: User;
};

/** NoteのエッジのPath */
export type NoteConnection = {
  readonly __typename?: 'NoteConnection';
  /** edges */
  readonly edges?: Maybe<ReadonlyArray<Maybe<NoteEdge>>>;
  /** pageInfo */
  readonly pageInfo: PageInfo;
};

/** Noteのエッジオブジェクト */
export type NoteEdge = {
  readonly __typename?: 'NoteEdge';
  /** カーソル */
  readonly cursor: Scalars['ID'];
  /** ノード */
  readonly node?: Maybe<Note>;
};

/** ノートに付与できるラベルを表すオブジェクト */
export type NoteLabel = {
  readonly __typename?: 'NoteLabel';
  /** ラベル色 */
  readonly color: Scalars['String'];
  /** 作成日時 */
  readonly createdAt: Scalars['DateTime'];
  /** ラベルID */
  readonly id: Scalars['ID'];
  /** ラベル名 */
  readonly name: Scalars['String'];
  /** ノート */
  readonly notes: ReadonlyArray<Note>;
  /** 個人ノート */
  readonly personalNotes: PersonalNoteConnection;
  /** 更新日時 */
  readonly updatedAt: Scalars['DateTime'];
};


/** ノートに付与できるラベルを表すオブジェクト */
export type NoteLabelNotesArgs = {
  okrTermId: Scalars['ID'];
};


/** ノートに付与できるラベルを表すオブジェクト */
export type NoteLabelPersonalNotesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Scalars['DateTime']>;
  last?: InputMaybe<Scalars['Int']>;
  to?: InputMaybe<Scalars['DateTime']>;
};

/** NoteLabelのソート条件 */
export type NoteLabelSort = {
  /** ソートキー */
  readonly field: NoteLabelSortField;
};

/** NoteLabelのソートキー */
export const NoteLabelSortField = {
  /** ID */
  Id: 'ID'
} as const;

export type NoteLabelSortField = typeof NoteLabelSortField[keyof typeof NoteLabelSortField];
/** ノートと個人ノートを表す共用体 */
export type NoteOrPersonalNote = Note | PersonalNote;

/** Noteの公開設定 */
export const NotePermission = {
  /** 自分のみ */
  OwnerOnly: 'OWNER_ONLY',
  /** 非公開グループ */
  Private: 'PRIVATE',
  /** 公開グループ */
  Public: 'PUBLIC'
} as const;

export type NotePermission = typeof NotePermission[keyof typeof NotePermission];
/** リアクションを表すオブジェクト */
export type NoteReaction = {
  readonly __typename?: 'NoteReaction';
  /** 作成日時 */
  readonly createdAt: Scalars['DateTime'];
  /** 絵文字 */
  readonly emoji: Scalars['String'];
  /** リアクションID */
  readonly id: Scalars['ID'];
  /** ノート */
  readonly note: Note;
  /** 更新日時 */
  readonly updatedAt: Scalars['DateTime'];
  /** ユーザー */
  readonly user: User;
};

/** ノートに紐づくリソースを表す共用体 */
export type NoteResource = KeyResult | Objective;

/** 通知情報を表すオブジェクト */
export type Notification = Node & {
  readonly __typename?: 'Notification';
  /** 既読 */
  readonly alreadyRead: Scalars['Boolean'];
  /** 変更内容 */
  readonly changesBody: Scalars['String'];
  /** 通知作成日時 */
  readonly createdAt: Scalars['DateTime'];
  /** 通知ID */
  readonly id: Scalars['ID'];
  /** 通知対象のリソース */
  readonly resource: NotificationResource;
  /** 通知するアクションを行ったユーザー */
  readonly triggeredUser: User;
  /** 既読更新日時 */
  readonly updatedAt: Scalars['DateTime'];
};

/** ページングオブジェクトのエッジのPath */
export type NotificationConnection = {
  readonly __typename?: 'NotificationConnection';
  /** edges */
  readonly edges: ReadonlyArray<NotificationEdge>;
  /** pageInfo */
  readonly pageInfo: PageInfo;
  /** totalCount */
  readonly totalCount: Scalars['Int'];
};

/** ページングオブジェクトのエッジオブジェクト */
export type NotificationEdge = {
  readonly __typename?: 'NotificationEdge';
  /** カーソル */
  readonly cursor: Scalars['ID'];
  /** ノード */
  readonly node: Notification;
};

/** 通知情報のリソースを表す共用体 */
export type NotificationResource = KeyResult | Note | Objective;

/** notifierサービス連携時に利用するユーザー情報 */
export type NotificationTargetUser = {
  /** 通知対象のユーザーのメールアドレス */
  readonly email: Scalars['String'];
  /** 通知対象のユーザー 姓 */
  readonly firstName: Scalars['String'];
  /** 通知対象のユーザーの言語 */
  readonly language: Language;
  /** 通知対象のユーザー 名 */
  readonly lastName: Scalars['String'];
  /** 通知対象のユーザーの通知メールの有無 */
  readonly notifyOneOnOneEmailEnabled: Scalars['Boolean'];
};

/** notifierサービスとの連携情報 */
export type NotifierIntegrationField = {
  /** ChatworkApiToken */
  readonly chatworkApiToken?: InputMaybe<Scalars['String']>;
  /** Chatwork連携済みか(ChatworkApiTokenとRoomIdの有無で連携済みか判断できるので削除予定) */
  readonly chatworkIntegrated: Scalars['Boolean'];
  /** ChatworkRoomId */
  readonly chatworkRoomId?: InputMaybe<Scalars['String']>;
  /** Slackアクセストークン */
  readonly slackAccessToken?: InputMaybe<Scalars['String']>;
  /** Slack連携済みか(slackIntegratedChannelListの有無で連携済みか判断できるので削除予定) */
  readonly slackIntegrated: Scalars['Boolean'];
  /** Teams連携済みか(teamsWebhookUrlの有無で連携済みか判断できるので削除予定) */
  readonly teamsIntegrated: Scalars['Boolean'];
  /** TeamsWebhookUrl */
  readonly teamsWebhookUrl?: InputMaybe<Scalars['String']>;
};

/** 1on1の予定をnotifierサービスに連携するための情報 */
export type NotifierOneOnOneInput = {
  /** notifierサービスとの連携情報 */
  readonly notifierIntegrationField: NotifierIntegrationField;
  /** 通知対象のユーザー情報 */
  readonly toUser: NotificationTargetUser;
  /** 1on1を設定したユーザー */
  readonly triggeredUser: NotificationTargetUser;
};

/** OAuthProviderのリスト */
export const OAuthProvider = {
  /** Google */
  Google: 'GOOGLE'
} as const;

export type OAuthProvider = typeof OAuthProvider[keyof typeof OAuthProvider];
/** OKRのObjectiveを表すオブジェクト */
export type Objective = Node & OkrNodeItem & {
  readonly __typename?: 'Objective';
  /** 添付ファイル */
  readonly attachments: ReadonlyArray<File>;
  /** 指定期間のConfidence Score */
  readonly confidenceScores: ReadonlyArray<ObjectiveConfidenceScore>;
  /** 自信度 */
  readonly confidences: ReadonlyArray<ObjectiveConfidence>;
  /** 作成日時 */
  readonly createdAt: Scalars['DateTime'];
  /** 説明 */
  readonly description?: Maybe<Document>;
  /** イベント履歴 */
  readonly events: ReadonlyArray<ObjectiveEvent>;
  /** ObjectiveID */
  readonly id: Scalars['ID'];
  /** 無効化状態 */
  readonly isDisabled: Scalars['Boolean'];
  /** Objectiveに紐づくKey Result */
  readonly keyResults: ReadonlyArray<KeyResult>;
  /** 最新のConfidence Score */
  readonly latestConfidenceScore?: Maybe<ObjectiveConfidenceScore>;
  /** 名前 */
  readonly name: Scalars['String'];
  /** Objectiveが所属しているNode */
  readonly node: OkrNode;
  /** 投稿されたノート */
  readonly notes: ReadonlyArray<Note>;
  /** オーナー */
  readonly owner: User;
  /** Objectiveに紐づく上位Key Result */
  readonly parentKeyResults: ReadonlyArray<KeyResult>;
  /** 進捗率の着地予想 */
  readonly progressForecast: Scalars['Int'];
  /** 進捗率 */
  readonly progressRate?: Maybe<Scalars['Int']>;
  /** 進捗率の推移 */
  readonly progressRateHistory: ReadonlyArray<ObjectiveProgressRateHistory>;
  /** 進捗率のトレンド */
  readonly progressRateTrend: Scalars['Int'];
  /** 進捗率の最終更新日時 */
  readonly progressRateUpdatedAt?: Maybe<Scalars['DateTime']>;
  /** 結果 */
  readonly result?: Maybe<Document>;
  /** 更新日時 */
  readonly updatedAt: Scalars['DateTime'];
  /** KR重み付けの利用可否 */
  readonly useWeighting: Scalars['Boolean'];
};


/** OKRのObjectiveを表すオブジェクト */
export type ObjectiveConfidenceScoresArgs = {
  from?: InputMaybe<Scalars['DateTime']>;
  to?: InputMaybe<Scalars['DateTime']>;
};


/** OKRのObjectiveを表すオブジェクト */
export type ObjectiveKeyResultsArgs = {
  filter?: InputMaybe<OkrEnableFilter>;
};


/** OKRのObjectiveを表すオブジェクト */
export type ObjectiveParentKeyResultsArgs = {
  filter?: InputMaybe<OkrEnableFilter>;
};


/** OKRのObjectiveを表すオブジェクト */
export type ObjectiveProgressRateTrendArgs = {
  days: Scalars['Int'];
};

/** Objectiveのコンフィデンスを表すオブジェクト */
export type ObjectiveConfidence = Confidence & {
  readonly __typename?: 'ObjectiveConfidence';
  /** 作成日時 */
  readonly createdAt: Scalars['DateTime'];
  /** コンフィデンスID */
  readonly id: Scalars['ID'];
  /** レベル */
  readonly level: Scalars['Int'];
  /** Objective */
  readonly objective: Objective;
  /** 更新日時 */
  readonly updatedAt: Scalars['DateTime'];
  /** ユーザー */
  readonly user: User;
};

/** Objectiveのコンフィデンススコアを表すオブジェクト */
export type ObjectiveConfidenceScore = ConfidenceScore & {
  readonly __typename?: 'ObjectiveConfidenceScore';
  /** 平均 */
  readonly average: Scalars['Float'];
  /** 作成日時 */
  readonly createdAt: Scalars['DateTime'];
  /** コンフィデンススコアID */
  readonly id: Scalars['ID'];
  /** Objective */
  readonly objective: Objective;
  /** 標準偏差 */
  readonly standardDeviation: Scalars['Float'];
};

/** ページングオブジェクトのエッジのPath */
export type ObjectiveConnection = {
  readonly __typename?: 'ObjectiveConnection';
  /** edges */
  readonly edges?: Maybe<ReadonlyArray<Maybe<ObjectiveEdge>>>;
  /** pageInfo */
  readonly pageInfo: PageInfo;
};

/** ページングオブジェクトのエッジオブジェクト */
export type ObjectiveEdge = {
  readonly __typename?: 'ObjectiveEdge';
  /** カーソル */
  readonly cursor: Scalars['ID'];
  /** ノード */
  readonly node?: Maybe<Objective>;
};

/** Objectiveのイベント履歴を表すオブジェクト */
export type ObjectiveEvent = Node & {
  readonly __typename?: 'ObjectiveEvent';
  /** 変更後の値 */
  readonly after?: Maybe<Scalars['String']>;
  /** 変更前の値 */
  readonly before?: Maybe<Scalars['String']>;
  /** 作成日時 */
  readonly createdAt: Scalars['DateTime'];
  /** Objectiveイベント履歴ID */
  readonly id: Scalars['ID'];
  /** Item */
  readonly item: ObjectiveEventItem;
  /** Objective */
  readonly objective?: Maybe<Objective>;
  /** 操作した人 */
  readonly operator: User;
  /** 更新日時 */
  readonly updatedAt: Scalars['DateTime'];
};

/** ページングオブジェクトのエッジのPath */
export type ObjectiveEventConnection = {
  readonly __typename?: 'ObjectiveEventConnection';
  /** edges */
  readonly edges?: Maybe<ReadonlyArray<Maybe<ObjectiveEventEdge>>>;
  /** pageInfo */
  readonly pageInfo: PageInfo;
};

/** ページングオブジェクトのエッジオブジェクト */
export type ObjectiveEventEdge = {
  readonly __typename?: 'ObjectiveEventEdge';
  /** カーソル */
  readonly cursor: Scalars['ID'];
  /** ノード */
  readonly node?: Maybe<ObjectiveEvent>;
};

/** Objectiveのイベント履歴カラム名 */
export const ObjectiveEventItem = {
  /** 自信度 */
  Confidence: 'CONFIDENCE',
  /** 責任者 */
  Owner: 'OWNER',
  /** 進捗率 */
  ProgressRate: 'PROGRESS_RATE',
  /** 自動吸い上げ */
  ProgressRateAggregation: 'PROGRESS_RATE_AGGREGATION',
  /** タイトル */
  Title: 'TITLE',
  /** ウェイト設定 */
  Weight: 'WEIGHT',
  /** ウェイト設定OFF時 */
  WeightOff: 'WEIGHT_OFF'
} as const;

export type ObjectiveEventItem = typeof ObjectiveEventItem[keyof typeof ObjectiveEventItem];
/** Objectiveの進捗率の推移を示すためのtype */
export type ObjectiveProgressRateHistory = ProgressRateHistory & {
  readonly __typename?: 'ObjectiveProgressRateHistory';
  /** 進捗率(変更前) */
  readonly beforeProgressRate: Scalars['Int'];
  /** 作成日時 */
  readonly createdAt: Scalars['DateTime'];
  /** ID */
  readonly id: Scalars['ID'];
  /** Objective */
  readonly objective: Objective;
  /** 進捗率 */
  readonly progressRate: Scalars['Int'];
  /** 変更者 */
  readonly user: User;
};

/** O/KR取得時の有効/無効フィルター */
export const OkrEnableFilter = {
  /** 全て */
  All: 'ALL',
  /** 無効のみ */
  DisableOnly: 'DISABLE_ONLY',
  /** 有効のみ */
  EnableOnly: 'ENABLE_ONLY'
} as const;

export type OkrEnableFilter = typeof OkrEnableFilter[keyof typeof OkrEnableFilter];
/** イベント履歴を表すオブジェクト */
export type OkrEventHistory = Node & {
  readonly __typename?: 'OkrEventHistory';
  /** 変更後の値 */
  readonly after?: Maybe<Scalars['String']>;
  /** 変更前の値 */
  readonly before?: Maybe<Scalars['String']>;
  /** 作成日時 */
  readonly createdAt: Scalars['DateTime'];
  /** イベント履歴ID */
  readonly id: Scalars['ID'];
  /** Objective or KerResult */
  readonly isObjective: Scalars['Boolean'];
  /** Item */
  readonly item: Scalars['String'];
  /** 変更理由 */
  readonly message?: Maybe<Scalars['String']>;
  /** KeyResult or Objective 名称 */
  readonly name: Scalars['String'];
  /** 操作した人(ID) */
  readonly operator: User;
};

/** ページングオブジェクトのエッジのPath */
export type OkrEventHistoryConnection = {
  readonly __typename?: 'OkrEventHistoryConnection';
  /** edges */
  readonly edges?: Maybe<ReadonlyArray<Maybe<OkrEventHistoryEdge>>>;
  /** pageInfo */
  readonly pageInfo: PageInfo;
};

/** ページングオブジェクトのエッジオブジェクト */
export type OkrEventHistoryEdge = {
  readonly __typename?: 'OkrEventHistoryEdge';
  /** カーソル */
  readonly cursor: Scalars['ID'];
  /** ノード */
  readonly node?: Maybe<OkrEventHistory>;
};

export const OkrGlossary = {
  /** Action Plan */
  ActionPlan: 'ACTION_PLAN',
  /** Assignee */
  Assignee: 'ASSIGNEE',
  /** Challenges and obstacles */
  ChallengesAndObstacles: 'CHALLENGES_AND_OBSTACLES',
  /** Check in */
  CheckIn: 'CHECK_IN',
  /** Confidence */
  Confidence: 'CONFIDENCE',
  /** Contributors */
  Contributors: 'CONTRIBUTORS',
  /** Key Result */
  KeyResult: 'KEY_RESULT',
  /** KR */
  Kr: 'KR',
  /** Objective */
  Objective: 'OBJECTIVE',
  /** OKR */
  Okr: 'OKR',
  /** OKR meeting */
  OkrMeeting: 'OKR_MEETING',
  /** 1on1 */
  OneOnOne: 'ONE_ON_ONE',
  /** Other */
  Other: 'OTHER',
  /** Priority item */
  PriorityItem: 'PRIORITY_ITEM',
  /** Win-sessions */
  WinSessions: 'WIN_SESSIONS'
} as const;

export type OkrGlossary = typeof OkrGlossary[keyof typeof OkrGlossary];
/** OKR Nodeに付与できるラベルを示すオブジェクト */
export type OkrLabel = {
  readonly __typename?: 'OkrLabel';
  /** ラベル色 */
  readonly color: Scalars['String'];
  /** 作成日時 */
  readonly createdAt: Scalars['DateTime'];
  /** ラベルID */
  readonly id: Scalars['ID'];
  /** ラベル名 */
  readonly name: Scalars['String'];
  /** OKR Node */
  readonly okrNodes: ReadonlyArray<OkrNode>;
  /** OKR Term */
  readonly okrTerm: OkrTerm;
  /** 更新日時 */
  readonly updatedAt: Scalars['DateTime'];
};

/** OkrLabelのソート条件 */
export type OkrLabelSort = {
  /** ソートキー */
  readonly field: OkrLabelSortField;
};

/** OkrLabelのソートキー */
export const OkrLabelSortField = {
  /** ID */
  Id: 'ID'
} as const;

export type OkrLabelSortField = typeof OkrLabelSortField[keyof typeof OkrLabelSortField];
/** OKRをノードで表すオブジェクト */
export type OkrNode = Node & {
  readonly __typename?: 'OkrNode';
  /** ノード配下のアクションプラン */
  readonly actionPlans: ReadonlyArray<ActionPlan>;
  /** ChatworkApiToken */
  readonly chatworkApiToken?: Maybe<Scalars['String']>;
  /** Chatwork連携済みか(ChatworkApiTokenとRoomIdの有無で連携済みか判断できるので削除予定) */
  readonly chatworkIntegrated: Scalars['Boolean'];
  /** ChatworkRoomId */
  readonly chatworkRoomId?: Maybe<Scalars['String']>;
  /** チェックインサマリー一覧 */
  readonly checkinSummaries: CheckinSummaryConnection;
  /** 子のノードID */
  readonly childNodeIds: ReadonlyArray<Scalars['ID']>;
  /** 作成日時 */
  readonly createdAt: Scalars['DateTime'];
  /** チェックイン中のチェックインサマリー */
  readonly currentCheckinSummary?: Maybe<CheckinSummary>;
  /** ノードの深さ */
  readonly depth: Scalars['Int'];
  /** 表示順序 */
  readonly displayOrder: Scalars['Int'];
  /** イベント履歴 */
  readonly eventHistories?: Maybe<OkrEventHistoryConnection>;
  /** ノードにアタッチされているグループ */
  readonly groups: ReadonlyArray<Group>;
  /** ノードID */
  readonly id: Scalars['ID'];
  /** ノード内のKey Result */
  readonly keyResults: ReadonlyArray<KeyResult>;
  /** ラベル */
  readonly labels: ReadonlyArray<OkrLabel>;
  /** ノード配下のノート */
  readonly notes: ReadonlyArray<Note>;
  /** ノード内のObjective */
  readonly objective: Objective;
  /** 親のノード */
  readonly parentNode?: Maybe<OkrNode>;
  /** Slack連携済みか(slackIntegratedChannelListの有無で連携済みか判断できるので削除予定) */
  readonly slackIntegrated: Scalars['Boolean'];
  /** Slackの連携先チャンネルリスト */
  readonly slackIntegratedChannelList: ReadonlyArray<SlackChannel>;
  /** Teams連携済みか(teamsWebhookUrlの有無で連携済みか判断できるので削除予定) */
  readonly teamsIntegrated: Scalars['Boolean'];
  /** TeamsWebhookUrl */
  readonly teamsWebhookUrl?: Maybe<Scalars['String']>;
  /** 所属しているOKR期間 */
  readonly term: OkrTerm;
  /** 更新日時 */
  readonly updatedAt: Scalars['DateTime'];
};


/** OKRをノードで表すオブジェクト */
export type OkrNodeCheckinSummariesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** OKRをノードで表すオブジェクト */
export type OkrNodeEventHistoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

/** ページングオブジェクトのエッジのPath */
export type OkrNodeConnection = {
  readonly __typename?: 'OkrNodeConnection';
  /** edges */
  readonly edges?: Maybe<ReadonlyArray<Maybe<OkrNodeEdge>>>;
  /** pageInfo */
  readonly pageInfo: PageInfo;
};

/** ページングオブジェクトのエッジオブジェクト */
export type OkrNodeEdge = {
  readonly __typename?: 'OkrNodeEdge';
  /** カーソル */
  readonly cursor: Scalars['ID'];
  /** ノード */
  readonly node?: Maybe<OkrNode>;
};

/** OKRノードに紐づくオブジェクトを表すinterface */
export type OkrNodeItem = {
  /** 添付ファイル */
  readonly attachments: ReadonlyArray<File>;
  /** 自信度 */
  readonly confidences: ReadonlyArray<Confidence>;
  /** 作成日時 */
  readonly createdAt: Scalars['DateTime'];
  /** 説明 */
  readonly description?: Maybe<Document>;
  /** ID */
  readonly id: Scalars['ID'];
  /** 無効化状態 */
  readonly isDisabled: Scalars['Boolean'];
  /** 名前 */
  readonly name?: Maybe<Scalars['String']>;
  /** 所属しているNode */
  readonly node: OkrNode;
  /** 投稿されたノート */
  readonly notes: ReadonlyArray<Note>;
  /** 進捗率 */
  readonly progressRate?: Maybe<Scalars['Int']>;
  /** 進捗率のトレンド */
  readonly progressRateTrend: Scalars['Int'];
  /** 進捗率の最終更新日時 */
  readonly progressRateUpdatedAt?: Maybe<Scalars['DateTime']>;
  /** 結果 */
  readonly result?: Maybe<Document>;
  /** 更新日時 */
  readonly updatedAt: Scalars['DateTime'];
};


/** OKRノードに紐づくオブジェクトを表すinterface */
export type OkrNodeItemProgressRateTrendArgs = {
  days: Scalars['Int'];
};

/** OKR期間を表すオブジェクト */
export type OkrTerm = {
  readonly __typename?: 'OkrTerm';
  /** 作成日時 */
  readonly createdAt: Scalars['DateTime'];
  /** 期間終了日 */
  readonly endDate: Scalars['DateTime'];
  /** OKR期間ID */
  readonly id: Scalars['ID'];
  /** 期間名(3Qとか4Qとかを設定する) */
  readonly name: Scalars['String'];
  /** 期間に紐づくNode */
  readonly nodes: ReadonlyArray<OkrNode>;
  /** 企業情報 */
  readonly organization: Organization;
  /** 最優先 */
  readonly primary: Scalars['Boolean'];
  /** 完了日数率 */
  readonly progressRate: Scalars['Int'];
  /** 期間開始日 */
  readonly startDate: Scalars['DateTime'];
  /** 更新日時 */
  readonly updatedAt: Scalars['DateTime'];
  /** マップの状態のブックマーク */
  readonly userBookmarks: ReadonlyArray<UserBookmark>;
};


/** OKR期間を表すオブジェクト */
export type OkrTermNodesArgs = {
  trendDays?: InputMaybe<Scalars['Int']>;
};

/** ソートの並び順を指定する定数 */
export const OrderDirection = {
  Asc: 'ASC',
  Desc: 'DESC'
} as const;

export type OrderDirection = typeof OrderDirection[keyof typeof OrderDirection];
/** 企業アカウントを表すオブジェクト */
export type Organization = {
  readonly __typename?: 'Organization';
  /** Slackでの一括更新通知 */
  readonly bulkUpdateNotificationEnabled: Scalars['Boolean'];
  /** 作成日時 */
  readonly createdAt: Scalars['DateTime'];
  /** 無効化ユーザ */
  readonly disableUsers: ReadonlyArray<User>;
  /** 企業の全グループ */
  readonly groups: ReadonlyArray<Group>;
  /** 企業ID */
  readonly id: Scalars['ID'];
  /** 招待中ユーザ */
  readonly invitingUsers: ReadonlyArray<User>;
  /** ログインIPアドレスのホワイトリスト */
  readonly ipWhitelist: ReadonlyArray<Scalars['String']>;
  /** 無効化状態 */
  readonly isDisabled: Scalars['Boolean'];
  /** SAML有効有無 */
  readonly isSamlEnabled: Scalars['Boolean'];
  /** トライアルアカウントか否か */
  readonly isTrialAccount: Scalars['Boolean'];
  /** ロゴ */
  readonly logo?: Maybe<File>;
  /** 企業名 */
  readonly name: Scalars['String'];
  /** OAuthドメインのホワイトリスト */
  readonly oauthWhitelist: ReadonlyArray<Scalars['String']>;
  /** 企業の全OKR期間 */
  readonly okrTerms: ReadonlyArray<OkrTerm>;
  /** 1on1を公開するか否か */
  readonly oneOnOneEnabled: Scalars['Boolean'];
  /** セルフサーブの企業アカウントでなければnull、セルフサーブであればセルフサーブの状態を返却する */
  readonly selfServe?: Maybe<SelfServe>;
  /** Slackアクセストークン */
  readonly slackAccessToken?: Maybe<Scalars['String']>;
  /** Slack連携ステータス */
  readonly slackIntegrationEnabled: Scalars['Boolean'];
  /** SmartHR連携ステータス */
  readonly smartHRIntegrationEnabled: Scalars['Boolean'];
  /** 更新日時 */
  readonly updatedAt: Scalars['DateTime'];
  /** 企業の有効なユーザ */
  readonly users: ReadonlyArray<User>;
};

export type OverKeyResultProgressRate = RecommendAgenda & {
  readonly __typename?: 'OverKeyResultProgressRate';
  /** id */
  readonly id: Scalars['ID'];
  /** KeyResult */
  readonly keyResult: KeyResult;
  /** 進捗率の閾値 */
  readonly over: Scalars['Int'];
  /** おすすめアジェンダの種別 */
  readonly type: RecommendAgendaType;
};

/** ページングオブジェクト */
export type PageInfo = {
  readonly __typename?: 'PageInfo';
  /** このページの最後のカーソル */
  readonly endCursor?: Maybe<Scalars['String']>;
  /** 次のページがあるかどうか */
  readonly hasNextPage: Scalars['Boolean'];
  /** 前のページがあるかどうか */
  readonly hasPreviousPage: Scalars['Boolean'];
  /** このページの最初のカーソル */
  readonly startCursor?: Maybe<Scalars['String']>;
};

/** 個人ノートを表すオブジェクト */
export type PersonalNote = {
  readonly __typename?: 'PersonalNote';
  /** 添付ファイル */
  readonly attachments: ReadonlyArray<File>;
  /** 投稿者 */
  readonly author: User;
  /** 本文 */
  readonly body?: Maybe<Document>;
  /** コメント */
  readonly comments: ReadonlyArray<PersonalNoteComment>;
  /** 作成日時 */
  readonly createdAt: Scalars['DateTime'];
  /** 個人ノートID */
  readonly id: Scalars['ID'];
  /** ラベル */
  readonly labels: ReadonlyArray<NoteLabel>;
  /** 公開範囲 */
  readonly permission: NotePermission;
  /** リアクション */
  readonly reactions: ReadonlyArray<PersonalNoteReaction>;
  /** タイトル */
  readonly title: Scalars['String'];
  /** 更新日時 */
  readonly updatedAt: Scalars['DateTime'];
  /** 更新者 */
  readonly updatedUser: User;
  /** 被作成者 */
  readonly user: User;
};

/** 個人ノート内のコメントを表すオブジェクト */
export type PersonalNoteComment = {
  readonly __typename?: 'PersonalNoteComment';
  /** 添付ファイル */
  readonly attachments: ReadonlyArray<File>;
  /** 投稿者 */
  readonly author: User;
  /** 本文 */
  readonly body: Document;
  /** 作成日時 */
  readonly createdAt: Scalars['DateTime'];
  /** 個人ノートコメントID */
  readonly id: Scalars['ID'];
  /** 個人ノート */
  readonly personalNote: PersonalNote;
  /** リアクション */
  readonly reactions: ReadonlyArray<PersonalNoteCommentReaction>;
  /** 更新日時 */
  readonly updatedAt: Scalars['DateTime'];
};

/** 個人ノートのコメントへのリアクションを表すオブジェクト */
export type PersonalNoteCommentReaction = {
  readonly __typename?: 'PersonalNoteCommentReaction';
  /** 作成日時 */
  readonly createdAt: Scalars['DateTime'];
  /** 絵文字 */
  readonly emoji: Scalars['String'];
  /** コメントリアクションID */
  readonly id: Scalars['ID'];
  /** コメント */
  readonly personalNoteComment: PersonalNoteComment;
  /** 更新日時 */
  readonly updatedAt: Scalars['DateTime'];
  /** ユーザー */
  readonly user: User;
};

/** ページングオブジェクトのエッジのPath */
export type PersonalNoteConnection = {
  readonly __typename?: 'PersonalNoteConnection';
  /** edges */
  readonly edges: ReadonlyArray<PersonalNoteEdge>;
  /** pageInfo */
  readonly pageInfo: PageInfo;
};

/** ページングオブジェクトのエッジオブジェクト */
export type PersonalNoteEdge = {
  readonly __typename?: 'PersonalNoteEdge';
  /** カーソル */
  readonly cursor: Scalars['ID'];
  /** ノード */
  readonly node?: Maybe<PersonalNote>;
};

/** 個人ノートのリアクションを表すオブジェクト */
export type PersonalNoteReaction = {
  readonly __typename?: 'PersonalNoteReaction';
  /** 作成日時 */
  readonly createdAt: Scalars['DateTime'];
  /** 絵文字 */
  readonly emoji: Scalars['String'];
  /** リアクションID */
  readonly id: Scalars['ID'];
  /** 個人ノート */
  readonly personalNote: PersonalNote;
  /** 更新日時 */
  readonly updatedAt: Scalars['DateTime'];
  /** ユーザー */
  readonly user: User;
};

/** KRの進捗率と自信度を更新するためのオブジェクト */
export type ProgressRateAndConfidenceInput = {
  /** 実績値 */
  readonly actualValue?: InputMaybe<Scalars['Float']>;
  /** 自信度 */
  readonly confidenceLevel?: InputMaybe<Scalars['Int']>;
  /** メッセージ */
  readonly message?: InputMaybe<Scalars['String']>;
  /** 更新箇所 */
  readonly screen: KeyResultEventScreen;
};

/** 進捗率の推移を示すためのinterface */
export type ProgressRateHistory = {
  /** 進捗率(変更前) */
  readonly beforeProgressRate: Scalars['Int'];
  /** 作成日時 */
  readonly createdAt: Scalars['DateTime'];
  /** ID */
  readonly id: Scalars['ID'];
  /** 進捗率 */
  readonly progressRate: Scalars['Int'];
  /** ユーザ */
  readonly user: User;
};

/**
 * 進捗率を表すオブジェクト
 * 入力引数用
 */
export type ProgressRateInput = {
  /** 実績値 */
  readonly actualValue?: InputMaybe<Scalars['Float']>;
  /** 初期値 */
  readonly initialValue?: InputMaybe<Scalars['Float']>;
  /** メッセージ */
  readonly message?: InputMaybe<Scalars['String']>;
  /** 更新箇所 */
  readonly screen: KeyResultEventScreen;
  /** 目標値 */
  readonly targetValue?: InputMaybe<Scalars['Float']>;
  /** 目標値の単位 */
  readonly unit?: InputMaybe<Scalars['String']>;
};

/**
 * クエリ一覧
 * オブジェクトへの全てのエントリポイントを定義しています
 */
export type Query = {
  readonly __typename?: 'Query';
  /** チェックインサマリー */
  readonly checkinSummary: CheckinSummary;
  /** 指定IDのOKRノードの1階層下の子OKRノードを返却する */
  readonly childrenOkrNodes: ReadonlyArray<OkrNode>;
  /** OKR用語集を全て取得する */
  readonly customOkrGlossaries: ReadonlyArray<CustomOkrGlossary>;
  /** 指定IDのノートを取得する */
  readonly findActionPlanById: ActionPlan;
  /** 指定IDのノートを全て取得する */
  readonly findActionPlanByIds: ReadonlyArray<ActionPlan>;
  /** 指定のKeyResultIDsのアクティビティを取得する */
  readonly findActivitiesByKeyResultIds: ActivityConnection;
  /** 指定のUserIDに関連するアクティビティを取得する */
  readonly findActivitiesByUserId: ActivityConnection;
  /** チェックインサマリー一覧 */
  readonly findCheckinSummariesByOkrNodeId: CheckinSummaryConnection;
  /** 指定IDのグループを取得する */
  readonly findGroupById: Group;
  /**
   * Key Resultのイベント履歴を取得する
   * @deprecated 右ドロワー廃止につき削除予定
   */
  readonly findKeyResultEventsById?: Maybe<KeyResultEventConnection>;
  /** 特定のOKR期間内のKey Resultを全て取得する */
  readonly findKeyResultsByTerm?: Maybe<KeyResultConnection>;
  /** 指定IDのノートを取得する */
  readonly findNoteById: Note;
  /** 指定IDのノートを全て取得する */
  readonly findNoteByIds: ReadonlyArray<Note>;
  /** ノートのラベルを取得する */
  readonly findNoteLabelByID: NoteLabel;
  /** ノートを取得する */
  readonly findNotes: NoteConnection;
  /** 指定UserID、okrTermIdのノートを全て取得する */
  readonly findNotesByUserId: ReadonlyArray<NoteOrPersonalNote>;
  /**
   * Objectiveのイベント履歴の取得する
   * @deprecated 右ドロワー廃止につき削除予定
   */
  readonly findObjectiveEventsById?: Maybe<ObjectiveEventConnection>;
  /** Objectiveに紐づくKeyResultのイベント履歴の取得する（進捗・自信度タブの初期表示用） */
  readonly findObjectiveKeyResultsEventsById: ReadonlyArray<KeyResultEventConnections>;
  /** 特定のOKR期間内のObjectiveを全て取得する */
  readonly findObjectivesByTerm?: Maybe<ObjectiveConnection>;
  /** OKR Node のラベルを取得する */
  readonly findOkrLabelById: OkrLabel;
  /** 指定IDの個人ノートを取得する */
  readonly findPersonalNoteById: PersonalNote;
  /** 指定IDの個人ノートを全て取得する */
  readonly findPersonalNoteByIds: ReadonlyArray<PersonalNote>;
  /** smartHR従業員の取得 */
  readonly findSmartHREmployees?: Maybe<SmartHrEmployeeConnection>;
  /** 指定IDのユーザを取得する */
  readonly findUserById?: Maybe<User>;
  /** 指定IDのノートの利用可能ユーザを取得する */
  readonly findUserNotePermission: ReadonlyArray<Maybe<UserNotePermission>>;
  /** 指定IDの個人ノートの利用可能ユーザを取得する */
  readonly findUserPersonalNotePermission: ReadonlyArray<Maybe<UserPersonalNotePermission>>;
  /** 指定IDの固定アジェンダを取得 */
  readonly fixedAgenda: FixedAgenda;
  /** 固定アジェンダ一覧を取得 */
  readonly fixedAgendas: ReadonlyArray<FixedAgenda>;
  /**
   * ページネーション形式で固定アジェンダ一覧を取得
   * 返却順序はindexに準じます
   */
  readonly fixedAgendasPaginate?: Maybe<FixedAgendaConnection>;
  /** グループを取得する */
  readonly groups: ReadonlyArray<Group>;
  /** ノートラベルの利用有無を取得する */
  readonly isNoteLabelUsed: Scalars['Boolean'];
  /** OKR Node のラベルの利用有無を取得する */
  readonly isOkrLabelUsed: Scalars['Boolean'];
  /** KeyResultを返却する */
  readonly keyResult: KeyResult;
  /** 条件に一致するOkrNodesの子となるKeyResultの進捗率履歴を取得する */
  readonly keyResultProgressRateHistories: KeyResultProgressRateHistoryConnection;
  /** ノートのラベル一覧を取得する */
  readonly noteLabels: ReadonlyArray<NoteLabel>;
  /** 通知の取得 */
  readonly notifications: NotificationConnection;
  /** Objectiveを返却する */
  readonly objective: Objective;
  /** OKR Node のラベル一覧を取得する */
  readonly okrLabels: ReadonlyArray<OkrLabel>;
  /** OKRノードを返却する */
  readonly okrNode: OkrNode;
  /** 特定のOKR期間内のOKRノードのリストの取得 */
  readonly okrNodes: ReadonlyArray<OkrNode>;
  /** 特定のOKR期間内のOKRノードのリストをページネーションして取得 */
  readonly okrNodesWithPagination?: Maybe<OkrNodeConnection>;
  /** OKR期間の取得 */
  readonly okrTerms: ReadonlyArray<OkrTerm>;
  /** 自身が所属するOrganizationを取得する */
  readonly organization: Organization;
  /** OKRノードを起点に親から頂点までのノードを返却 */
  readonly parentOkrNodes: ReadonlyArray<OkrNode>;
  /** 指定IDのOKRノードの部分木を返却する */
  readonly partialTree: ReadonlyArray<OkrNode>;
  /** Slackチャンネルの一覧を取得する */
  readonly readSlackChannelList: ReadonlyArray<SlackChannel>;
  /** SAMLの設定情報を取得する */
  readonly samlSetting: SamlSetting;
  /** 名前でKeyResultを検索する(有効なKeyResultのみ返す) */
  readonly searchKeyResultsByName: ReadonlyArray<KeyResult>;
  /** タイトルでノートを検索する */
  readonly searchNotesByTitle: ReadonlyArray<Note>;
  /** 名前でObjectiveを検索する(有効なObjectiveのみ返す) */
  readonly searchObjectivesByName: ReadonlyArray<Objective>;
  /** 特定のOKR期間内のOKRノードをobjective名で検索する */
  readonly searchOkrNodesByObjectiveName: ReadonlyArray<OkrNode>;
  /** タイトルで個人ノートを検索する */
  readonly searchPersonalNotesByTitle: ReadonlyArray<PersonalNote>;
  /** 未読通知件数 */
  readonly unreadNotificationCount: Scalars['Int'];
  /** 自身のUser情報を取得する */
  readonly user: User;
  /**
   * 1on1でのユーザー自由記述部分のアジェンダの一覧を取得
   * 返却順序はindexに準じます
   */
  readonly userAgendas: ReadonlyArray<Maybe<UserAgenda>>;
  /** 指定IDの1on1を取得 */
  readonly userOneOnOne: UserOneOnOne;
  /** 指定IDの1on1詳細情報を取得 */
  readonly userOneOnOneMeeting: UserOneOnOneMeeting;
  /** 指定した1on1のIDの1on1詳細情報の一覧を取得 */
  readonly userOneOnOneMeetings: ReadonlyArray<UserOneOnOneMeeting>;
  /** ユーザーの1on1一覧を取得 */
  readonly userOneOnOnes: ReadonlyArray<UserOneOnOne>;
  /** User一覧を取得する */
  readonly users: ReadonlyArray<User>;
};


/**
 * クエリ一覧
 * オブジェクトへの全てのエントリポイントを定義しています
 */
export type QueryCheckinSummaryArgs = {
  checkinSummaryId: Scalars['ID'];
};


/**
 * クエリ一覧
 * オブジェクトへの全てのエントリポイントを定義しています
 */
export type QueryChildrenOkrNodesArgs = {
  okrNodeId: Scalars['ID'];
};


/**
 * クエリ一覧
 * オブジェクトへの全てのエントリポイントを定義しています
 */
export type QueryFindActionPlanByIdArgs = {
  id: Scalars['ID'];
};


/**
 * クエリ一覧
 * オブジェクトへの全てのエントリポイントを定義しています
 */
export type QueryFindActionPlanByIdsArgs = {
  ids: ReadonlyArray<Scalars['ID']>;
};


/**
 * クエリ一覧
 * オブジェクトへの全てのエントリポイントを定義しています
 */
export type QueryFindActivitiesByKeyResultIdsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  keyResultIds: ReadonlyArray<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
};


/**
 * クエリ一覧
 * オブジェクトへの全てのエントリポイントを定義しています
 */
export type QueryFindActivitiesByUserIdArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  okrTermId: Scalars['ID'];
  resourceType: ActivityResourceType;
  userId: Scalars['ID'];
};


/**
 * クエリ一覧
 * オブジェクトへの全てのエントリポイントを定義しています
 */
export type QueryFindCheckinSummariesByOkrNodeIdArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  okrNodeId: Scalars['ID'];
};


/**
 * クエリ一覧
 * オブジェクトへの全てのエントリポイントを定義しています
 */
export type QueryFindGroupByIdArgs = {
  id: Scalars['ID'];
};


/**
 * クエリ一覧
 * オブジェクトへの全てのエントリポイントを定義しています
 */
export type QueryFindKeyResultEventsByIdArgs = {
  connection: Connection;
  duration?: InputMaybe<Duration>;
  id: Scalars['ID'];
  itemTypes?: InputMaybe<ReadonlyArray<KeyResultEventItem>>;
};


/**
 * クエリ一覧
 * オブジェクトへの全てのエントリポイントを定義しています
 */
export type QueryFindKeyResultsByTermArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  okrTermId: Scalars['ID'];
};


/**
 * クエリ一覧
 * オブジェクトへの全てのエントリポイントを定義しています
 */
export type QueryFindNoteByIdArgs = {
  id: Scalars['ID'];
};


/**
 * クエリ一覧
 * オブジェクトへの全てのエントリポイントを定義しています
 */
export type QueryFindNoteByIdsArgs = {
  ids: ReadonlyArray<Scalars['ID']>;
};


/**
 * クエリ一覧
 * オブジェクトへの全てのエントリポイントを定義しています
 */
export type QueryFindNoteLabelByIdArgs = {
  id: Scalars['ID'];
};


/**
 * クエリ一覧
 * オブジェクトへの全てのエントリポイントを定義しています
 */
export type QueryFindNotesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/**
 * クエリ一覧
 * オブジェクトへの全てのエントリポイントを定義しています
 */
export type QueryFindNotesByUserIdArgs = {
  okrTermId: Scalars['ID'];
  userId: Scalars['ID'];
};


/**
 * クエリ一覧
 * オブジェクトへの全てのエントリポイントを定義しています
 */
export type QueryFindObjectiveEventsByIdArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  last?: InputMaybe<Scalars['Int']>;
};


/**
 * クエリ一覧
 * オブジェクトへの全てのエントリポイントを定義しています
 */
export type QueryFindObjectiveKeyResultsEventsByIdArgs = {
  duration: Duration;
  itemTypes?: InputMaybe<ReadonlyArray<KeyResultEventItem>>;
  objectiveId: Scalars['ID'];
};


/**
 * クエリ一覧
 * オブジェクトへの全てのエントリポイントを定義しています
 */
export type QueryFindObjectivesByTermArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  okrTermId: Scalars['ID'];
};


/**
 * クエリ一覧
 * オブジェクトへの全てのエントリポイントを定義しています
 */
export type QueryFindOkrLabelByIdArgs = {
  id: Scalars['ID'];
};


/**
 * クエリ一覧
 * オブジェクトへの全てのエントリポイントを定義しています
 */
export type QueryFindPersonalNoteByIdArgs = {
  id: Scalars['ID'];
};


/**
 * クエリ一覧
 * オブジェクトへの全てのエントリポイントを定義しています
 */
export type QueryFindPersonalNoteByIdsArgs = {
  ids: ReadonlyArray<Scalars['ID']>;
};


/**
 * クエリ一覧
 * オブジェクトへの全てのエントリポイントを定義しています
 */
export type QueryFindSmartHrEmployeesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  searchQuery?: InputMaybe<Scalars['String']>;
  synced?: InputMaybe<Scalars['Boolean']>;
};


/**
 * クエリ一覧
 * オブジェクトへの全てのエントリポイントを定義しています
 */
export type QueryFindUserByIdArgs = {
  id: Scalars['ID'];
};


/**
 * クエリ一覧
 * オブジェクトへの全てのエントリポイントを定義しています
 */
export type QueryFindUserNotePermissionArgs = {
  id: Scalars['ID'];
};


/**
 * クエリ一覧
 * オブジェクトへの全てのエントリポイントを定義しています
 */
export type QueryFindUserPersonalNotePermissionArgs = {
  id: Scalars['ID'];
};


/**
 * クエリ一覧
 * オブジェクトへの全てのエントリポイントを定義しています
 */
export type QueryFixedAgendaArgs = {
  id: Scalars['ID'];
};


/**
 * クエリ一覧
 * オブジェクトへの全てのエントリポイントを定義しています
 */
export type QueryFixedAgendasPaginateArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<FixedAgendaOrder>;
};


/**
 * クエリ一覧
 * オブジェクトへの全てのエントリポイントを定義しています
 */
export type QueryIsNoteLabelUsedArgs = {
  noteLabelId: Scalars['ID'];
};


/**
 * クエリ一覧
 * オブジェクトへの全てのエントリポイントを定義しています
 */
export type QueryIsOkrLabelUsedArgs = {
  okrLabelId: Scalars['ID'];
};


/**
 * クエリ一覧
 * オブジェクトへの全てのエントリポイントを定義しています
 */
export type QueryKeyResultArgs = {
  keyResultId?: InputMaybe<Scalars['ID']>;
};


/**
 * クエリ一覧
 * オブジェクトへの全てのエントリポイントを定義しています
 */
export type QueryKeyResultProgressRateHistoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  groupIds: ReadonlyArray<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  okrNodeIds: ReadonlyArray<Scalars['ID']>;
  termId: Scalars['ID'];
  userIds: ReadonlyArray<Scalars['ID']>;
};


/**
 * クエリ一覧
 * オブジェクトへの全てのエントリポイントを定義しています
 */
export type QueryNoteLabelsArgs = {
  sort: NoteLabelSort;
};


/**
 * クエリ一覧
 * オブジェクトへの全てのエントリポイントを定義しています
 */
export type QueryNotificationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  unreadOnly: Scalars['Boolean'];
};


/**
 * クエリ一覧
 * オブジェクトへの全てのエントリポイントを定義しています
 */
export type QueryObjectiveArgs = {
  objectiveId?: InputMaybe<Scalars['ID']>;
};


/**
 * クエリ一覧
 * オブジェクトへの全てのエントリポイントを定義しています
 */
export type QueryOkrLabelsArgs = {
  sort: OkrLabelSort;
};


/**
 * クエリ一覧
 * オブジェクトへの全てのエントリポイントを定義しています
 */
export type QueryOkrNodeArgs = {
  keyResultId?: InputMaybe<Scalars['ID']>;
  objectiveId?: InputMaybe<Scalars['ID']>;
  okrNodeId?: InputMaybe<Scalars['ID']>;
};


/**
 * クエリ一覧
 * オブジェクトへの全てのエントリポイントを定義しています
 */
export type QueryOkrNodesArgs = {
  okrTermId: Scalars['ID'];
  trendDays?: InputMaybe<Scalars['Int']>;
};


/**
 * クエリ一覧
 * オブジェクトへの全てのエントリポイントを定義しています
 */
export type QueryOkrNodesWithPaginationArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  okrTermId: Scalars['ID'];
};


/**
 * クエリ一覧
 * オブジェクトへの全てのエントリポイントを定義しています
 */
export type QueryParentOkrNodesArgs = {
  okrNodeId: Scalars['ID'];
};


/**
 * クエリ一覧
 * オブジェクトへの全てのエントリポイントを定義しています
 */
export type QueryPartialTreeArgs = {
  vertexOkrNodeId: Scalars['ID'];
};


/**
 * クエリ一覧
 * オブジェクトへの全てのエントリポイントを定義しています
 */
export type QuerySearchKeyResultsByNameArgs = {
  name: Scalars['String'];
  okrTermId: Scalars['ID'];
};


/**
 * クエリ一覧
 * オブジェクトへの全てのエントリポイントを定義しています
 */
export type QuerySearchNotesByTitleArgs = {
  title: Scalars['String'];
};


/**
 * クエリ一覧
 * オブジェクトへの全てのエントリポイントを定義しています
 */
export type QuerySearchObjectivesByNameArgs = {
  name: Scalars['String'];
  okrTermId: Scalars['ID'];
};


/**
 * クエリ一覧
 * オブジェクトへの全てのエントリポイントを定義しています
 */
export type QuerySearchOkrNodesByObjectiveNameArgs = {
  name: Scalars['String'];
  okrTermId: Scalars['ID'];
};


/**
 * クエリ一覧
 * オブジェクトへの全てのエントリポイントを定義しています
 */
export type QuerySearchPersonalNotesByTitleArgs = {
  title: Scalars['String'];
};


/**
 * クエリ一覧
 * オブジェクトへの全てのエントリポイントを定義しています
 */
export type QueryUserAgendasArgs = {
  meetingId: Scalars['ID'];
};


/**
 * クエリ一覧
 * オブジェクトへの全てのエントリポイントを定義しています
 */
export type QueryUserOneOnOneArgs = {
  id: Scalars['ID'];
};


/**
 * クエリ一覧
 * オブジェクトへの全てのエントリポイントを定義しています
 */
export type QueryUserOneOnOneMeetingArgs = {
  id: Scalars['ID'];
};


/**
 * クエリ一覧
 * オブジェクトへの全てのエントリポイントを定義しています
 */
export type QueryUserOneOnOneMeetingsArgs = {
  orderBy?: InputMaybe<UserOneOnOneMeetingOrder>;
  userOneOnOneId: Scalars['ID'];
  where?: InputMaybe<UserOneOnOneMeetingWhereInput>;
};


/**
 * クエリ一覧
 * オブジェクトへの全てのエントリポイントを定義しています
 */
export type QueryUserOneOnOnesArgs = {
  orderBy?: InputMaybe<UserOneOnOneOrder>;
};

export type RecommendAgenda = {
  readonly id: Scalars['ID'];
  readonly type: RecommendAgendaType;
};

/** おすすめアジェンダの種別 */
export const RecommendAgendaType = {
  /** 次のOKR期間の目標についての会話を促すおすすめアジェンダ */
  ConversationNextOkrTerm: 'CONVERSATION_NEXT_OKR_TERM',
  /** 事業・個人目標の確認を促すおすすめアジェンダ */
  FollowGoal: 'FOLLOW_GOAL',
  /** 期末の振り返りを促すおすすめアジェンダ */
  LookBackOkrTerm: 'LOOK_BACK_OKR_TERM',
  /** 責任者のKRの自信度が下がっていることを示唆するおすすめアジェンダ */
  LowKeyResultConfidence: 'LOW_KEY_RESULT_CONFIDENCE',
  /** 責任者のKRの進捗更新が一度もないことを示唆するおすすめアジェンダ */
  NeverProgressKeyResult: 'NEVER_PROGRESS_KEY_RESULT',
  /** 責任者のKRの進捗がないことを示唆するおすすめアジェンダ */
  NoProgressKeyResult: 'NO_PROGRESS_KEY_RESULT',
  /** 責任者のKRの進捗率が基準値を超えたことを示唆するおすすめアジェンダ */
  OverKeyResultProgressRate: 'OVER_KEY_RESULT_PROGRESS_RATE',
  /** 責任者のKRの進捗率が大幅に更新をされたことを示唆するおすすめアジェンダ */
  RiseKeyResultProgressRate: 'RISE_KEY_RESULT_PROGRESS_RATE'
} as const;

export type RecommendAgendaType = typeof RecommendAgendaType[keyof typeof RecommendAgendaType];
export type RiseKeyResultProgressRate = RecommendAgenda & {
  readonly __typename?: 'RiseKeyResultProgressRate';
  /** id */
  readonly id: Scalars['ID'];
  /** KeyResult */
  readonly keyResult: KeyResult;
  /** 上昇したKeyResultの進捗率 */
  readonly progressRateDelta: Scalars['Int'];
  /** おすすめアジェンダの種別 */
  readonly type: RecommendAgendaType;
};

/** 権限ロール */
export const Role = {
  /** グループ管理者ロール */
  GroupAdmin: 'GROUP_ADMIN',
  /** システム管理者ロール */
  SystemAdmin: 'SYSTEM_ADMIN'
} as const;

export type Role = typeof Role[keyof typeof Role];
/** SAMLの設定 */
export type SamlSetting = {
  readonly __typename?: 'SamlSetting';
  /** IdP情報 */
  readonly identityProvider?: Maybe<IdentityProvider>;
  /** SP情報 */
  readonly serviceProvider: ServiceProvider;
  /** metadata.xml 最終更新日時 */
  readonly updatedAt?: Maybe<Scalars['DateTime']>;
};

export type Schedule = {
  readonly endTime: Scalars['DateTime'];
  readonly startTime: Scalars['DateTime'];
};

/** セルフサーブの企業アカウントの状態を表すObject */
export type SelfServe = {
  readonly __typename?: 'SelfServe';
  /** 残りの追加可能なユーザー数 */
  readonly remainingLicenses?: Maybe<Scalars['Int']>;
  /** Stripe上の顧客ID */
  readonly stripeCustomerID?: Maybe<Scalars['String']>;
  /** サブスクがいつ期限切れになったか */
  readonly stripeExpiredAt?: Maybe<Scalars['DateTime']>;
  /** トライアルの有効期限 */
  readonly trialExpiresAt: Scalars['DateTime'];
};

/** ServiceProvider情報 */
export type ServiceProvider = {
  readonly __typename?: 'ServiceProvider';
  /** IdPからのレスポンスアサーション用URL */
  readonly acsUrl: Scalars['String'];
  /** 上記のバリデータ */
  readonly acsUrlValidator: Scalars['String'];
  /** オーディエンス情報提示用URL */
  readonly audienceUrl: Scalars['String'];
  /** IdPからのシングルログアウト要求を受け付けるURL */
  readonly singleLogoutUrl: Scalars['String'];
};

/** Slackチャンネル */
export type SlackChannel = {
  readonly __typename?: 'SlackChannel';
  /** ID */
  readonly id: Scalars['ID'];
  /** name */
  readonly name: Scalars['String'];
  /** 非公開チャンネルか */
  readonly private: Scalars['Boolean'];
};

export type SmartHrEmployee = {
  readonly __typename?: 'SmartHREmployee';
  /** メールアドレス */
  readonly email: Scalars['String'];
  /** 雇用形態 */
  readonly employmentType: Scalars['String'];
  /** 連携の有効化 */
  readonly enable: Scalars['Boolean'];
  /** 名 */
  readonly firstName: Scalars['String'];
  /** ノードID */
  readonly id: Scalars['ID'];
  /** 姓 */
  readonly lastName: Scalars['String'];
  /** 利用開始日 */
  readonly startDate?: Maybe<Scalars['DateTime']>;
};

/** ページングオブジェクトのEdgeのPath */
export type SmartHrEmployeeConnection = {
  readonly __typename?: 'SmartHREmployeeConnection';
  /** edges */
  readonly edges?: Maybe<ReadonlyArray<Maybe<SmartHrEmployeeEdge>>>;
  /** pageInfo */
  readonly pageInfo: PageInfo;
  /** 検索条件に対するトータル数 */
  readonly totalCount: Scalars['Int'];
};

/** ページングオブジェクトのエッジオブジェクト */
export type SmartHrEmployeeEdge = {
  readonly __typename?: 'SmartHREmployeeEdge';
  /** カーソル */
  readonly cursor: Scalars['ID'];
  /** ノード */
  readonly node?: Maybe<SmartHrEmployee>;
};

/** ソート方向 */
export const SortDirection = {
  /** 昇順 */
  Asc: 'ASC',
  /** 降順 */
  Desc: 'DESC'
} as const;

export type SortDirection = typeof SortDirection[keyof typeof SortDirection];
/** moon, roof */
export const TargetSettingType = {
  /** ムーン */
  Moon: 'MOON',
  /** 未設定 */
  None: 'NONE',
  /** ルーフ */
  Roof: 'ROOF'
} as const;

export type TargetSettingType = typeof TargetSettingType[keyof typeof TargetSettingType];
/** 固定アジェンダ表示順更新用入力値 */
export type UpdateFixedAgendaIndexInput = {
  /** 表示順 */
  readonly index: Scalars['Int'];
};

/** 固定アジェンダ更新用入力値 */
export type UpdateFixedAgendaInput = {
  /** 本文 */
  readonly body: Scalars['String'];
  /** プレーンテキスト */
  readonly plainText: Scalars['String'];
  /** ステータス */
  readonly status: FixedAgendaStatus;
  /** タイトル */
  readonly title: Scalars['String'];
};

/** 固定アジェンダステータス更新用入力値 */
export type UpdateFixedAgendaStatusInput = {
  /** ステータス */
  readonly status: FixedAgendaStatus;
};

/**
 * KR更新用オブジェクト
 * 入力引数用
 */
export type UpdateKeyResultInfoInput = {
  /** 実績値 */
  readonly actualValue?: InputMaybe<Scalars['Float']>;
  /** 添付ファイル */
  readonly attachments: ReadonlyArray<FileInput>;
  /** 自信度 */
  readonly confidence?: InputMaybe<Scalars['Int']>;
  /** 説明 */
  readonly description?: InputMaybe<DocumentInput>;
  /** 初期値 */
  readonly initialValue?: InputMaybe<Scalars['Float']>;
  /**
   * 説明文のトラッキングフラグ (デフォルトテキストから変更がある場合true)
   * TODO: 本当に必要か確認
   */
  readonly isDescriptionChanged?: InputMaybe<Scalars['Boolean']>;
  /** keyResult id */
  readonly keyResultId: Scalars['ID'];
  /** コントリビューターID */
  readonly memberIds: ReadonlyArray<Scalars['String']>;
  /** 更新メモ */
  readonly message?: InputMaybe<Scalars['String']>;
  /** 名前 */
  readonly name: Scalars['String'];
  /** 責任者ID */
  readonly ownerId: Scalars['String'];
  /** moon,roofの設定 */
  readonly targetSetting: TargetSettingType;
  /** 目標値 */
  readonly targetValue?: InputMaybe<Scalars['Float']>;
  /** 目標値の単位 */
  readonly unit?: InputMaybe<Scalars['String']>;
};

/** mutation updateNoteにて変更するノートにアタッチするラベル */
export type UpdateNoteLabelIds = {
  readonly ids: ReadonlyArray<Scalars['ID']>;
};

/** mutation updateNoteにて変更する非公開ノートの公開ユーザー */
export type UpdateNoteUserIds = {
  readonly ids: ReadonlyArray<Scalars['ID']>;
};

/**
 * objectiveのdescriptionを更新を表すオブジェクト
 * 入力引数用
 */
export type UpdateObjectiveDescriptionInput = {
  /** 添付ファイル */
  readonly attachments: ReadonlyArray<FileInput>;
  /** 説明(更新前) */
  readonly beforeDescription: DocumentInput;
  /** 説明 */
  readonly description: DocumentInput;
  /** Objective ID */
  readonly id: Scalars['ID'];
  /** 説明文のトラッキングフラグ (デフォルトテキストから変更がある場合true) */
  readonly isDescriptionChanged?: InputMaybe<Scalars['Boolean']>;
};

/** OKR Nodeに関する情報の更新を表すオブジェクト */
export type UpdateOkrNodeInput = {
  readonly attachments?: InputMaybe<ReadonlyArray<FileInput>>;
  readonly before: BeforeUpdateOkrNodeInput;
  /** nodeと連携させるChatworkのapiToken */
  readonly chatworkApiToken?: InputMaybe<Scalars['String']>;
  /** nodeと連携させるChatworkのroomId */
  readonly chatworkRoomId?: InputMaybe<Scalars['String']>;
  readonly description?: InputMaybe<DocumentInput>;
  readonly groupIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly isDescriptionChanged?: InputMaybe<Scalars['Boolean']>;
  readonly name: Scalars['String'];
  readonly objectiveId: Scalars['ID'];
  readonly okrNodeId: Scalars['ID'];
  readonly ownerId: Scalars['ID'];
  readonly parentKeyResultIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly parentOkrNodeId?: InputMaybe<Scalars['ID']>;
  /** nodeと連携させるSlackチャンネルリスト */
  readonly slackChannelIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** nodeと連携させるTeamsのWebhoolUrl */
  readonly teamsWebhookUrl?: InputMaybe<Scalars['String']>;
};

/** 1on1固定アジェンダ用議事録を更新するための入力値 */
export type UpdateOneOnOneMeetingFixedAgendaNoteInput = {
  /** 議事録(エディタのJSON文字列) */
  readonly meetingNote: Scalars['String'];
  /** 議事録(プレーンテキスト) */
  readonly meetingNoteText: Scalars['String'];
};

/** 1on1議事録を更新するための入力値 */
export type UpdateOneOnOneMeetingNoteInput = {
  /** 議事録(エディタのJSON文字列) */
  readonly meetingNote: Scalars['String'];
  /** 議事録(プレーンテキスト) */
  readonly meetingNoteText: Scalars['String'];
};

/** 1on1議事録の個人メモを更新するための入力値 */
export type UpdateOneOnOneMeetingPrivateNoteInput = {
  /** 議事録(エディタのJSON文字列) */
  readonly meetingNote: Scalars['String'];
  /** 議事録(プレーンテキスト) */
  readonly meetingNoteText: Scalars['String'];
};

/** ユーザーアジェンダ表示順更新用入力値 */
export type UpdateUserAgendaIndexInput = {
  /** 表示順 */
  readonly index: Scalars['Int'];
};

/** ユーザーアジェンダ更新用入力値 */
export type UpdateUserAgendaInput = {
  /** タイトル */
  readonly title: Scalars['String'];
};

export type UpsertAndDeleteUserOneOnOneMeetings = {
  /** 1on1の予定 */
  readonly UserOneOnOneMeetingsInput: ReadonlyArray<UpsertUserOneOnOneMeetingsInput>;
  /** 削除対象のUserOneOnOneMeetingのID */
  readonly deleteIDs: ReadonlyArray<Scalars['ID']>;
  /** UserOneOnOneのID */
  readonly id: Scalars['ID'];
  /** 1on1の予定をnotifierサービスに連携するための情報 */
  readonly notifierInput: NotifierOneOnOneInput;
};

export type UpsertUserOneOnOneMeetingsInput = {
  readonly description: Description;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly schedule: Schedule;
};

/** ユーザを表すオブジェクト */
export type User = {
  readonly __typename?: 'User';
  /** システム管理者か否か */
  readonly admin: Scalars['Boolean'];
  /**
   * アサインされているKeyResultの情報
   * @deprecated リソアロ廃止につき削除予定
   */
  readonly assignKeyResults: ReadonlyArray<UserKeyResultInfo>;
  /** アバター */
  readonly avatar?: Maybe<File>;
  /** チェックインの状態 */
  readonly checkinKeyResultsState: CheckinKeyResultsState;
  /** チェックイン可能なKR一覧 */
  readonly checkinableKeyResults: ReadonlyArray<CheckinableKeyResult>;
  /** 招待メール送信日時 */
  readonly confirmationSentAt?: Maybe<Scalars['DateTime']>;
  /** 招待メール承諾日時 */
  readonly confirmedAt?: Maybe<Scalars['DateTime']>;
  /** アカウント作成日時 */
  readonly createdAt: Scalars['DateTime'];
  /** 最終ログイン日時 */
  readonly currentSignInAt?: Maybe<Scalars['DateTime']>;
  /** メールアドレス */
  readonly email: Scalars['String'];
  /** 有効なOAuth連携 */
  readonly enabledOAuth: ReadonlyArray<OAuthProvider>;
  /** 名 */
  readonly firstName: Scalars['String'];
  /** 所属するGroup */
  readonly groups: ReadonlyArray<Group>;
  /** ユーザID */
  readonly id: Scalars['ID'];
  /** チャネルトーク用ユーザーIDのハッシュ値 */
  readonly idHashForChannelTalk: Scalars['String'];
  /** 無効化状態 */
  readonly isDisabled: Scalars['Boolean'];
  /** アカウントロック状態 */
  readonly isLockingAccount: Scalars['Boolean'];
  /** Key Result */
  readonly keyResults: ReadonlyArray<KeyResult>;
  /** 姓 */
  readonly lastName: Scalars['String'];
  /** 最終情報更新日時（進捗・自信度、コメント・議事録を対象） */
  readonly lastUpdatedAt?: Maybe<Scalars['DateTime']>;
  /** 関係者となっているアクションプラン一覧 */
  readonly memberActionPlans: ReadonlyArray<ActionPlan>;
  /** 自身がコントリビューターのKey Result */
  readonly memberKeyResults: ReadonlyArray<KeyResult>;
  /** 利用可能なノート */
  readonly notes: ReadonlyArray<Note>;
  /** 自分のOKR(自分が所蔵しているO/KR)を表すObjectiveの集合 */
  readonly okrs: ReadonlyArray<Objective>;
  /** 所属するOrganization */
  readonly organization: Organization;
  /** 2要素認証の有効化状態 */
  readonly otpRequiredForLogin: Scalars['Boolean'];
  /** オーナーとなっているアクションプラン一覧 */
  readonly ownerActionPlans: ReadonlyArray<ActionPlan>;
  /** 自身がオーナーのKey Result */
  readonly ownerKeyResults: ReadonlyArray<KeyResult>;
  /** 自身がオーナーのObjective */
  readonly ownerObjectives: ReadonlyArray<Objective>;
  /** 利用可能な個人ノート */
  readonly personalNotes: PersonalNoteConnection;
  /** おすすめアジェンダの一覧 */
  readonly recommendAgendas: ReadonlyArray<RecommendAgenda>;
  /** パスワード再設定メール送信日時 */
  readonly resetPasswordSentAt?: Maybe<Scalars['DateTime']>;
  /** SAML認証が有効無効 */
  readonly samlEnabled: Scalars['Boolean'];
  /** 変更予定のメールアドレス */
  readonly unconfirmedEmail?: Maybe<Scalars['String']>;
  /** アカウント更新日時 */
  readonly updatedAt: Scalars['DateTime'];
  /** ユーザー設定 */
  readonly userSetting: UserSetting;
};


/** ユーザを表すオブジェクト */
export type UserAssignKeyResultsArgs = {
  okrTermId: Scalars['ID'];
};


/** ユーザを表すオブジェクト */
export type UserKeyResultsArgs = {
  filter?: InputMaybe<OkrEnableFilter>;
  okrTermId: Scalars['ID'];
};


/** ユーザを表すオブジェクト */
export type UserMemberActionPlansArgs = {
  okrTermId: Scalars['ID'];
};


/** ユーザを表すオブジェクト */
export type UserMemberKeyResultsArgs = {
  filter?: InputMaybe<OkrEnableFilter>;
  okrTermId: Scalars['ID'];
};


/** ユーザを表すオブジェクト */
export type UserNotesArgs = {
  okrTermId: Scalars['ID'];
};


/** ユーザを表すオブジェクト */
export type UserOkrsArgs = {
  filter?: InputMaybe<OkrEnableFilter>;
  okrTermId: Scalars['ID'];
};


/** ユーザを表すオブジェクト */
export type UserOwnerActionPlansArgs = {
  okrTermId: Scalars['ID'];
};


/** ユーザを表すオブジェクト */
export type UserOwnerKeyResultsArgs = {
  filter?: InputMaybe<OkrEnableFilter>;
  okrTermId: Scalars['ID'];
};


/** ユーザを表すオブジェクト */
export type UserOwnerObjectivesArgs = {
  filter?: InputMaybe<OkrEnableFilter>;
  okrTermId: Scalars['ID'];
};


/** ユーザを表すオブジェクト */
export type UserPersonalNotesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Scalars['DateTime']>;
  last?: InputMaybe<Scalars['Int']>;
  to?: InputMaybe<Scalars['DateTime']>;
};


/** ユーザを表すオブジェクト */
export type UserRecommendAgendasArgs = {
  meetingAt: Scalars['DateTime'];
  previousMeetingAt?: InputMaybe<Scalars['DateTime']>;
};

/** 1on1でのユーザー自由記述部分のアジェンダ */
export type UserAgenda = Agenda & Node & {
  readonly __typename?: 'UserAgenda';
  /** 起票者 */
  readonly author: User;
  /** 作成日 */
  readonly createdAt: Scalars['DateTime'];
  /** ID */
  readonly id: Scalars['ID'];
  /** 表示順 */
  readonly index: Scalars['Int'];
  /** 議事録 */
  readonly meetingNote: Scalars['String'];
  /** 議事録のプレーンテキスト */
  readonly meetingNoteText: Scalars['String'];
  /** タイトル */
  readonly title: Scalars['String'];
  /** 更新日 */
  readonly updatedAt: Scalars['DateTime'];
};

/** 1on1でのユーザー自由記述部分のアジェンダの一覧を取得する際のソートフィールド */
export const UserAgendaOrderField = {
  /** 作成日時 */
  CreatedAt: 'CREATED_AT',
  /** 表示順 */
  Index: 'INDEX',
  /** 更新日時 */
  UpdatedAt: 'UPDATED_AT'
} as const;

export type UserAgendaOrderField = typeof UserAgendaOrderField[keyof typeof UserAgendaOrderField];
/**
 * UserAgendaWhereInput is used for filtering UserAgenda objects.
 * Input was generated by ent.
 */
export type UserAgendaWhereInput = {
  readonly and?: InputMaybe<ReadonlyArray<UserAgendaWhereInput>>;
  /** created_at field predicates */
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly createdAtGT?: InputMaybe<Scalars['DateTime']>;
  readonly createdAtGTE?: InputMaybe<Scalars['DateTime']>;
  readonly createdAtIn?: InputMaybe<ReadonlyArray<Scalars['DateTime']>>;
  readonly createdAtLT?: InputMaybe<Scalars['DateTime']>;
  readonly createdAtLTE?: InputMaybe<Scalars['DateTime']>;
  readonly createdAtNEQ?: InputMaybe<Scalars['DateTime']>;
  readonly createdAtNotIn?: InputMaybe<ReadonlyArray<Scalars['DateTime']>>;
  /** user_one_on_one_meeting edge predicates */
  readonly hasUserOneOnOneMeeting?: InputMaybe<Scalars['Boolean']>;
  readonly hasUserOneOnOneMeetingWith?: InputMaybe<ReadonlyArray<UserOneOnOneMeetingWhereInput>>;
  readonly not?: InputMaybe<UserAgendaWhereInput>;
  readonly or?: InputMaybe<ReadonlyArray<UserAgendaWhereInput>>;
  /** updated_at field predicates */
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAtGT?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAtGTE?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAtIn?: InputMaybe<ReadonlyArray<Scalars['DateTime']>>;
  readonly updatedAtLT?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAtLTE?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAtNEQ?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAtNotIn?: InputMaybe<ReadonlyArray<Scalars['DateTime']>>;
};

/** マップの状態のブックマーク */
export type UserBookmark = {
  readonly __typename?: 'UserBookmark';
  /** 検索条件(グループ) */
  readonly filterGroupIds: ReadonlyArray<Scalars['ID']>;
  /** 検索条件(ユーザ) */
  readonly filterUserIds: ReadonlyArray<Scalars['ID']>;
  /** フォーカスノード */
  readonly focusNodeId?: Maybe<Scalars['ID']>;
  /** Fold */
  readonly foldNodeIds: ReadonlyArray<Scalars['ID']>;
  /** ブックマークID */
  readonly id: Scalars['ID'];
  /** ブックマーク名 */
  readonly name: Scalars['String'];
  /** サブツリーノード */
  readonly subtreeNodeId?: Maybe<Scalars['ID']>;
  /** OKRの期間 */
  readonly term: OkrTerm;
};

/**
 * ユーザに紐づくKeyResultの情報
 * @deprecated リソアロ廃止につき削除予定
 */
export type UserKeyResultInfo = {
  readonly __typename?: 'UserKeyResultInfo';
  /** 作成日時 */
  readonly createdAt: Scalars['DateTime'];
  /** ID */
  readonly id: Scalars['ID'];
  /** ユーザに紐づく有効なKeyResult */
  readonly keyResult: KeyResult;
  /** リソース配分 */
  readonly resourceAllocation: Scalars['Int'];
  /** アサインされているKeyResultのロール */
  readonly role: UserKeyResultRole;
  /** 更新日時 */
  readonly updatedAt: Scalars['DateTime'];
};

/** KeyResultにアサインされているユーザのロール */
export const UserKeyResultRole = {
  /** コントリビューター */
  Member: 'MEMBER',
  /** オーナー */
  Owner: 'OWNER'
} as const;

export type UserKeyResultRole = typeof UserKeyResultRole[keyof typeof UserKeyResultRole];
/** ノート利用可能ユーザ */
export type UserNotePermission = {
  readonly __typename?: 'UserNotePermission';
  /** アバター */
  readonly avatar?: Maybe<File>;
  /** 名 */
  readonly firstName: Scalars['String'];
  /** ユーザID */
  readonly id: Scalars['ID'];
  /** 無効化状態 */
  readonly isDisabled: Scalars['Boolean'];
  /** 姓 */
  readonly lastName: Scalars['String'];
  /** ノート閲覧可否 */
  readonly noteVisible: Scalars['Boolean'];
};

/** ユーザーの1on1 */
export type UserOneOnOne = Node & {
  readonly __typename?: 'UserOneOnOne';
  /** 作成日 */
  readonly createdAt: Scalars['DateTime'];
  /** ID */
  readonly id: Scalars['ID'];
  /** ログインユーザー */
  readonly me: User;
  /** 1on1詳細の一覧 */
  readonly meetings: UserOneOnOneMeetingConnection;
  /** 1on1の相手 */
  readonly partnerUser: User;
  /** 更新日 */
  readonly updatedAt: Scalars['DateTime'];
};


/** ユーザーの1on1 */
export type UserOneOnOneMeetingsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UserOneOnOneMeetingOrder>;
  where?: InputMaybe<UserOneOnOneMeetingWhereInput>;
};

/** 1on1詳細 */
export type UserOneOnOneMeeting = {
  readonly __typename?: 'UserOneOnOneMeeting';
  /** 作成日 */
  readonly createdAt: Scalars['DateTime'];
  /** 開催日 */
  readonly dateOfMeeting: Scalars['DateTime'];
  /** 場所・URL */
  readonly description: Scalars['String'];
  /** 場所・URLのプレーンテキスト */
  readonly descriptionPlainText: Scalars['String'];
  /** 終了時間 */
  readonly endTime: Scalars['DateTime'];
  /** ID */
  readonly id: Scalars['ID'];
  /** 非公開メモ */
  readonly privateNote: UserOneOnOneMeetingPrivateNote;
  /** 開始時間 */
  readonly startTime: Scalars['DateTime'];
  /** 更新日 */
  readonly updatedAt: Scalars['DateTime'];
  /** 1on1の設定情報 */
  readonly userOneOnOne: UserOneOnOne;
};

/** 1on1詳細のコネクション */
export type UserOneOnOneMeetingConnection = {
  readonly __typename?: 'UserOneOnOneMeetingConnection';
  /** エッジ */
  readonly edges: ReadonlyArray<UserOneOnOneMeetingEdge>;
  /** ページング情報 */
  readonly pageInfo: PageInfo;
  /** トータル件数 */
  readonly totalCount: Scalars['Int'];
};

/** 1on1でのユーザー自由記述部分のアジェンダエッジ */
export type UserOneOnOneMeetingEdge = {
  readonly __typename?: 'UserOneOnOneMeetingEdge';
  /** カーソル */
  readonly cursor: Scalars['String'];
  /** ノード */
  readonly node: UserOneOnOneMeeting;
};

/** ユーザーの1on1のミーティングの一覧を取得するソート順を指定するためのinput */
export type UserOneOnOneMeetingOrder = {
  /** 順序 */
  readonly direction?: OrderDirection;
  /** ソート対象カラム */
  readonly field?: InputMaybe<UserOneOnOneMeetingOrderField>;
};

/** 1on1のミーティングの一覧を取得する際のソートフィールド */
export const UserOneOnOneMeetingOrderField = {
  /** ミーティング開催日 */
  DateOfMeeting: 'DATE_OF_MEETING',
  /** ミーティング終了日時 */
  EndTime: 'END_TIME',
  /** ミーティング開始日時 */
  StartTime: 'START_TIME'
} as const;

export type UserOneOnOneMeetingOrderField = typeof UserOneOnOneMeetingOrderField[keyof typeof UserOneOnOneMeetingOrderField];
/** 1on1の非公開メモ */
export type UserOneOnOneMeetingPrivateNote = {
  readonly __typename?: 'UserOneOnOneMeetingPrivateNote';
  /** 作成日 */
  readonly createdAt: Scalars['DateTime'];
  /** ID */
  readonly id: Scalars['ID'];
  /** ノート */
  readonly meetingNote: Scalars['String'];
  /** ノートのプレーンテキスト */
  readonly meetingNoteText: Scalars['String'];
  /** 更新日 */
  readonly updatedAt: Scalars['DateTime'];
  /** ユーザー */
  readonly user: User;
  /** 1on1詳細のFK */
  readonly userOneOnOneMeeting: UserOneOnOneMeeting;
};

/**
 * UserOneOnOneMeetingPrivateNoteWhereInput is used for filtering UserOneOnOneMeetingPrivateNote objects.
 * Input was generated by ent.
 */
export type UserOneOnOneMeetingPrivateNoteWhereInput = {
  readonly and?: InputMaybe<ReadonlyArray<UserOneOnOneMeetingPrivateNoteWhereInput>>;
  /** created_at field predicates */
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly createdAtGT?: InputMaybe<Scalars['DateTime']>;
  readonly createdAtGTE?: InputMaybe<Scalars['DateTime']>;
  readonly createdAtIn?: InputMaybe<ReadonlyArray<Scalars['DateTime']>>;
  readonly createdAtLT?: InputMaybe<Scalars['DateTime']>;
  readonly createdAtLTE?: InputMaybe<Scalars['DateTime']>;
  readonly createdAtNEQ?: InputMaybe<Scalars['DateTime']>;
  readonly createdAtNotIn?: InputMaybe<ReadonlyArray<Scalars['DateTime']>>;
  /** user_one_on_one_meeting edge predicates */
  readonly hasUserOneOnOneMeeting?: InputMaybe<Scalars['Boolean']>;
  readonly hasUserOneOnOneMeetingWith?: InputMaybe<ReadonlyArray<UserOneOnOneMeetingWhereInput>>;
  readonly not?: InputMaybe<UserOneOnOneMeetingPrivateNoteWhereInput>;
  readonly or?: InputMaybe<ReadonlyArray<UserOneOnOneMeetingPrivateNoteWhereInput>>;
  /** updated_at field predicates */
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAtGT?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAtGTE?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAtIn?: InputMaybe<ReadonlyArray<Scalars['DateTime']>>;
  readonly updatedAtLT?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAtLTE?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAtNEQ?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAtNotIn?: InputMaybe<ReadonlyArray<Scalars['DateTime']>>;
};

/**
 * UserOneOnOneMeetingWhereInput is used for filtering UserOneOnOneMeeting objects.
 * Input was generated by ent.
 */
export type UserOneOnOneMeetingWhereInput = {
  readonly and?: InputMaybe<ReadonlyArray<UserOneOnOneMeetingWhereInput>>;
  /** created_at field predicates */
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly createdAtGT?: InputMaybe<Scalars['DateTime']>;
  readonly createdAtGTE?: InputMaybe<Scalars['DateTime']>;
  readonly createdAtIn?: InputMaybe<ReadonlyArray<Scalars['DateTime']>>;
  readonly createdAtLT?: InputMaybe<Scalars['DateTime']>;
  readonly createdAtLTE?: InputMaybe<Scalars['DateTime']>;
  readonly createdAtNEQ?: InputMaybe<Scalars['DateTime']>;
  readonly createdAtNotIn?: InputMaybe<ReadonlyArray<Scalars['DateTime']>>;
  /** deleted_at field predicates */
  readonly deletedAt?: InputMaybe<Scalars['DateTime']>;
  readonly deletedAtGT?: InputMaybe<Scalars['DateTime']>;
  readonly deletedAtGTE?: InputMaybe<Scalars['DateTime']>;
  readonly deletedAtIn?: InputMaybe<ReadonlyArray<Scalars['DateTime']>>;
  readonly deletedAtIsNil?: InputMaybe<Scalars['Boolean']>;
  readonly deletedAtLT?: InputMaybe<Scalars['DateTime']>;
  readonly deletedAtLTE?: InputMaybe<Scalars['DateTime']>;
  readonly deletedAtNEQ?: InputMaybe<Scalars['DateTime']>;
  readonly deletedAtNotIn?: InputMaybe<ReadonlyArray<Scalars['DateTime']>>;
  readonly deletedAtNotNil?: InputMaybe<Scalars['Boolean']>;
  /** end_time field predicates */
  readonly endTime?: InputMaybe<Scalars['DateTime']>;
  readonly endTimeGT?: InputMaybe<Scalars['DateTime']>;
  readonly endTimeGTE?: InputMaybe<Scalars['DateTime']>;
  readonly endTimeIn?: InputMaybe<ReadonlyArray<Scalars['DateTime']>>;
  readonly endTimeLT?: InputMaybe<Scalars['DateTime']>;
  readonly endTimeLTE?: InputMaybe<Scalars['DateTime']>;
  readonly endTimeNEQ?: InputMaybe<Scalars['DateTime']>;
  readonly endTimeNotIn?: InputMaybe<ReadonlyArray<Scalars['DateTime']>>;
  /** fixed_agenda_user_one_on_one_meeting edge predicates */
  readonly hasFixedAgendaUserOneOnOneMeeting?: InputMaybe<Scalars['Boolean']>;
  readonly hasFixedAgendaUserOneOnOneMeetingWith?: InputMaybe<ReadonlyArray<FixedAgendaUserOneOnOneMeetingWhereInput>>;
  /** user_agendas edge predicates */
  readonly hasUserAgendas?: InputMaybe<Scalars['Boolean']>;
  readonly hasUserAgendasWith?: InputMaybe<ReadonlyArray<UserAgendaWhereInput>>;
  /** user_one_on_one edge predicates */
  readonly hasUserOneOnOne?: InputMaybe<Scalars['Boolean']>;
  /** user_one_on_one_meeting_private_notes edge predicates */
  readonly hasUserOneOnOneMeetingPrivateNotes?: InputMaybe<Scalars['Boolean']>;
  readonly hasUserOneOnOneMeetingPrivateNotesWith?: InputMaybe<ReadonlyArray<UserOneOnOneMeetingPrivateNoteWhereInput>>;
  readonly hasUserOneOnOneWith?: InputMaybe<ReadonlyArray<UserOneOnOneWhereInput>>;
  readonly not?: InputMaybe<UserOneOnOneMeetingWhereInput>;
  readonly or?: InputMaybe<ReadonlyArray<UserOneOnOneMeetingWhereInput>>;
  /** start_time field predicates */
  readonly startTime?: InputMaybe<Scalars['DateTime']>;
  readonly startTimeGT?: InputMaybe<Scalars['DateTime']>;
  readonly startTimeGTE?: InputMaybe<Scalars['DateTime']>;
  readonly startTimeIn?: InputMaybe<ReadonlyArray<Scalars['DateTime']>>;
  readonly startTimeLT?: InputMaybe<Scalars['DateTime']>;
  readonly startTimeLTE?: InputMaybe<Scalars['DateTime']>;
  readonly startTimeNEQ?: InputMaybe<Scalars['DateTime']>;
  readonly startTimeNotIn?: InputMaybe<ReadonlyArray<Scalars['DateTime']>>;
  /** updated_at field predicates */
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAtGT?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAtGTE?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAtIn?: InputMaybe<ReadonlyArray<Scalars['DateTime']>>;
  readonly updatedAtLT?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAtLTE?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAtNEQ?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAtNotIn?: InputMaybe<ReadonlyArray<Scalars['DateTime']>>;
};

/** ユーザーの1on1一覧を取得するソート順を指定するためのinput */
export type UserOneOnOneOrder = {
  /** 順序 */
  readonly direction?: OrderDirection;
  /** ソート対象カラム */
  readonly field?: InputMaybe<UserOneOnOneOrderField>;
};

/** 固定アジェンダ一覧を取得する際のソートフィールド */
export const UserOneOnOneOrderField = {
  /** 1on1作成ユーザー */
  CreatedUser: 'CREATED_USER',
  /** 1on1対象ユーザー */
  PartnerUser: 'PARTNER_USER',
  /** 関係性 */
  Rerationship: 'RERATIONSHIP'
} as const;

export type UserOneOnOneOrderField = typeof UserOneOnOneOrderField[keyof typeof UserOneOnOneOrderField];
/**
 * UserOneOnOneWhereInput is used for filtering UserOneOnOne objects.
 * Input was generated by ent.
 */
export type UserOneOnOneWhereInput = {
  readonly and?: InputMaybe<ReadonlyArray<UserOneOnOneWhereInput>>;
  /** created_at field predicates */
  readonly createdAt?: InputMaybe<Scalars['DateTime']>;
  readonly createdAtGT?: InputMaybe<Scalars['DateTime']>;
  readonly createdAtGTE?: InputMaybe<Scalars['DateTime']>;
  readonly createdAtIn?: InputMaybe<ReadonlyArray<Scalars['DateTime']>>;
  readonly createdAtLT?: InputMaybe<Scalars['DateTime']>;
  readonly createdAtLTE?: InputMaybe<Scalars['DateTime']>;
  readonly createdAtNEQ?: InputMaybe<Scalars['DateTime']>;
  readonly createdAtNotIn?: InputMaybe<ReadonlyArray<Scalars['DateTime']>>;
  /** user_one_on_one_meetings edge predicates */
  readonly hasUserOneOnOneMeetings?: InputMaybe<Scalars['Boolean']>;
  readonly hasUserOneOnOneMeetingsWith?: InputMaybe<ReadonlyArray<UserOneOnOneMeetingWhereInput>>;
  readonly not?: InputMaybe<UserOneOnOneWhereInput>;
  readonly or?: InputMaybe<ReadonlyArray<UserOneOnOneWhereInput>>;
  /** updated_at field predicates */
  readonly updatedAt?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAtGT?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAtGTE?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAtIn?: InputMaybe<ReadonlyArray<Scalars['DateTime']>>;
  readonly updatedAtLT?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAtLTE?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAtNEQ?: InputMaybe<Scalars['DateTime']>;
  readonly updatedAtNotIn?: InputMaybe<ReadonlyArray<Scalars['DateTime']>>;
};

/** 個人ノート利用可能ユーザ */
export type UserPersonalNotePermission = {
  readonly __typename?: 'UserPersonalNotePermission';
  /** アバター */
  readonly avatar?: Maybe<File>;
  /** 名 */
  readonly firstName: Scalars['String'];
  /** ユーザID */
  readonly id: Scalars['ID'];
  /** 無効化状態 */
  readonly isDisabled: Scalars['Boolean'];
  /** 姓 */
  readonly lastName: Scalars['String'];
  /** ノート閲覧可否 */
  readonly noteVisible: Scalars['Boolean'];
};

/** ユーザーの設定を表すオブジェクト */
export type UserSetting = {
  readonly __typename?: 'UserSetting';
  /** 作成日時 */
  readonly createdAt: Scalars['DateTime'];
  /** ユーザ設定ID */
  readonly id: Scalars['ID'];
  /** 言語 */
  readonly language: Language;
  /** OKRツリーで初期表示時にfolding状態としないOKRノードの階層 */
  readonly minimumVisibleOkrNodeLevel: Scalars['Int'];
  /** スラックで一括更新の通知を受け取るか */
  readonly notifyBulkUpdateEnabledOnSlack: Scalars['Boolean'];
  /** OKR関連の通知を有効にするか */
  readonly notifyOkrEmailEnabled: Scalars['Boolean'];
  /** 1on1の通知を有効にするか */
  readonly notifyOneOnOneEmailEnabled: Scalars['Boolean'];
  /** 無効化したOKRを表示するか */
  readonly showDisabledOkrs: Scalars['Boolean'];
  /** 更新日時 */
  readonly updatedAt: Scalars['DateTime'];
  /** ユーザー */
  readonly user: User;
};

export type Description = {
  readonly description?: InputMaybe<Scalars['String']>;
  readonly descriptionPlainText?: InputMaybe<Scalars['String']>;
};

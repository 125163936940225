import dayjs from 'dayjs'
import React from 'react'

import { CreateUserOneOnOneMeetingInput } from '../graphql'

import { ModalSubmitHandler, OneOnOneModal } from './OneOnOneModal'
import { FormValue, Partner } from './type'

export type OnCreateUserOneOnOneMeetings = (
  partner: Partner,
  userOneOnOneMeetingsInput: Array<CreateUserOneOnOneMeetingInput>,
) => Promise<void>

export type Props = {
  title: string
  onClose: () => void
  onCreateUserOneOnOneMeetings: OnCreateUserOneOnOneMeetings
}

export const OneOnOneCreateModal: React.FC<Props> = ({
  title,
  onClose,
  onCreateUserOneOnOneMeetings,
}) => {
  const onSubmit: ModalSubmitHandler = async (value: FormValue) => {
    const castedUserOneOnOneMeetingsInput =
      value.meetingSchedule.map<CreateUserOneOnOneMeetingInput>((s) => {
        const ymd = dayjs(s.date).format('YYYY/MM/DD')
        const [startHour, startMinutes] = s.startTime.split(':')
        const [endHour, endMinutes] = s.endTime.split(':')

        return {
          schedule: {
            startTime: new Date(`${ymd} ${startHour}:${startMinutes}:00`),
            endTime: new Date(`${ymd} ${endHour}:${endMinutes}:00`),
          },
          description: {
            description: s.description.treeJson,
            descriptionPlainText: s.description.plainText,
          },
        }
      })
    await onCreateUserOneOnOneMeetings(
      {
        id: value.partner.id,
        email: value.partner.email,
        firstName: value.partner.firstName,
        lastName: value.partner.lastName,
        language: value.partner.language,
        notifyOneOnOneEmailEnabled: value.partner.notifyOneOnOneEmailEnabled,
      },
      castedUserOneOnOneMeetingsInput,
    )
  }

  return <OneOnOneModal title={title} onSubmit={onSubmit} onClose={onClose} />
}

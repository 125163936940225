import { useCallback } from 'react'

import { User } from '../../../contexts/UserContext'
import { tracker } from '../../../lib/tracking'
import { useUpdateUserSettingMutation, OrganizationFragment } from '../api/graphql'
import { Props as NotificationSettingProps } from '../components/NotificationSetting'

export const useNotificationSetting = ({
  user,
  organization,
}: {
  organization: OrganizationFragment
  user: User
}): NotificationSettingProps => {
  const [updateUserSetting, { loading }] = useUpdateUserSettingMutation()

  const updateNotifyOkrEmailEnabled = useCallback(
    (notifyOkrEmailEnabled: boolean) => {
      tracker.OkrNotificationSettingToggleSubscribe(user.userSetting.notifyOkrEmailEnabled)
      updateUserSetting({
        variables: {
          notifyOkrEmailEnabled,
          notifyOneOnOneEmailEnabled: user.userSetting.notifyOneOnOneEmailEnabled,
          notifyBulkUpdateEnabledOnSlack: user.userSetting.notifyBulkUpdateEnabledOnSlack,
        },
      })
    },
    [user.userSetting, updateUserSetting],
  )

  const updateNotifyOneOnOneEmailEnabled = useCallback(
    (notifyOneOnOneEmailEnabled: boolean) => {
      tracker.OneOnOneNotificationSettingToggleSubscribe(
        user.userSetting.notifyOneOnOneEmailEnabled,
      )
      updateUserSetting({
        variables: {
          notifyOkrEmailEnabled: user.userSetting.notifyOkrEmailEnabled,
          notifyOneOnOneEmailEnabled,
          notifyBulkUpdateEnabledOnSlack: user.userSetting.notifyBulkUpdateEnabledOnSlack,
        },
      })
    },
    [user.userSetting, updateUserSetting],
  )

  const updateNotifyBulkUpdateBySlack = useCallback(
    (notifyBulkUpdateEnabledOnSlack: boolean) => {
      updateUserSetting({
        variables: {
          notifyOkrEmailEnabled: user.userSetting.notifyOkrEmailEnabled,
          notifyOneOnOneEmailEnabled: user.userSetting.notifyOneOnOneEmailEnabled,
          notifyBulkUpdateEnabledOnSlack,
        },
      })
    },
    [user.userSetting, updateUserSetting],
  )

  return {
    loading,
    userSetting: {
      notifyOkrEmailEnabled: user.userSetting.notifyOkrEmailEnabled,
      notifyOneOnOneEmailEnabled: user.userSetting.notifyOneOnOneEmailEnabled,
      notifyBulkUpdateEnabledOnSlack: user.userSetting.notifyBulkUpdateEnabledOnSlack,
    },
    organizationSlackNotification: organization.bulkUpdateNotificationEnabled,
    updateNotifyOkrEmailEnabled,
    updateNotifyOneOnOneEmailEnabled,
    updateNotifyBulkUpdateBySlack,
  }
}

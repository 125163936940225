import { useContext, useEffect, useState } from 'react'

import { configureChannelTalk } from '../../config'
import { OrganizationContext } from '../../contexts/OrganizationContext'
import { useCurrentUser } from '../../contexts/UserContext'
import { decodeId } from '../domain/id'
import { useFlags } from '../featureToggle'

import channelService from './channelTalk'
import { User } from './type'

export const CUSTOM_LAUNCHER_CLASS_NAME = 'channel-talk-launcher'
type ChannelIO = {
  onShowMessenger: () => void
  onHideMessenger: () => void
}

/**
 * channel talk を起動するhooks
 */
export const useChannelTalk = (channelIO: ChannelIO): [number] => {
  const user = useCurrentUser()
  const organization = useContext(OrganizationContext)

  const [loaded, setLoaded] = useState(false)
  const [unreadCount, setUnreadCount] = useState<number>(0)
  const { channelTalkChat } = useFlags()

  useEffect(() => {
    if (!loaded && user && organization && channelTalkChat === true) {
      try {
        channelService.boot(
          {
            pluginKey: configureChannelTalk().pluginKey,
            // @see https://guide-jp.channel.io/6f8cd8d8-25e0-4d60-9107-c2ac1c2d258b#544915e8-df7e-4e4f-8499-287ef8d07bac
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            locale: 'ja',
            memberId: String(decodeId(user.id)),
            memberHash: user.idHashForChannelTalk,
            profile: {
              // channel talk reserved values
              name: `${user.lastName} ${user.firstName}`,
              email: user.email,
              // customized values
              organizationId: decodeId(organization.id),
              organizationName: organization.name,
              selfserve: !!organization.selfServe,
            },
            customLauncherSelector: `.${CUSTOM_LAUNCHER_CLASS_NAME}`,
            hideChannelButtonOnBoot: true,
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (_error?: any, _userObject?: User) => {
            /** DO NOTHING */
          },
        )
        channelService.onBadgeChanged((count: number) => {
          setUnreadCount(count)
        })
        channelService.onShowMessenger(channelIO.onShowMessenger)
        channelService.onHideMessenger(channelIO.onHideMessenger)
      } catch (e) {
        /** DO NOTHING */
      }
      setLoaded(true)
    }
  }, [
    channelIO.onHideMessenger,
    channelIO.onShowMessenger,
    channelTalkChat,
    loaded,
    organization,
    user,
  ])

  return [unreadCount]
}

/**
 * ChannelTalkに現在の画面のURLとPageViewイベントを記録する
 * SPA構成でCRMマーケティングの自動配信ポップアップを表示する場合にChannelTalkへURLを指定する必要がある
 * See: https://developers.channel.io/docs/faq#utilizing-workflow-and-marketing-features-in-an-spa-environment
 */
export const trackChannelTalkPageView = (): void => {
  const fullUrl = window.location.href
  channelService.setPage(fullUrl)
  channelService.track('PageView')
}
